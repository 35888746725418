import { Routes } from '@angular/router';

import { TopComponent } from '../top/top.component';
import { LoginComponent } from '../login/login.component';
import { LogoutComponent } from '../logout/logout.component';

import { ErrorComponent } from '../error/error.component';
import { ErrorLinkComponent } from '../error-link/error-link.component';

import { MessageComponent } from '../message/message.component';
import { routes as messageRoutes, components as messageComponents }
  from '../message/message-routing.module';

import { MypageComponent } from '../mypage/mypage.component';
import { routes as mypageRoutes, components as mypageComponents }
  from '../mypage/mypage-routing.module';

import { PasswdReissueComponent } from '../passwd-reissue/passwd-reissue.component';
import { routes as passwdReissueRoutes, components as passwdReissueComponents }
  from '../passwd-reissue/passwd-reissue-routing.module';

import { RegistrationComponent } from '../registration/registration.component';
import { routes as registrationRoutes, components as registrationComponents }
  from '../registration/registration-routing.module';

import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';
import { routes as unsubscribeRoutes, components as unsubscribeComponents }
  from '../unsubscribe/unsubscribe-routing.module';
import { NoticeInfoComponent } from '../notice-info/notice-info.component';
import { routes as noticeInfoRoutes, components as noticeInfoComponent } from '../notice-info/notice-info-routing.module';

//
export const routes: Routes = [
  {
  path: '',
  component: PassCardComponent,
  children: ticketRoutes
},
// {
//   path: '',
//   component: TopComponent
// },
{
  path: 'error',
  component: ErrorComponent
}, {
  path: 'error-link/:code',
  component: ErrorLinkComponent
}, {
  path: 'login',
  component: LoginComponent
}, {
  path: 'logout',
  component: LogoutComponent
}, {
  path: 'message',
  component: MessageComponent,
  children: messageRoutes
}, {
  path: 'mypage',
  component: MypageComponent,
  children: mypageRoutes
}, {
  path: 'passwd-reissue',
  component: PasswdReissueComponent,
  children: passwdReissueRoutes
},{
  path: 'registration',
  component: RegistrationComponent,
  children: registrationRoutes
}, {
  path: 'unsubscribe',
  component: UnsubscribeComponent,
  children: unsubscribeRoutes
}, {
  path: 'passcard',
  component: PassCardComponent,
  children: ticketRoutes
},{
  path: 'notice-info',
  component: NoticeInfoComponent,
  children: noticeInfoRoutes
}];

//
import { HomeComponent } from './home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NaviComponent } from './navi/navi.component';
import { components as topComponents } from '../top/top.module';
import { PassCardComponent } from '../passcard/passcard.component';
import { passcardComponents, ticketRoutes } from '../passcard';

export const components = [
  ErrorComponent,
  ErrorLinkComponent,
  FooterComponent,
  HeaderComponent,
  HomeComponent,
  LoginComponent,
  LogoutComponent,
  MessageComponent,
  MypageComponent,
  NaviComponent,
  PasswdReissueComponent,
  RegistrationComponent,
  TopComponent,
  UnsubscribeComponent,
  PassCardComponent,
  NoticeInfoComponent,
  ...messageComponents,
  ...mypageComponents,
  ...passwdReissueComponents,
  ...registrationComponents,
  ...topComponents,
  ...unsubscribeComponents,
  ...passcardComponents,
  ...noticeInfoComponent,
];
