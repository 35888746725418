import { Injectable } from "@angular/core";
import { Delivery, DeliveryInfo, PasscardApply } from "../passcard.model";
import { UserInfo } from "../models/user-info";

@Injectable({
    providedIn: 'root'
  })
export class LostService {
    maxProcessed = 0;
    passcardApply: PasscardApply;
    delivery!: Delivery
    deliveryInputKind: number = 0;
    myPage: UserInfo | null = null;
    reissueFee: number = 0;
    receiptNo: string;
    complete = false;
    cancelComplete = false;
    inputedDelivery: DeliveryInfo | null = null;

    reset() {
      this.complete = false;
      this.maxProcessed = 0;
      this.reissueFee = 0;
      this.receiptNo = '';
      this.inputedDelivery = null;
    }

    get applyCardDelivery() {
      return this.passcardApply?.applyCardDelivery;
    }
}