import { Component } from '@angular/core';
import { ApplyKind, Passcard } from '../passcard.model';
import { Location } from '@angular/common';
import { PasscardBookingService } from '../passcard-booking.service';
import { AppModel } from 'src/app/app.model';
import { ApiService } from 'src/app/services';

@Component({
  selector: 'apply',
  templateUrl: './apply.component.html'
})
export class ApplyComponent {
  completeMsg = ['', '']
  receptionNo: string | null = null;
  btnPhoneMode = false;
  currentProc: number = 0;

  passcardInfo!: Passcard;
  reservationNo!: number;

  constructor(
    public app: AppModel,
    public api: ApiService,
    private _bookingService: PasscardBookingService,
    location: Location) {
    const navigateState = location.getState();
    if (navigateState?.['action'] == 'reApply') {
      this._bookingService.PasscardInfo = navigateState['passcard'];
      this._bookingService.passcardApplyNo = navigateState['passcardApplyNo'];
      _bookingService.applyKind = ApplyKind.ReApply;
    }
  }
}
