<h2>パスワード再発行</h2>
<t-error [message]="error"></t-error>

<div class="infoText">
  <div class="infoLine">
    <div>
      <img class="info-icon" src="./assets/img/info.svg" alt="">
    </div>
    <div class="meg">
      パスワード再発行を行います。<br>
      メールアドレスを入力し、<br class="br-sp">「送信」を押してください。<br>
    </div>
  </div>
</div>

<section>
  <h3>メールアドレス入力</h3>
  <div class="tableCtn">
    <i-email [model]="model" [label]="'メールアドレス'"></i-email>
  </div>
</section>

<div class="buttonBox">
  <loading-button
    (apply)="onApply()"
    [disabled]="disabled"
    [loading]="api.disabled"
    [label]="'送信'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>