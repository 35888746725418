import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigateService } from '../services';

//
const messages = [
  ['リンクの有効期限が切れました。', 'もう一度、登録を行ってください。'],
  ['メールアドレスが既に登録されています。', 'もう一度、登録を行ってください。']
];

//
@Component({
  selector: 'app-error-link',
  templateUrl: './error-link.component.html',
})
export class ErrorLinkComponent implements OnInit {
  message: string;
  messages: string[];

  constructor(
    public navi: NavigateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const map = this.route.snapshot.paramMap;
    const code = map.get('code');
    const m = messages[+code - 1];
    this.message = m[0];
    this.messages = [m[1]];
  }

  onClick = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/');
  }
}
