<h2>車両情報(バイク)変更確認</h2>
<t-error [message]="error" [messages]="errors"></t-error>

<t-info [value1]="'以下の内容で車両情報(バイク)を更新します。'" [value2]="'内容を確認し、「確定」を押してください。'"></t-info>


<section>
  <div class="tableCtn tableCtn-confirm">
    <div *ngIf="model.byApplyMotorcycleNumber!=='0'">
      <p-single [label]="'ナンバープレート'" [value]="model.motorcycleNumberPlate"></p-single>
    </div>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>