import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';

//
@Injectable({
  providedIn: 'root'
})
export class KeyService {
  blockCopyPaste = (evt: KeyboardEvent) => {
    const c = evt.keyCode
    const ctrlDown = evt.ctrlKey || evt.metaKey // Mac support

    // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
    if (ctrlDown && evt.altKey)
      return true
    // Check for ctrl+c, v and x
    else if (ctrlDown && (c == 67 || c == 86 || c == 88))
      return false // c, v, x

    // Otherwise allow
    return true
  }

  blockMenu = () => false;

  // IEでのキーボード操作を抑止
  blockBS() {
    fromEvent<any>(document.body, 'keydown').subscribe(e => {
      const tag = e.srcElement.tagName;
      const code = e.keyCode;

      // 8:backspace
      if (code === 8 && tag !== 'INPUT')
        e.preventDefault();
    });
  }
}

// functions
export function focusNext(elm: Element) {
  if (!elm)
    return;

  if (elm instanceof HTMLInputElement)
    elm.focus();
  else
    focusNext(elm.nextElementSibling);
}

export function focusNext2(elm: Element) {
  if (!elm)
    return;

  const child = elm.firstChild
  if (child instanceof HTMLInputElement)
    child.focus();
  else
    focusNext2(elm.nextElementSibling);
}
