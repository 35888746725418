import { Component, Input } from '@angular/core';
import { NOSHOWORDER, ReservationStatus } from '../../passcard.model';

const applyingStatus = [
  { mainMessage: ['現在申請中です。'], subMessage: ['申請が受理されるまでしばらくお待ちください。'], imgUrl: '/assets/img/note.svg', tipClass: 'tips-color-blue' },
  {
    mainMessage: ['定期券受け取り後', '駐輪場でお手続きが必要です。'],
    subMessage: ['お受け取りになった定期券を持参し、駐輪場でお手続きお願いします。', '詳細はこちらを押してください。'],
    imgUrl: '/assets/img/exclamation.svg',
    tipClass: 'tips-color-yellow cursor-pointer'
  },
  {
    mainMessage: ['順番となりました。', '定期券の申請を続けてください。'],
    subMessage: ['こちらから申請処理を続けてください。', '2023/12/31までにお手続きください。'],
    imgUrl: '/assets/img/bell.svg',
    tipClass: 'tips-color-green cursor-pointer'
  },
  {
    mainMessage: ['順番となりました。', '定期券の申請を続けてください。'],
    subMessage: ['順番となるまでお待ちください。', '申請を取り消す場合は、こちらからお手続きできます。'],
    imgUrl: '/assets/img/hourglass.svg',
    tipClass: 'tips-color-blue2 cursor-pointer'
  },
  {
    mainMessage: ['申請の修正が必要です。'],
    subMessage: ['申請が受け付けられませんでした、', 'こちらから内容をご確認お願い致します。'],
    imgUrl: '/assets/img/exclamation.svg',
    tipClass: 'tips-color-pink cursor-pointer'
  },
]

@Component({
  selector: 'status-tip',
  templateUrl: './status-tip.component.html'
})
export class StatusTipComponent {
  @Input() status: number = 0;
  @Input() endDate: number | null = null
  @Input() order: number = 0;

  get Status() {
    let status = this.status;
    if (status >= ReservationStatus.Recepting) {
      status -= 1;
    }
    return status;
  }

  get MainMsgClass() {
    return this.status == ReservationStatus.Recepting ? 'tip-autowrap' : null;
  }

  get TipClass() {
    return applyingStatus[this.Status].tipClass;
  }

  get ImgUrl() {
    return applyingStatus[this.Status].imgUrl;
  }

  get MainMessage() {
    return applyingStatus[this.Status].mainMessage;
  }

  get SubMessage() {
    return applyingStatus[this.Status].subMessage;
  }

  isShowOrder() {
    return this.order != NOSHOWORDER;
  }
}
