import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';
import { MotorcycleInfoModel } from '../motorcycle-info.model';
import { MypageModel } from '../../mypage.model';

import { IMotorcycleNumberComponent } from '../../../shared/i-motorcyclenumber/i-motorcyclenumber.component';


//
interface Req {
  motorcycleNumberPlate: string;
}

interface Res {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
  motorcycleInfoValidator: {
    validatorMessageMotorcycleInfoList: string[];
  }
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(IMotorcycleNumberComponent, { static: false }) motorcycleNumber: IMotorcycleNumberComponent;

  // for Server
  error: string;
  errors: string[];
  messages: { motorcycleNumber: string[]};

  //
  disabled: boolean;

  //
  constructor(
    public model: MotorcycleInfoModel,
    public mypage: MypageModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
  ) { }

  ngOnInit() {
    this.disabled = true;
  
    this.messages = {
      motorcycleNumber:null
    };
  }

  ngDoCheck() {
    const b1 = this.motorcycleNumber ? this.motorcycleNumber.validation() : false;
    var b2 = true;
    if (this.model.byApplyMotorcycleNumber === '0' ) 
    {
      b2 = false;
    }
    this.disabled = !(b1 && b2) ;
  }

  onKeyup = () => {
    this.error = null;
    this.errors = null;
  }

  //
  private afterPost = (body: Res) => {
    if (body.resultCode === 0) {
      this.model.saveStorage();
      this.navi.navigateByUrl('/mypage/motorcycle-info/confirm');
    } else {
      this.api.enabled();
      
      this.error = body.mainMessage;
      this.messages.motorcycleNumber = body.validatorMessageList;
    
    }
  }

  onChange = () => {
    this.error = null;
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const req: Req = {
      motorcycleNumberPlate: this.model.motorcycleNumberPlate
    };

    this.api.post<Res>('/motorcycleInfo/check', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
