<h2>新規会員登録</h2>

<!--
<reg-stepbar [step]="1"></reg-stepbar>
-->
<flow-box-registration [steps]="['アドレス入力', 'お客様情報入力', '確認', '完了']" [processing]="0" [processed]="0" />

<h3>メールアドレス入力</h3>
<t-error [message]="error"></t-error>

<section>
  <i-email2 [model]="model"></i-email2>
</section>


<h3 class="mt20">利用規約</h3>
<p class="formText_02"><a href="{{model.termsServiceURL}}" target="term-of-service" class="link-text">定期券申し込みサービス利用規約</a> を確認し、<br class="br-sp">同意するにチェックをしてください。</p>

<div class="termConsent">
      <label [ngClass]="{'checkBoxArea':consent,'checkBoxAreaNot':!consent}">
        <input [(ngModel)]="consent" type="checkbox"><div class="checkboxMeg">利用規約に同意する</div>
      </label>
</div>

<div class="buttonBox">
  <loading-button
    (apply)="onApply()"
    [disabled]="!consent||disabled"
    [loading]="api.disabled"
    [label]="'仮登録（登録確認メールが送信されます）'"
    [class]="'ui primary button cmn-btn button-box-color-b-50-c kari-regist'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>