import { Routes } from '@angular/router';

import { CompleteComponent } from './complete/complete.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { RejectedComponent } from './rejected/rejected.component';

//
export const routes: Routes = [{
  path: 'complete',
  component: CompleteComponent,
}, {
  path: 'confirm',
  component: ConfirmComponent,
}, {
  path: 'rejected',
  component: RejectedComponent,
}];

//
export const components = [
  CompleteComponent,
  ConfirmComponent,
  RejectedComponent
];
