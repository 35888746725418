import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class AddressModel {
  phone: string;
  postcode: string[];
  address1: string;
  address2: string;
  kana: string[];
  byUserZip : string;
  byUserAddress1 : string;
  byUserAddress2 : string;

  

  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = (postcode: string[],address1: string,address2: string,kana: string[],byUserZip: string, byUserAddress1: string, byUserAddress2: string) => {
    this.completed = false;
    /*this.phone = null;*/
    this.postcode = postcode;
    this.address1 = address1;
    this.address2 = address2;
    this.kana = null;
    this.byUserZip = byUserZip;
    this.byUserAddress1 = byUserAddress1;
    this.byUserAddress2 = byUserAddress2;

    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  setModel = (phone: string) => {
    /*this.phone = phone;*/
    /*this.postcode = null;
    this.address1 = null;
    this.address2 = null;*/
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('address', this);
  }

  loadStorage = () => {
    this._storage.load('address', this);
  }
}
