import { Component, OnInit } from '@angular/core';

import { NavigateService } from '../services';
import { ErrorModel } from './error.model';

//
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {

  constructor(
    public navi: NavigateService,
    public model: ErrorModel
  ) { }

  ngOnInit() {
    const m = this.model;

    if (!m.message) {
      m.message = '操作が正しく受け付けられませんでした';
      m.messages = ['誠に申し訳ございませんが、再度最初から操作をお願い致します。'];
    }
  }

  onClick = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/');
  }

}
