import { Component, Input } from '@angular/core';
import { CarInfoService, ValidateService } from '../../services';

@Component({
  selector: 'i-carinfo',
  templateUrl: './i-carinfo.component.html',
})
export class ICarInfoComponent {
  @Input() label: string;
  @Input() model: { byCarInfo: string; numberPlate: string[] };
  @Input() spanRequire: boolean;
  
  // for server
  @Input() messages: string[];
  @Input() okng: string[];

  //
  message1: string[];
  message2: string[];
  message3: string[];
  message4: string[];
  message: string[];

  //
  constructor(
    public carInfo: CarInfoService,
    public valid: ValidateService
  ) { }

  validation = () => {
    const msg = this.valid;
    if (this.model.byCarInfo === '2') {
      //const b = this.carInfo.validationRequired(this);
      if(!(this.carInfo.validationRequired(this))) {
        return false;
      }
    }
    return this.carInfo.validation(this);
  }

  public onChange = (i: number) => {
    this.okng[i] = null;
    this.messages = null;
    this.message1 = null;
    this.message2 = null;
    this.message3 = null;
    this.message4 = null;
  }
}
