import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ModalComponent } from '../common/modal/modal.component';
import { NavigateService } from 'src/app/services';
import { CorrectionReq } from '../passcard.model';
import { PasscardBookingService } from '../passcard-booking.service';
import { PasscardApiProxy } from '../passcard.api.proxy';

/**
 * 否認定期修正確認
 */
@Component({
  selector: 'change-confirm',
  templateUrl: './change-confirm.component.html'
})
export class ChangeConfirmComponent {
  correctionReq: CorrectionReq | null = null;
  passcardApplyNo: number;

  @ViewChild('changeConfirm') changeConfirm!: ModalComponent

  constructor(public api: PasscardApiProxy,
    private _cacheService: PasscardBookingService,
    private _navi: NavigateService,
    location: Location) {
      this.correctionReq = <CorrectionReq>location.getState();
      this.passcardApplyNo = this.correctionReq['passcardApplyNo'];
  }

  get applyCardDelivery() {
    return this.correctionReq['applyCardDelivery'];
  }

  get ParkingName() {
    return this.correctionReq['parkingName'];
  }

  get IsComplete() {
    return this._cacheService.changeComplete;
  }

  get RejectReason() {
    return this.correctionReq?.denialReason;
  }

  get LastChangableTime() {
    if (!this.correctionReq.correctLimitDate) return '無期限';

    const date = new Date(this.correctionReq.correctLimitDate)
    const year = `${date.getFullYear()}`;
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const hours = date.getHours();
    const minutes = `${date.getMinutes()}`.padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }

  get ApplyInfo() {
    return this.correctionReq?.applyInfo;
  }

  get receiptNo() {
    return this.correctionReq?.receiptNo;
  }

  onDlgClose() {
    this.changeConfirm.closeModal();
  }

  /**
   * 修正確定
   */
  onDlgCorrect() {
    this.api.myPageCorrectConfirmation(this.passcardApplyNo)
      .then(receiptNo => {
        this.changeConfirm.closeModal();
        this._cacheService.changeComplete = true;
        this._navi.navigate(`/passcard/change-ok`, true, { receiptNo });
      })
      .catch(() => {
        this.changeConfirm.closeModal();
      });
  }

  onBack() {
    this._navi.goBack();
  }

  onConfirm() {
    this.changeConfirm.showModal();
  }
}
