import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class CarInfoModel {
  numberPlate: string[];

  byCarInfo : string;

  completed: boolean;

  dispNumberPlate: boolean; 



  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = (numberPlate:string[], byCarInfo:string) => {
    this.completed = false;
    this.numberPlate = numberPlate;
    this.byCarInfo = byCarInfo;

    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  setModel = () => {
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('carinfo', this);
  }

  loadStorage = () => {
    this._storage.load('carinfo', this);
  }
}
