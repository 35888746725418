import { Component, DoCheck, Input, Output, EventEmitter } from '@angular/core';
import { KeyService, ValidateService } from '../../services';

//
@Component({
  selector: 'i-setname',
  templateUrl: './i-setname.component.html',
})
export class ISetnameComponent implements DoCheck {
  @Output() validation: EventEmitter<boolean> = new EventEmitter();

  @Input() model: { receiptAddressName: string };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() label: string;

  message: string[];

  constructor(
    public key: KeyService,
    public valid: ValidateService
  ) { }

  ngDoCheck() {
    this.validations();
  }

  //
  private validations = () => {
    const msg = this.valid;
    const fn = msg.validRequired;
    this.message = fn(this.model.receiptAddressName, this.label);
    msg.pushSetname(this.message, this.model.receiptAddressName, this.label);
    this.validation.emit(this.message && !this.message.length);
  }

  onChange = () => this.messages = null;
}