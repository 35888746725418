import { Component, Input, OnInit } from '@angular/core';

import { generateQrcode } from '../../shared/qrcode';

@Component({
  selector: 'p-qrcode',
  templateUrl: './p-qrcode.component.html',
})
export class PQRcodeComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;

  constructor() { }

  ngOnInit() {
    generateQrcode(this.value, '.qrcode');
  }
}
