//
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Time9 } from '../shared/time9';

import { ApiService } from './api.service';
import { ConfigService } from './config.service';

//
export interface Rule {
  basic: string;
  maxDays: number;
  minDays: number;
  startDate: number;
}

export interface Capacity {
  time: number;
  level: number;
  count: any;
}

//
interface HolidayRes {
  holidayList: number[];
}

interface CalendarCapacityReq {
  dcReserveCompanyNo: number;
  dcParkingNo: number;
  dcParkingSpaceNo: number;
  reserveTicketNo: string;
}

interface CalendarCapacityRes {
  reserveBasic: string;
  mainMessage: string;
  reserveMaxDays: number;
  reserveMinDays: number;
  selectStartDate: number;
  reservationCapacityInfoList: CalendarCapacity[];
}

interface CalendarCapacity {
  freeCount: number;
  reserveDate: number;
  crowdCode: string;
}

//
const url = {
  calendar: {
    capacity: '/calendar/capacity',
    holiday: '/calendar/holiday',
  }
};


declare var is_staff;
//
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private holidays: number[];
  rule: Rule;
  capacitys: Capacity[];

  //
  constructor(
    private api: ApiService,
    private conf: ConfigService
  ) { }

  // holidaysがあれば、応答
  // holidaysが無ければ、取得して応答
  getHoliday = () => {
    const subj = new Subject<number[]>();

    if (this.holidays)
      setTimeout(() => {
        subj.next(this.holidays);
      }, 0);
    else
      this.api.get<HolidayRes>(url.calendar.holiday, 1).subscribe(
        res => {
          this.holidays = res.body.holidayList;
          subj.next(this.holidays);
        }
      );

    return subj;
  }

  //
  getCapacity = (space: number, ticket: string) => {
    const subj = new Subject<{
      rule: Rule,
      capacitys: Capacity[]
    }>();

    if (space) {
      this.conf.setSpace(space);

      const req: CalendarCapacityReq = {
        dcReserveCompanyNo: this.conf.reserveCompany,
        dcParkingNo: this.conf.getParkingNo(space),
        dcParkingSpaceNo: space,
        reserveTicketNo: ticket
      };
      this.api.post<CalendarCapacityRes>(url.calendar.capacity, req, 1).subscribe(
        res => {
          this.afterCapacity(res.body);
          subj.next({
            rule: this.rule,
            capacitys: this.capacitys
          });
        }
      );
    }

    return subj;
  }

  private afterCapacity = (body: CalendarCapacityRes) => {
    // rule
    this.rule = {
      basic: body.reserveBasic,
      maxDays: body.reserveMaxDays,
      minDays: body.reserveMinDays,
      startDate: body.selectStartDate
    };

    // capacity
    this.capacitys = body.reservationCapacityInfoList ?
      body.reservationCapacityInfoList.map(c => ({
        time: c.reserveDate,
        level: +c.crowdCode,
        count: c.freeCount
      })) : [];

    // for 現地テスト
    // const t = this.config.start_date;
    // if (t)
    //   this.capacitys = this.capacitys.filter(c => t <= c.time);

    // sort
    this.capacitys = this.capacitys.sort((t1, t2) => t1.time - t2.time);
    if (!is_staff && this.capacitys[0].time < Time9.today0().valueOf()) {
      let i = 0;
      while (this.capacitys[i].time < Time9.today0().valueOf()) {
        this.capacitys.shift();
      }
    }
  }

  // 0:空きあり
  checkDate = (t1: number, t2: number) => {
    const h2 = 1000 * 60 * 60 * 2;
    const capa: number[] = [];

    this.capacitys.forEach(c => {
      if (t1 - h2 <= c.time && (!t2 || c.time <= t2 + h2) && (c.level === 1 || c.level === 2))
        capa.push(c.time);
    });

    if (!capa.length)
      return -1;
    if (!(t1 - h2 < capa[0] && capa[0] < t1 + h2))
      return 1;

    const l = Math.round((t2 - t1) / (12 * h2) + 1);
    if (capa.length !== l)
      return 2;

    return 0;
  }
}
