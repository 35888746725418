import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

//
interface Notify {
  updateNotice: boolean;
  updateBeforeNotice: boolean;
};

//
@Injectable({
  providedIn: 'root'
})
export class MailNotifyModel {
  updateNotice: boolean;
  updateBeforeNotice: boolean;

  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  setModel = (notify: Notify) => {
    this.completed = false;

    this.updateNotice = notify.updateNotice;
    this.updateBeforeNotice = notify.updateBeforeNotice;

    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('mailnotify', this);
  }

  loadStorage = () => {
    this._storage.load('mailnotify', this);
  }
}
