import { Component } from '@angular/core';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { Location } from '@angular/common';

@Component({
  selector: 'change-complete',
  templateUrl: './complete.component.html'
})
export class ChangeCompleteComponent {
  receiptNo: string;
  constructor(public api: PasscardApiProxy,
    location: Location) {
    this.receiptNo = location.getState()?.['receiptNo'];
  }

  /**
   * 申請状況を確認する
   */
  onConfirmApply() {
    this.api.getPasscardApplyInfoAndNavi(true);
  }
}
