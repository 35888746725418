import { Component, Input } from '@angular/core';
import { ValidateService } from '../../services';

//
@Component({
  selector: 'i-number',
  templateUrl: './i-number.component.html',
})
export class INumberComponent {
  @Input() model: { number: string };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() label: string;

  message: string[];

  constructor(
    public valid: ValidateService
  ) { }

  //
  validation = () => {
    const msg = this.valid;

    const fn = msg.validRequired;
    this.message = fn(this.model.number, this.label);
    msg.pushNumber(this.message, this.model.number, 'お問い合わせ番号');

    return this.message && !this.message.length;
  }

}
