<div *ngIf="model.byCompanyName!=='0'"><div class="tableLine">
  <div class="tableCell_l">{{label}}
    <span *ngIf="model.byCompanyName==='2'"> 
      <span *ngIf="spanRequire" class="require">必須</span>
    </span>
  </div>
  <div class="tableCell_r">
    <input [(ngModel)]="model.wsPhone" (keyup)="onChange()"
      [ngClass]="{'error_border_r':valid.hasError(message, messages)}" type="tel" class="w100" maxlength="11"
      placeholder="0123456789">
    <p-error [messages]="valid.merge(message, messages)"></p-error>
    <p class="tel-attent-text">※ハイフン(-)は不要です。</p>
  </div>
</div>
</div>
