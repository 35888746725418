<div class="nav">
  <div (click)="onTopBtn1Click()">
    <label class="parking-button" title="{{btn1Caption}}">
      <i class="button-item-icon" [ngClass]="btn1Icon"></i>
      <div class="button-item-text">{{btn1Caption}}</div>
    </label>
  </div>
  <div (click)="onTopBtn2Click()">
    <label class="parking-button" title="{{btn2Caption}}">
      <i class="button-item-icon" [ngClass]="btn2Icon"></i>
      <div class="button-item-text">{{btn2Caption}}</div>
    </label>
  </div>
</div>