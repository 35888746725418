import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AppModel } from 'src/app/app.model';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements AfterViewInit {
  private _footerBottom = 0;
  scroll = false;
  height = 0;
  @ViewChild('footer') footer: ElementRef<HTMLElement>
  @ViewChild('archor') archor: ElementRef<HTMLElement>

  constructor(public app: AppModel) {
  }

  ngAfterViewInit(): void {
    this.onScrollStateChange(this.isScroll);
    const height = this.footer.nativeElement.offsetHeight;
    this._footerBottom = this.archor.nativeElement.getBoundingClientRect().top + height;
    this.archor.nativeElement.style.height = `${height}px`;
  }

  ngDoCheck() {
    if (this.footer?.nativeElement) {
      const height = this.footer.nativeElement.offsetHeight;
      this._footerBottom = this.archor.nativeElement.offsetTop + height;

      if (this.footer.nativeElement.style.position == 'fixed') {
        const { left, right } = this.archor.nativeElement.getBoundingClientRect();
        if (left != this.footer.nativeElement.getBoundingClientRect().left) {
          let r = window.innerWidth - right;
          this.footer.nativeElement.style.left = `${left}px`;
          this.footer.nativeElement.style.right = `${r}px`;
        }
      }
    }

    this.onWindowResize();
  }

  @HostListener("window:resize", [])
  private onWindowResize() {
    if (this.isScroll != this.scroll) {
      this.onScrollStateChange(this.isScroll);
    }
  }

  get isScroll() {
    return this._footerBottom >= document.documentElement.clientHeight;
  }

  onScrollStateChange(scrollState: boolean) {
    if (!this.archor) return;

    this.scroll = scrollState;
    setTimeout(() => {
      if (this.scroll) {
        if (this.footer.nativeElement.style.position)
          this.footer.nativeElement.style.position = '';
      }
      else {
        if (this.footer.nativeElement.style.position != 'fixed') {
          const { left, right } = this.archor.nativeElement.getBoundingClientRect();
          let r = window.innerWidth - right;
          this.footer.nativeElement.style.position = 'fixed';
          this.footer.nativeElement.style.left = `${left}px`;
          this.footer.nativeElement.style.right = `${r}px`;
          this.footer.nativeElement.style.bottom = '0';
        }
      }
    });
  }

  onOpenUrl = (e: Event, url: string, name: string, disabled = false) => {
    e.preventDefault();
    if (!disabled)
      window.open(url, name);
  }

}
