<header>
  <div class="top">
    <div class="title">
      <div class="logo">
        <img class="logoImage" src="{{app.publishedDocumentInfo.headerImageURL}}">&nbsp;
      </div>
      <div>定期利用申込サービス</div>
    </div>
    <div><a class="passcard-link" *ngIf="showLink && app.publishedDocumentInfo.applySiteTopLinkURL && app.publishedDocumentInfo.applySiteTopLinkName"
      target="top-link" href="{{app.publishedDocumentInfo.applySiteTopLinkURL}}">{{app.publishedDocumentInfo.applySiteTopLinkName}}</a></div>
  </div>
</header>
