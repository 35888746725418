import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-securityNumber',
  templateUrl: './security-number.component.html',
})
export class SecurityNumberComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
