import { Component, Input } from '@angular/core';

@Component({
  selector: 'flow-box',
  templateUrl: './flow-box.component.html'
})
export class FlowBoxComponent {
  @Input() processed: number = 0;
  @Input() processing: number = 0;
  @Input() steps: string[] = [];

  getActiveClass(proc: number) {
    return proc == this.processing ? 'active' : (proc < this.processing ? 'done' : null);
  }

}
