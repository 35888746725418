import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AppModel } from 'src/app/app.model';
import { ApiService } from 'src/app/services';
import { LostService } from './lost.service';

@Component({
  selector: 'lost-handler',
  templateUrl: './lost-handler.component.html'
})
export class LostHandlerComponent {
  constructor(
    public app: AppModel,
    public api: ApiService,
    lostService: LostService,
    location: Location) {
    const navigateState = location.getState();
    if (navigateState?.['contract']) {
      lostService.passcardApply = navigateState?.['contract'];
      lostService.delivery = lostService.passcardApply;
    }
  }
}
