import { Component, OnInit } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';
import { MailNotifyModel } from '../mail-notify.model';

//
interface Req {
  applyUpdateNoticeType: boolean;
  applyUpdateBeforeNoticeType: boolean;

}

interface Res {
  resultCode: number;
  remindNoticeType: boolean;
  inPossibleNoticeType: boolean;
  inMailNoticeType: boolean;
  outPossibleNoticeType: boolean;
}

//
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {

  constructor(
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
    public model: MailNotifyModel,
  ) { }

  ngOnInit() { }

  //
  hasNotify = () => {
    const n = this.model;
    return n.updateNotice || n.updateBeforeNotice;
  }

  //
  private afterPost = (body: Res) => {

    if (body.resultCode === 0) {
      this.model.apply();
      this.navi.navigateByUrl('/mypage/mail-notify/complete');
    } else
      this.api.enabled();
  }

  //
  onApply = () => {
    this.api.disabled = true;
    const m = this.model;

    const req: Req = {
      applyUpdateNoticeType:m.updateNotice,
      applyUpdateBeforeNoticeType:m.updateBeforeNotice
    };

    this.api.put<Res>('/notice/update', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
