<div class="tableLine">
  <div class="tableCell_l">{{label}}</div>

  <div class="tableCell_r">
    <div class="selectTime">
      <div class="selectBox">
        <select [(ngModel)]="model.year" (change)="onChangeYear()"
          [ngClass]="{'error_border_r':valid.hasError(msgYear)}">
          <option value="">--</option>
          <option *ngFor="let op of option_y" [value]="op.value">{{op.text}}</option>
        </select>
      </div>
      <span>年</span>

      <div class="selectBox">
        <select [(ngModel)]=" model.month" (change)="onChangeMonth()"
          [ngClass]="{'error_border_r':valid.hasError(msgMonth)}">
          <option value="">--</option>
          <option *ngFor="let op of option_m" [value]="op.value">{{op.text}}</option>
        </select>
      </div>
      <span>月</span>

      <div class="selectBox">
        <select [(ngModel)]="model.hour" [ngClass]="{error_border_r:valid.hasError(msgHour)}">
          <option value="">--</option>
          <option *ngFor="let op of option_h" [value]="op.value">{{op.text}}</option>
        </select>
      </div>
      <span>時</span>

      <div class="selectBox minute">
        <select [(ngModel)]="model.minute" [ngClass]="{error_border_r:valid.hasError(msgMinute)}">
          <option value="">--</option>
          <option *ngFor="let op of option_M" [value]="op.value">{{op.text}}</option>
        </select>
      </div>
      <span>分</span>
    </div>

    <p-error [messages]="valid.merge(message, messages)"></p-error>
  </div>
</div>