<div *ngIf="model.byCarInfo!=='0'">
  <div class="tableLine">
    <div class="tableCell_l">{{label}}
      <span *ngIf="model.byCarInfo==='2'">  
        <span *ngIf="spanRequire" class="require">必須</span>
      </span>
    </div>
    <div class="tableCell_r carEntry">
      <div class="numberplate">
        <div class="carBox">
          <div class="carTop">
            <input [(ngModel)]="model.numberPlate[0]" [ngClass]="{'error_border_r':valid.hasError(message1, okng[0])}"
              (keyup)="onChange(0)" type="text" maxlength="10" class="carArea" placeholder="横浜">
            <input [(ngModel)]="model.numberPlate[1]" [ngClass]="{'error_border_r':valid.hasError(message2, okng[1])}"
              (keyup)="onChange(1)" type="text" maxlength="3" class="carNumber01" placeholder="555">
          </div>
          <div class="carBottom">
            <div class="carFont">
              <select [(ngModel)]="model.numberPlate[2]" [ngClass]="{'error_border_r':valid.hasError(message3, okng[2])}"
                (change)="onChange(2)" class="selectFont">
                <option value="">-</option>
                <option *ngFor="let s of carInfo.npMoji" [value]="s">{{s}}</option>
              </select>
            </div>
            <input [(ngModel)]="model.numberPlate[3]" [ngClass]="{'error_border_r':valid.hasError(message4, okng[3])}"
              (keyup)="onChange(3)" type="tel" maxlength="4" class="carNumber02" placeholder="1234">
          </div>
        </div>
      </div>
      <p-error [messages]="valid.merge(message, messages)"></p-error>
      <p class="car-text">車両番号:[・・・1]は[1]とご記入ください。</p>
    </div>
  </div>
</div>