<t-error [message]="errorLimit"></t-error>
<t-error *ngIf="!errorLimit" [message]="error"></t-error>

<p class="formText">ご使用されるクレジットカード情報を入力・選択し「次へ」を押してください。<br>※本システムは、クレジットカード情報非保持化に対応しています。</p>

<reserve-credit
  [model]="model"
  (change)="onChange1()"
  (keyup)="onChange1()"
  [messages]="msgCredit">
</reserve-credit>

<br>
<div *ngIf="conf.use_etc" class="termConsent">
  <ul>
    <li>
      <label>
        <input type="checkbox" [(ngModel)]="etc" class="termConsent-check">
        <span class="checkbox">ETC利用規約に同意し、ETCを登録する</span>
      </label>
    </li>
  </ul>
</div>

<reserve-etccard *ngIf="etc"
  [model]="model"
  (change)="onChange2()"
  (keyup)="onChange2()"
  [messages]="msgEtcCard">
</reserve-etccard>

<div *ngIf="!model.completed" class="buttonBox">
  <button (click)="navi.goBack()" class="cmn-btn btn_w_25 mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="disabled"
    [loading]="api.disabled"
    [label]="'次へ'"
    [class]="'cmn-btn btn_w_25'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>