import { Component, OnInit } from '@angular/core';

import { NavigateService } from '../../services';
import { RegistrationModel } from '../registration.model';

//
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
})
export class PaymentComponent implements OnInit {

  constructor(
    public model: RegistrationModel,
    private navi: NavigateService,
  ) { }

  ngOnInit() { }

  onApply = () => {
    this.model.saveStorage();
    this.navi.navigateByUrl('/registration/confirm2');
  }

}
