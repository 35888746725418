import { StatusTipComponent } from './common/status-tip/status-tip.component';
import { CardComponent } from './common/card/card.component';
import { ContractInfoComponent } from './common/contract-info/contract-info.component';
import { DeliveryComponent } from './common/delivery/delivery.component';
import { CompleteComponent } from './common/complete/complete.component';
import { Apply01Component, PasscardTypesResolver } from './apply/steps/step01/apply01.component';
import { Apply02Component } from './apply/steps/step02/apply02.component';
import { Apply03Component } from './apply/steps/step03/apply03.component';
import { Apply04Component } from './apply/steps/step04/apply04.component';
import { PlaceInfoComponent } from './common/place-info/place-info.component';
import { ModalComponent } from './common/modal/modal.component';
import { ApplyComponent } from './apply/apply.component';
import { FlowBoxComponent } from './common/flow-box/flow-box.component';
import { Routes } from '@angular/router';
import { ApplyGuideComponent } from './apply-guide/apply-guide.component';
import { PassCardComponent } from './passcard.component';
import { PasscardInfoComponent } from './common/passcard-info/passcard-info.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { IUsernameComponent } from './common/i-username/i-username.component';
import { ContractComponent } from './contract/contract.component';
import { ChangeConfirmComponent } from './change-confirm/change-confirm.component';
import { LostHandlerComponent } from './lost-handler/lost-handler.component';
import { Lost04Component } from './lost-handler/steps/lost04/lost04.component';
import { Lost03Component } from './lost-handler/steps/lost03/lost03.component';
import { Lost02Component } from './lost-handler/steps/lost02/lost02.component';
import { Lost01Component } from './lost-handler/steps/lost01/lost01.component';
import { ChangeCompleteComponent } from './change-confirm/complete/complete.component';
import { ApplyInfoComponent } from './reservation/apply-info/apply-info.component';
import { ApplyCancelComponent } from './reservation/apply-cancel/apply-cancel.component';
import { CancelCompleteComponent } from './reservation/apply-cancel/complete/complete.component';
import { ContractDetailComponent } from './contract/contract-detail/contract-detail.component';
import { DeliveryInputComponent } from './common/delivery-input/delivery-input.component';
import { DeliveryEditComponent } from './reservation/delivery-edit/delivery-edit.component';
import { DeliveryConfirmComponent } from './reservation/delivery-edit/changed-confirm/changed-confirm.component';
import { EditDeliveryComponent } from './reservation/delivery-edit/edit-delivery/edit-delivery.component';
import { DeliveryEditCompleteComponent } from './reservation/delivery-edit/complete/complete.component';
import { PaginationComponent } from './common/pagination/pagination.component';
import { NoticeInfoComponent } from './common/notice-info/notice-info.component';

export const passcardComponents = [
  ApplyGuideComponent,
  ApplyComponent,
  DeliveryComponent,
  StatusTipComponent,
  Apply01Component,
  Apply02Component,
  Apply03Component,
  Apply04Component,
  Lost01Component,
  Lost02Component,
  Lost03Component,
  Lost04Component,
  CardComponent,
  PasscardInfoComponent,
  ContractInfoComponent,
  ChangeConfirmComponent,
  LostHandlerComponent,
  CompleteComponent,
  PlaceInfoComponent,
  ModalComponent,
  FlowBoxComponent,
  ContractComponent,
  ReservationComponent,
  ReservationsComponent,
  PassCardComponent,
  IUsernameComponent,
  ChangeCompleteComponent,
  ApplyInfoComponent,
  ApplyCancelComponent,
  CancelCompleteComponent,
  ContractDetailComponent,
  DeliveryInputComponent,
  DeliveryEditComponent,
  DeliveryConfirmComponent,
  EditDeliveryComponent,
  DeliveryEditCompleteComponent,
  PaginationComponent,
  NoticeInfoComponent,
];


export const ticketRoutes: Routes = [
  { path: '', component: ApplyGuideComponent },
  { path: 'guide', component: ApplyGuideComponent },
  {
    path: 'passcard/new', component: ApplyComponent, children: [
      { path: '', component: Apply01Component, resolve: { data: PasscardTypesResolver } },
      { path: 'step1', component: Apply01Component, resolve: { data: PasscardTypesResolver } },
      { path: 'step2', component: Apply02Component },
      { path: 'step3', component: Apply03Component },
      { path: 'complete', component: Apply04Component },
    ]
  },
  {
    path: 'passcard/delivery-edit', component: DeliveryEditComponent, children: [
      { path: '', component: EditDeliveryComponent },
      { path: 'step1', component: EditDeliveryComponent },
      { path: 'step2', component: DeliveryConfirmComponent },
      { path: 'complete', component: DeliveryEditCompleteComponent },
    ]
  },
  { path: 'passcard/list', component: ReservationsComponent },
  { path: 'passcard/cancel-complete', component: CancelCompleteComponent },
  { path: 'passcard/cancel-apply', component: ApplyCancelComponent },
  { path: 'passcard/rejection', component: ChangeConfirmComponent },
  { path: 'passcard/change-ok', component: ChangeCompleteComponent },
  {
    path: 'passcard/lost-handler', component: LostHandlerComponent, children: [
      { path: '', component: Lost01Component },
      { path: 'step1', component: Lost01Component },
      { path: 'step2', component: Lost02Component },
      { path: 'step3', component: Lost03Component },
      { path: 'complete', component: Lost04Component },
    ]
  },
  { path: 'passcard/contract', component: ContractComponent },
  { path: 'passcard/applyInfo', component: ReservationComponent },
];
