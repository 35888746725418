<h2>通学/勤務先</h2>
<t-error [message]="error"></t-error>

<section>
  <div class="tableCtn">
    <i-ws-name
      [model]="model"
      (keyup)="onKeyup()"
      [label]="'通学/勤務先名'"
      [messages]="messages.wsName"
      [spanRequire]="true">
    </i-ws-name>
    <i-ws-kana
      [model]="model"
      (keyup)="onKeyup()"
      [label]="'通学/勤務先名（カナ）'"
      [messages]="messages.wsKana"
      [spanRequire]="true">
    </i-ws-kana>
    <i-ws-phone 
      [model]="model"
      [label]="'電話番号'"
      [messages]="messages.wsTelephoneNumber"
      (keyup)="onKeyup()"
      [spanRequire]="true">
    </i-ws-phone>
    <i-ws-address
      [model]="model"
      (keyup)="onKeyup()"
      [messages]="messages.wsPostcodeList"
      [label]="'郵便番号'"
      [label1]="'郵便番号'"
      [label2]="'住所1'"
      [label3]="'住所2'"
      [messages]="messages.wsPostcodeList"
      [messages1]="messages.wsAddress1"
      [messages2]="messages.wsAddress2"
      [spanRequire]="true">
    </i-ws-address>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="disabled"
    [loading]="api.disabled"
    [label]="'確認'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>