import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class MotorcycleInfoModel {
  motorcycleNumberPlate: string;

  byApplyMotorcycleNumber: string;

  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = (motorcycleNumberPlate:string, byMotorcycleInfo:string) => {
    this.completed = false;
    this.motorcycleNumberPlate = motorcycleNumberPlate;
    this.byApplyMotorcycleNumber = byMotorcycleInfo;

    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  setModel = () => {
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('motorcycleInfo', this);
  }

  loadStorage = () => {
    this._storage.load('motorcycleInfo', this);
  }
}
