<h2>お支払い情報変更確認</h2>
<t-error [message]="error"></t-error>

<p class="formText">以下の内容で更新します。<br>内容を確認後、「確定」を押してください。</p>

<section>
  <div class="tableCtn tableCtn-confirm">
    <p-single [label]="'変更後 クレジットカード番号（一部省略）'" [value]="model.veritrans.reqCardNumber"></p-single>
    <p-single *ngIf="conf.use_etc" [label]="'ETCカード番号'" [value]="model.etccard.masked"></p-single>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBox">
  <button (click)="navi.goBack()" class="cmn-btn btn_w_25 mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn btn_w_25'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>