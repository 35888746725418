import { Routes } from '@angular/router';

import { CompleteComponent } from './complete/complete.component';
import { ConfigComponent } from './config/config.component';
import { MailComponent } from './mail/mail.component';
import { SendComponent } from './send/send.component';

//
export const routes: Routes = [{
  path: 'complete',
  component: CompleteComponent
}, {
  path: 'confirm/:p1/:p2',
  component: ConfigComponent
}, {
  path: 'mail',
  component: MailComponent
}, {
  path: 'send',
  component: SendComponent
}];

//
export const components = [
  CompleteComponent,
  ConfigComponent,
  MailComponent,
  SendComponent,
];
