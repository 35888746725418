//
import { Routes } from '@angular/router';

import { TopComponent } from './top/top.component';


import { CarInfoComponent } from '././car-info/car-info.component';
import { routes as carInfoRoutes, components as carInfoComponents }
  from '././car-info/car-info-routing.module';

import { MotorcycleInfoComponent } from '././motorcycle-info/motorcycle-info.component';
import { routes as motorcycleInfoRoutes, components as motorcycleInfoComponents }
  from '././motorcycle-info/motorcycle-info-routing.module';


import { CreditComponent } from './credit/credit.component';
import { routes as creditRoutes, components as creditComponents }
  from './credit/credit-routing.module';

import { MailAddrComponent } from './mail-addr/mail-addr.component';
import { routes as mailAddrRoutes, components as mailAddrComponents }
  from './mail-addr/mail-addr-routing.module';

import { MailNotifyComponent } from './mail-notify/mail-notify.component';
import { routes as mailNotifyRoutes, components as mailNotifyComponents }
  from './mail-notify/mail-notify-routing.module';

import { PasswdComponent } from './passwd/passwd.component';
import { routes as passwdRoutes, components as passwdComponents }
  from './passwd/passwd-routing.module';

import { PhoneComponent } from './phone/phone.component';
import { routes as phoneRoutes, components as phoneComponents }
  from './phone/phone.module';

import { NameBirthdateComponent } from './name-birthdate/name-birthdate.component';
import { routes as nameBirthdateRoutes, components as nameBirthdateComponents }
  from './name-birthdate/name-birthdate-routing.module';

import { AddressComponent } from './address/address.component';
import { routes as addressRoutes, components as addressComponents }
  from './address/address-routing.module';

import { SecurityNumberComponent } from './security-number/security-number.component';
import { routes as securityNumberRoutes, components as securityNumberComponents }
  from './security-number/security-number-routing.module';

  import { WsInfoComponent } from './ws-info/ws-info.component';
import { routes as wsInfoRoutes, components as wsInfoComponents }
  from './ws-info/ws-info-routing.module';
/*
import { CarInfoComponent } from './carinfo/carinfo.component';
import { routes as carInfoRoutes, components as carInfoComponents }
  from './carinfo/carinfo-routing.module';
*/

//
export const routes: Routes = [{
  path: 'top',
  component: TopComponent
}, {
  path: 'car-info',
  component: CarInfoComponent,
  children: carInfoRoutes
}, {
  path: 'motorcycle-info',
  component: MotorcycleInfoComponent,
  children: motorcycleInfoRoutes
}, {
  path: 'credit',
  component: CreditComponent,
  children: creditRoutes
}, {
  path: 'mail',
  component: MailAddrComponent,
  children: mailAddrRoutes
}, {
  path: 'mail-notify',
  component: MailNotifyComponent,
  children: mailNotifyRoutes
}, {
  path: 'passwd',
  component: PasswdComponent,
  children: passwdRoutes
}, {
  path: 'phone',
  component: PhoneComponent,
  children: phoneRoutes
}, {
  path: 'name-birthdate',
  component: NameBirthdateComponent,
  children: nameBirthdateRoutes
}, {
  path: 'address',
  component: AddressComponent,
  children: addressRoutes
}, {
  path: 'security-number',
  component: SecurityNumberComponent,
  children: securityNumberRoutes
}, {
  path: 'ws-info',
  component: WsInfoComponent,
  children: wsInfoRoutes
}];

//
export const components = [
  TopComponent,
  CarInfoComponent,
  MotorcycleInfoComponent,
  CreditComponent,
  MailAddrComponent,
  MailNotifyComponent,
  PasswdComponent,
  PhoneComponent,
  NameBirthdateComponent,
  AddressComponent,
  SecurityNumberComponent,
  WsInfoComponent,
  ...carInfoComponents,
  ...motorcycleInfoComponents,
  ...creditComponents,
  ...mailAddrComponents,
  ...mailNotifyComponents,
  ...passwdComponents,
  ...phoneComponents,
  ...nameBirthdateComponents,
  ...addressComponents,
  ...securityNumberComponents,
  ...wsInfoComponents,
];
