import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-motorcycleinfo',
  templateUrl: './motorcycle-info.component.html',
})
export class MotorcycleInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
