<h3>ETCカード情報</h3>
<section>
  <div class="tableCtn">
    <div class="tableLine">
      <div class="tableCell_l">ETCカード番号</div>
      <div class="tableCell_r">
        <input [(ngModel)]="etcNumber" [ngClass]="{'error_border_r':valid.hasError(msgEtcCard, messages.number)}"
          (keyup)="onChange1()" type="tel" class="w100" maxlength="19" placeholder="123456789012345">
        <p-error [messages]="valid.merge(msgEtcCard, messages.number)"></p-error>
      </div>
    </div>
    <div class="tableLine">
      <div class="tableCell_l">有効期限</div>
      <div class="tableCell_r">
        <div class="cardNumber">
          <div class="selectBox">
            <select [(ngModel)]="etcExpires[0]" (change)="onChange2()"
              [ngClass]="{'error_border_r':valid.hasError(msgEtcMonth, messages.expired)}">
              <option value="">--</option>
              <option *ngFor="let op of option_m" [value]="op.value">{{op.text}}</option>
            </select>
          </div>
          <span>月</span>
          <span>/</span>
          <div class="selectBox">
            <select [(ngModel)]="etcExpires[1]" (change)="onChange2()"
              [ngClass]="{'error_border_r':valid.hasError(msgEtcYear, messages.expired)}">
              <option value="">--</option>
              <option *ngFor="let op of option_y" [value]="op.value">{{op.text}}</option>
            </select>
          </div>
          <span>年</span>
        </div>
        <p-error [messages]="valid.merge(msgEtcExpired, messages.expired)"></p-error>
      </div>
    </div>
  </div>
</section>