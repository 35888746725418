import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ReservationStatus, PasscardStatus, PasscardApply, mapStatus, NOSHOWORDER } from '../passcard.model';
import { AppModel } from 'src/app/app.model';
import { ApiService, NavigateService } from 'src/app/services';
import { PasscardBookingService } from '../passcard-booking.service';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { DeliveryEditService } from './delivery-edit/delivery-edit.service';

@Component({
  selector: 'passcard-reservation',
  templateUrl: './reservation.component.html'
})
export class ReservationComponent {
  BS = ReservationStatus;

  reservation: PasscardApply | null = null;
  viewOnly = false;

  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    private _cacheService: PasscardBookingService,
    private _editService: DeliveryEditService,
    private _navi: NavigateService,
    location: Location) {
    const navigateState = location.getState();
    this.reservation = navigateState?.['reservation'];
    if (this.reservation) {
      this.reservation.currentNum ??= NOSHOWORDER;
    }
  }

  get Status() {
    if (this.reservation.passcardAvailable == 1) return ReservationStatus.Available;

    return mapStatus(this.reservation.status);
  }

  get ShowDeliveryEditBtn() {
    return this.reservation.applyCardDelivery != 0 && this.Status == ReservationStatus.Reject;
  }

  /**
   * 申請チップクリック
   */
  onStatusTipClick() {
    switch (this.Status) {
      case ReservationStatus.Waiting:
        window.open(this.reservation.passcardAfterShippingProcedureURL, '_blank');
        break;
      case ReservationStatus.Queuing:
        this.onCancelConfirm();
        break;
      case ReservationStatus.Recepting:
        this.onReApply();
        break;
      case ReservationStatus.Reject:
        this.onModifiedConfirm();
        break;
    }
  }

  /**
 * 申請取消確認
 */
  onCancelConfirm() {
    this._cacheService.cancelComplete = false;
    this._navi.navigate(`/passcard/cancel-apply`, false, { reservation: this.reservation });
  }

  /**
   * 再申請
   */
  onReApply() {
    this._cacheService.reset();
    this._cacheService.passcardApplyNo = this.reservation.passcardApplyNo;
    this._navi.navigate('/passcard/new',
      false,
      {
        action: 'reApply',
        passcardApplyNo: this.reservation.passcardApplyNo,
        passcard: { ...this.reservation, status: PasscardStatus.Available }
      });
  }

  /**
   * 修正確認
   */
  onModifiedConfirm() {
    this._cacheService.changeComplete = false;
    this._navi.navigate(`/passcard/rejection`,
      false,
      this.reservation);
  }

  /**
   * 配送先情報編集
   */
  async onEditDelivery() {
    this._editService.reset();
    const myPage = await this.api.deliveryInfo();
    this._navi.navigate('/passcard/delivery-edit', false, {
      applyInfo: this.reservation,
      myPage
    });
  }
}
