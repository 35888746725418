import { Component } from '@angular/core';

import { NavigateService } from '../../../services';

import { CreditModel } from '../credit.model';
import { MypageModel } from '../../mypage.model';

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent {

  constructor(
    public model: CreditModel,
    public mypage: MypageModel,
    private navi: NavigateService,
  ) { }

  //
  onApply = () => {
    this.model.saveStorage();
    this.navi.navigateByUrl('/mypage/credit/confirm');
  }
}
