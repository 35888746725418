<h1><span class="h_ttl">メッセージ</span></h1>

<div id="container">
  <div *ngIf="info" class="msg-detail">
    <h2>{{info.title}}</h2>
    <div class="date">{{info.time|number2date}}</div>
    <div class="msg-content">{{info.detail}}</div>
  </div>

  <div class="btn-box">
    <button (click)="navi.goBack()" class="c-btn">戻る</button>
  </div>
</div>