<h2>電話番号変更確認</h2>
<t-error [message]="error" [messages]="errors"></t-error>

<t-info [value1]="'以下の内容で電話番号を更新します。'" [value2]="'内容を確認し、「確定」を押してください。'"></t-info>


<section>
  <div class="tableCtn tableCtn-confirm">
    <div *ngIf="model.byUserTel!=='0'">
      <p-single [label]="'電話番号'" [value]="model.phone"></p-single>
    </div>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>