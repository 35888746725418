import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { PasscardContract } from '../models/passcard-contract';
import { AppModel } from 'src/app/app.model';
import { ApiService, NavigateService } from 'src/app/services';
import { LostService } from '../lost-handler/lost.service';
import { ApplyStatus } from '../passcard.model';

/**
 * 定期契約情報
 */
@Component({
  selector: 'contract',
  templateUrl: './contract.component.html'
})
export class ContractComponent {
  contract: PasscardContract | null = null;
  passcardApplyNo: number;

  constructor(public app: AppModel,
    public api: ApiService,
    private _navi: NavigateService,
    private _lostService: LostService,
    location: Location) {
    const state = location.getState();
    this.contract = state['contract'];
    this.passcardApplyNo = this.contract['passcardApplyNo'];
  }

  get IsRecepting() {
    const status = this.contract['status'];
    return status == ApplyStatus.WaitingReissueForLost
      || status == ApplyStatus.WaitingDeliveryFoReissue
      || status == ApplyStatus.WaitingPayAfterReissue;
  }

  get NoticeInfo(){
    return this.contract['noticeInfoList'];
  }

  onStatusTipClick() {
    if (this.IsRecepting) {
      window.open(this.contract['passcardAfterShippingProcedureURL'], '_blank');
    }
  }

  get ShowButtons() {
    return this.contract['menuSettingLostReissue'] != 0;
  }

  /**
   * 紛失・破損・シール再発行処理
   */
  onReissue(action: string) {
    this._lostService.reset();
    this._navi.navigate('/passcard/lost-handler',
      false,
      {
        action: action,
        contract: this.contract
      });
  }

  /**
   * 定期券の解約
   */
  onCancelConfirm() {
    this._lostService.cancelComplete = false;
    this._navi.navigate('/passcard/cancel-passcard',
      false,
      {
        contract: this.contract
      });
  }
}
