export function zero2(n: number) {
  return (n < 10 ? '0' : '') + Math.floor(n);
}

export function option_numbers(i1: number, i2: number, step = 1) {
  const op = [];
  for (let i = i1; i < i2 + 1; i += step)
    op.push({ text: zero2(i), value: zero2(i) });

  return op;
}

// push message to list
export function ifPush(a: Array<any>, o: any) {
  if (a && !a.length)
    a.push(o);
}

export function minute2obj(minute: number) {
  const days = Math.floor(minute / (60 * 24));
  const m = minute - days * 60 * 24;
  const hour = Math.floor(m / 60);

  return {
    d: days,
    h: hour,
    m: m % 60
  };
}

export function minute2string(minute: number) {
  const o = minute2obj(minute);
  const a = [];
  if (o.d)
    a.push(o.d + '日');
  if (o.h)
    a.push(o.h + '時間');

  if (o.m)
    if (o.d || o.h)
      a.push(zero2(o.m) + '分');
    else
      a.push(o.m + '分');

  if (a.length === 0)
    a.push('0分');

  return a.join('');
}

// MM/YY -> YYYY/MM/01
export function creditExpDate(str: string) {
  if (str) {
    const s = str.split('/');
    if (s && s.length)
      return `20${s[1]}/${s[0]}/01`;
  }

  return '';
}

// 01/20 -> time
function expired2time(expired: string) {
  if (expired) {
    const s = expired.split('/');
    const m = +s[0];
    if (m)
      return Date.UTC(+s[1] + 2000, m - 1, 1, -9, 0, 0, 0);
  }
  return null;
}

export function isTablet() {
  return 720 <= document.body.clientWidth;
}

export function hasNP(NP: string[]) {
  return NP && NP[0];
}

//
export function deepClone(o) {
  return JSON.parse(JSON.stringify(o));
}

export function deepEq(o1, o2) {
  return JSON.stringify(o1) === JSON.stringify(o2);
}
