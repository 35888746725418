<h2>定期紛失</h2>
<flow-box [steps]="Steps" [processing]="CurrentProc" [processed]="CurrentProc" />

<complete message1="定期券の紛失申請が完了しました。" [message2]="message2"></complete>
<div class="apply-status">
  <span class="status-color">受付No.{{receiptNo}}</span>
</div>
<br>
<div class="buttonBox">
  <loading-button (apply)="onBackToContract()" [disabled]="api.Disabled" [loading]="api.Disabled" [label]="'定期情報ページに戻る'"
    [class]="'cmn-btn bck-btn2 six'" [lClass]="'button-box-loading'" />
</div>