import { Component, Input } from '@angular/core';
import { hasNP } from '../../utils';

@Component({
  selector: 'amn-np',
  templateUrl: './np.component.html',
})
export class NPComponent {
  @Input() np: string[];

  constructor() { }

  hasNP = () => hasNP(this.np);
}
