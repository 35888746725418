import { Component } from '@angular/core';
import { deliveryInputToDelivery, deliveryToDeliveryInput, getDefaultDelivery, myPageToDeliveryInput } from '../../../passcard.model';
import { PasscardBookingService } from '../../../passcard-booking.service';
import { NavigateService, ValidateService } from 'src/app/services';
import { AppModel } from 'src/app/app.model';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { DeliveryInputModel } from 'src/app/passcard/common/delivery-input/delivery-input.component';

@Component({
  selector: 'apply02',
  templateUrl: './apply02.component.html'
})
export class Apply02Component {
  deliveryInfo: DeliveryInputModel;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    public valid: ValidateService,
    private _navi: NavigateService,
    private _bookingService: PasscardBookingService) {
      const temp = myPageToDeliveryInput(_bookingService.myPage);
      this.deliveryInfo = {
        kind: _bookingService.DeliveryInputKind,
        myPage: deliveryInputToDelivery(temp),
        deliveryInput: _bookingService.inputedDelivery ?? getDefaultDelivery()
      }
  }

  get IsComplete() {
    return this._bookingService.complete;
  }

  onBack() {
    this._navi.navigateByUrl('/passcard/new/step1');
  }

  onNext() {
    if (this.deliveryInfo.kind == 1 && this.deliveryInfo.error) {
      return;
    }

    this._bookingService.DeliveryInputKind = this.deliveryInfo.kind;
    if (this.deliveryInfo.kind == 1) {
      this._bookingService.inputedDelivery = this.deliveryInfo.deliveryInput;
      this._bookingService.setDelivery(this._bookingService.inputedDelivery);
    }
    else {
      this._bookingService.setDelivery(deliveryToDeliveryInput(this.deliveryInfo.myPage));
    }

    this._bookingService.maxProcessed = Math.max(this._bookingService.maxProcessed, 1);
    this._navi.navigateByUrl('/passcard/new/step3');
  }
}
