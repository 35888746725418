import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { ApiService, ConfigService, NavigateService ,StorageService } from 'src/app/services';
import { PasscardBookingService } from '../passcard-booking.service';

interface Req {
  agreementStatus: number;
};

interface Res {
  resultCode: number; 
}

@Component({
  selector: 'apply-guide',
  templateUrl: './apply-guide.component.html',
})
export class ApplyGuideComponent {

  loading: boolean;
  private _isAgree = false;
  agreeClass: string | null = null;

  @ViewChild('contents', {static: true}) content: ElementRef;

  constructor(public app: AppModel,
    public api: ApiService,
    private _cacheService: PasscardBookingService,
    private _navi: NavigateService,
    private _storage: StorageService,) {
      this._storage._save('agreementStatus', '0');
      this.loading = true;
      setTimeout(() => this.setGuideContent(), 0);
  }

  get isAgree() {
    return this._isAgree;
  }
  set isAgree(val: boolean) {
    this._isAgree = val;
    this.agreeClass = val ? 'passcard-selected' : 'passcard-normal';
  }

  onApplyStart() {
    this._cacheService.reset();
    // 利用規約同意の不具合対応
     this._storage._save('agreementStatus', '1');
    // if (this.app.login) {
    //   //this._navi.navigateByUrl('/passcard/new');
    //   // ログイン済みで利用規約に同意した場合DBに登録する
    //   const req: Req = {
    //     agreementStatus: 1
    //   };
    //   this.api.post<Res>('/user/registration/updateAgreementStatus', req, 1).subscribe(
    //     res => {
    //       if (res.body.resultCode === 0) {
    //         this._navi.navigateByUrl('/passcard/new');
    //       }
    //     }
    //   );
    // }
    // else {
    //   this._navi.navigateByUrl('/login');
    // }
    this._navi.navigateByUrl('/login');

  }

  private async setGuideContent() {
    if (this.app.publishedDocumentInfo.passcardApplyNotesDOCList?.length) {
      this.content.nativeElement.innerHTML = this.app.publishedDocumentInfo.passcardApplyNotesDOCList!.join('\n');
      this.loading = false;
      return;
    }

    const wait = (interval: number) => new Promise<void>(resolve => setTimeout(() => resolve(), interval));
    await wait(10);
    this.setGuideContent();
  }
}
