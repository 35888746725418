import { Injectable } from '@angular/core';

//
@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  //デバイスの判定
  getDevice() {
    const ua = navigator.userAgent;

    if (ua.includes('Mobile') && (ua.includes('iPhone') || ua.includes('iPod') || ua.includes('Android')))
      return 'sp';

    if (ua.includes('iPad') || ua.includes('Android'))
      return 'tab';

    return 'other';
  }

  pseudoIfPC() {
    if (this.getDevice() == 'other')
      document.querySelector('body').classList.add('pseudo');
  }
}
