import { Component } from '@angular/core';
import { DeliveryEditService } from '../delivery-edit.service';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'delivery-ef',
  templateUrl: './complete.component.html'
})
export class DeliveryEditCompleteComponent {
  constructor(public api: PasscardApiProxy,
    private _service: DeliveryEditService) {
    this._service.complete = true;
  }

  get receiptNo() {
    return this._service.receiptNo;
  }

  /**
   * 申請状況を確認する
   */
  onBackToInfo() {
    this.api.getPasscardApplyInfoAndNavi(true);
  }
}
