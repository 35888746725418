<h3>クレジットカード情報</h3>
<section>
  <div class="tableCtn">
    <div class="tableLine">
      <div class="tableCell_l">クレジットカード番号</div>
      <div class="tableCell_r">
        <input (keyup)="onKeyup($event)" [(ngModel)]="cardNumbers[0]"
          [ngClass]="{'error_border_r':valid.hasError(msgCard1, messages)}" type="tel" class="w60 frm_pay" maxlength="4"
          placeholder="1234">
        <span>-</span>
        <input (keyup)="onKeyup($event)" [(ngModel)]="cardNumbers[1]"
          [ngClass]="{'error_border_r':valid.hasError(msgCard2, messages)}" type="tel" class="w60 frm_pay" maxlength="4"
          placeholder="5678">
        <span>-</span>
        <input (keyup)="onKeyup($event)" [(ngModel)]="cardNumbers[2]"
          [ngClass]="{'error_border_r':valid.hasError(msgCard3, messages)}" type="tel" class="w60 frm_pay" maxlength="4"
          placeholder="9012">
        <span>-</span>
        <input (keyup)="onKeyup($event)" [(ngModel)]="cardNumbers[3]"
          [ngClass]="{'error_border_r':valid.hasError(msgCard4, messages)}" type="tel" class="w60 frm_pay" maxlength="4"
          placeholder="3456">

        <p-error [messages]="valid.merge(msgCard, messages)"></p-error>
      </div>
    </div>

    <div class="tableLine">
      <div class="tableCell_l">有効期限</div>
      <div class="tableCell_r">
        <div class="cardNumber">
          <div class="selectBox">
            <select [(ngModel)]="cardExpires[0]" [ngClass]="{'error_border_r':msgMonth.length}">
              <option value="">--</option>
              <option *ngFor="let op of option_m" [value]="op.value">{{op.text}}</option>
            </select>
          </div>
          <span>月</span>
          <span>/</span>
          <div class="selectBox">
            <select [(ngModel)]="cardExpires[1]" [ngClass]="{'error_border_r':msgYear.length}">
              <option value="">--</option>
              <option *ngFor="let op of option_y" [value]="op.value">{{op.text}}</option>
            </select>
          </div>
          <span>年</span>
        </div>
        <p-error [messages]="msgExpired"></p-error>
      </div>
    </div>

    <div class="tableLine">
      <div class="tableCell_l">セキュリティコード
        <button (click)="onHelp()" class="icon_question" tabindex="-1" type="button"><img alt="" src="./assets/img/help-question.png"></button>
      </div>
      <div class="tableCell_r">
        <input [(ngModel)]="securityCode" [ngClass]="{'error_border_r':msgSecurity.length}" type="tel" maxlength="4"
          placeholder="123" class="frm_pay">
        <p-error [messages]="msgSecurity"></p-error>
      </div>
    </div>
  </div>
</section>

<section *ngIf="help" class="box-inner mt15">
  <div class="balloon">
    <h3>セキュリティコードとは</h3>
    <p>セキュリティコードは、クレジットカードの裏面または表面に記載された3桁もしくは4桁の数字です。</p>
    <div class="creditcard-box">
      <div class="cards"><em>【Visa・MasterCard・JCB】<br>カード裏面の署名欄に記載された末尾3桁の数字</em><img src="/assets/img/creditCard.png"></div>
      <div class="amex"><em>【American Express】<br>カード表面のクレジットカード番号の右上に記載された4桁の数字</em><img src="/assets/img/creditCardaAmerican.png"></div>
    </div>
  </div>
</section>