import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class SecurityNumberModel {
  securityNumber: string;
  bySecurityNumber : string;
  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = (securityNumber: string,bySecurityNumber: string) => {
    this.completed = false;
    this.securityNumber = securityNumber;
    this.bySecurityNumber = bySecurityNumber;

    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  setModel = () => {
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('securityNumber', this);
  }

  loadStorage = () => {
    this._storage.load('securityNumber', this);
  }
}
