import { Component, OnInit } from '@angular/core';

import { NavigateService } from '../../services';
import { AppModel } from '../../app.model';

@Component({
  selector: 'app-top-hero',
  templateUrl: './hero.component.html',
})
export class HeroComponent implements OnInit {

  constructor(
    private navi: NavigateService,
    public app: AppModel
  ) { }

  ngOnInit() { }

  //
  onLogin = () => {
    this.navi.navigateByUrl('/login');
  }

  onRegistration = () => {
    this.navi.navigateByUrl('/registration/mail');
  }

  onReserve = () => {
  }

}
