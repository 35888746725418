import { Injectable } from "@angular/core";
import { ApplyKind, Delivery, DeliveryInfo, Passcard, deliveryInputToDelivery, getDefaultDelivery } from './passcard.model';
import { UserInfo } from "./models/user-info";

/**
 * 定期券予約サービス
 */
@Injectable({
  providedIn: 'root'
})
export class PasscardBookingService {
  private _fixedTermPasscard: Passcard | null = null;
  private _validPeriodKind: number | null = null;
  private _delivery: Delivery | null = null; // 配送先情報
  private _ppKind = 0;

  error: string = '';
  complete = false;
  changeComplete = false;
  cancelComplete = false;

  applyCardDelivery = 1; // 0：配送無し、1：配送有り
  passcardApplyPossible = 0;
  myPage: UserInfo | null = null;
  passcards: Passcard[] | null = null;
  applyKind: number = ApplyKind.New;
  maxProcessed = -1;
  periods: number[] | null = null;
  receiptNo: string;
  passcardApplyNo: number;
  inputedDelivery: DeliveryInfo = getDefaultDelivery();

  reset() {
    this.complete = false;
    this.applyKind = ApplyKind.New;
    this.maxProcessed = -1;
    this.passcards = null;
    this._fixedTermPasscard = null;
    this._validPeriodKind = null;
    this._delivery = null;
    this.inputedDelivery = getDefaultDelivery();
    this.error = '';
    this.passcardApplyPossible = 0;
    this.applyCardDelivery = 1;
  }

  get PasscardInfo(): Passcard {
    return this._fixedTermPasscard!;
  }

  set PasscardInfo(passcardInfo: Passcard) {
    this._fixedTermPasscard = passcardInfo;
  }

  set ValidPeriodKind(val: number) {
    this._validPeriodKind = val;
  }

  get ValidPeriodKind() {
    return this._validPeriodKind!;
  }

  get DeliveryInputKind() {
    return this._ppKind;
  }

  set DeliveryInputKind(val: number) {
    this._ppKind = val;

  }

  setDelivery(deliveryInput: DeliveryInfo) {
    this._delivery = deliveryInputToDelivery(deliveryInput);
  }

  get Delivery() {
    return this._delivery!;
  }
}
