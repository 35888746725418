import { Component, OnInit } from '@angular/core';
import { NavigateService } from '../../services';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
})
export class CompleteComponent implements OnInit {

  constructor(
    private navi: NavigateService,
  ) { }

  ngOnInit() {
  }

  onTop = () => {
    this.navi.navigateByUrl('/');
  }
}
