import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule, JsonpClientBackend } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { components as homeComponents } from './home/home-routing.module';
import { components as sharedComponents, pipes } from './shared';
import { passcardComponents } from './passcard';
import { YubinbangoJsonpClientBackend } from './YubinbangoJsonpClientBackend';

@NgModule({
  declarations: [
    AppComponent,
    ...homeComponents,
    ...passcardComponents,
    ...sharedComponents,
    ...pipes,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  providers: [{ provide: JsonpClientBackend, useClass: YubinbangoJsonpClientBackend }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
