import { Component, OnInit } from '@angular/core';
import { ApiService, NavigateService ,StorageService } from '../../services';
import { Time9 } from '../../shared/time9';
import { hasNP } from '../../shared/utils';
import { RegistrationModel } from '../registration.model';
import { AppModel } from '../../app.model';

//
interface Req {
  parkingCode: number
  mailSendToken: string;
  mailSendDate: string;
  fullNameList: string[];
  furiganaList: string[];
  birthList: string[];
  telephoneNumber: string;
  bicycleRegistrationNo: string;
  zipCodeList: string[];
  address1: string;
  address2: string;
  companyName: string;
  companyNameFurigana: string;
  companyTel: string;
  companyZipCodeList: string[];
  companyAddress1: string;
  companyAddress2: string;
  numberPlateList: string[];
  motorcycleNumberPlate: string;
  password: string;
  agreementStatus: number;
}

interface Res {
  resultCode: number;
  mainMessage: string;
}

//
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {
  error: string;

  constructor(
    public model: RegistrationModel,
    public api: ApiService,
    public navi: NavigateService,
    private _app: AppModel,
    private _storage: StorageService
  ) { }

  ngOnInit() {
    this.model.loadStorage();
  }

  //
  hasNP = () => hasNP(this.model.numberPlate);

  //
  private afterPost = (body: Res) => {
    this.api.enabled();

    if (body.resultCode === 0) {
      this.model.apply();
      this._app.login = true;
      this._app.saveStorage();
      this.navi.navigateByUrl('/registration/complete');
    } else {
      //this.error = body.mainMessage;
      this.navi.navigateByUrl('/error', true);
    }
  }

  onApply = () => {
    this.api.disabled = true;
    const m = this.model;
    const req: Req = {
      // 駐車場コード
      parkingCode: m.parkingCode,
      // メール情報
      mailSendToken: m.mail.token,
      mailSendDate: Time9.toString(+ m.mail.date, '_'),
      // お客様情報
      fullNameList: m.fullname,
      furiganaList: m.kana,
      birthList: m.birthdate,
      telephoneNumber: m.phone,
      bicycleRegistrationNo: m.securityNumber,
      // 住所
      zipCodeList: m.postcode,
      address1: m.address1,
      address2: m.address2,
      // 勤務先/通学先
      companyName: m.wsName,
      companyNameFurigana: m.wsKana,
      companyTel: m.wsPhone,
      companyZipCodeList: m.wsPostcode,
      companyAddress1: m.wsAddress1,
      companyAddress2: m.wsAddress2,
      // 車両情報（クルマ）
      numberPlateList: m.numberPlate,
      // 車両情報（バイク）
      motorcycleNumberPlate:m.motorcycleNumberPlate,
      // パスワード
      password: m.passwd,

      // 利用規約同意の不具合対応
      agreementStatus: Number(this._storage._load('agreementStatus'))
    };
    this.api.post<Res>('/registration', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}