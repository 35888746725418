import { Injectable } from '@angular/core';
import { StorageService } from '../../services';



@Injectable({
  providedIn: 'root'
})
export class NameBirthdateModel {
  /*numberPlate: string[];*/
  fullname: string[];
  kana: string[];
  birthdate: string[];
  byUserName : string;
  byUserFurigana : string;
  byUserBirth : string;
  completed: boolean;

  constructor(
    private _storage: StorageService,
  ) {
    this.loadStorage();
  }

  //
  reset = (name: string[],kana: string[],byUserName: string,byUserFurigana: string,byUserBirth: string) => {
    this.completed = false;
    this.fullname = name;
    this.kana = kana;
    this.birthdate = [null, null, null];
    this.byUserName = byUserName;
    this.byUserFurigana = byUserFurigana;
    this.byUserBirth = byUserBirth;
    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  setModel = (fullname: string[]) => {
    /*this.numberPlate = numberPlate;
    this.fullname = fullname;
    this.saveStorage();*/
  }

  //
  saveStorage = () => {
    this._storage.save('nameBirthdate', this);
  }

  loadStorage = () => {
    this._storage.load('nameBirthdate', this);
  }
}
