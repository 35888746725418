import { Routes } from '@angular/router';

import { CompleteComponent } from './complete/complete.component';
import { ConfigComponent } from './config/config.component';
import { ConfirmComponent } from './confirm/confirm.component';

//
export const routes: Routes = [{
  path: 'complete',
  component: CompleteComponent
}, {
  path: 'config',
  component: ConfigComponent
}, {
  path: 'confirm',
  component: ConfirmComponent
}];

//
export const components = [
  CompleteComponent,
  ConfigComponent,
  ConfirmComponent
];
