import { Component } from '@angular/core';
import { LostService } from '../../lost.service';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'lost04',
  templateUrl: './lost04.component.html'
})
export class Lost04Component {
  message2 = '定期券の到着まで今しばらくお待ちください。';
  constructor(public api: PasscardApiProxy,
    private _service: LostService) {
    this._service.complete = true;
    if (this._service.applyCardDelivery == 0) {
      this.message2 = '申請が完了するまでお待ちください。';
    }
  }

  get Steps() {
    return this._service.applyCardDelivery == 0 ? ['定期確認', '確認', '完了']
      : ['定期確認', '配送情報', '確認', '完了'];
  }

  get CurrentProc() {
    return this._service.applyCardDelivery == 0 ? 2 : 3;
  }

  get receiptNo() {
    return this._service.receiptNo;
  }

  /**
 * 定期情報ページに戻る
 */
  onBackToContract() {
    this.api.getPasscardApplyInfoAndNavi(true);
  }
}
