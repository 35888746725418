import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
const dispMax = 5;
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() onPage: EventEmitter<number> = new EventEmitter();

  @Input() pages: number;
  @Input() page: number;
  @Input() popup: boolean;

  //
  range: number[];
  n: number;

  modal = false;

  constructor() { }

  ngOnInit() {
    console.log('PaginationComponent ngOnInit');
  }

  ngOnChanges() {
    const p = this.pages;
    const len = p < dispMax ? p : dispMax;
    this.range = new Array(len);

    let n = this.page < 3 ? 1 : this.page - 2;
    if (p < n + len)
      n = p - len + 1;

    for (let i = 0; i < len; i++)
      this.range[i] = n++;
  }

  //
  onClick = (n: number) => {
    this.n = n;
    if(this.popup){
      this.pageChange();
    }else{
      this.modal = true;
    }
  }

  onCancel = () => {
    this.modal = false;
  }

  onOk = () => {
    this.modal = false;
    this.pageChange();
  }

  pageChange(){
    if (this.page !== this.n && 0 < this.n && this.n <= this.pages)
      this.onPage.emit(this.n);
  }
}
