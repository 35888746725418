<div class="confirm-info confirm-info-background">
  <div><i class="confirm-img triangle-warning-img"></i></div>
  <div>
    <p>以下定期のICカードの紛失・破損処理を行います。紛失・破損したICカードはご利用できなくなります。</p>
    <p>紛失・破損処理を行う定期を確認し、「次へ」を押してください。</p>
  </div>
</div>

<h2>定期紛失</h2>
<flow-box [steps]="Steps" [processing]="0" [processed]="0" />

<div class="card-container">
  <card *ngIf="contract" [reservation]="contract"></card>
</div>
<br>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button
    (apply)="onNext()"
    [disabled]="NextDisabled"
    [loading]="api.Disabled"
    [label]="'次へ'"
    [class]="'cmn-btn six'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>