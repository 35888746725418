<div id="dispSupArea">
<h2>サポート</h2>
<div class="blkCtn">
  <button id="dispNoticeInfo" class="box" (click)="onInfo()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/notice.svg" alt=""><span class="blkTitle">お知らせ</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispHelp" class="box" (click)="onHelp()">
    <div>
      <img class="login" src="./assets/img/help.svg" alt=""><span class="blkTitle">ヘルプ</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>

</div>
</div>
<div id="dispRegistInfoArea">
<h2>マイページ</h2>
<div class="blkCtn" >
  <button id="dispNameBirthdate" class="box" (click)="onNameBirthdate()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/human.svg" alt="">
      <span class="blkTitle">
        <span *ngIf="mypage.byUserName!=='0' || mypage.byUserFurigana!=='0'">氏名</span>
        <span *ngIf="!(mypage.byUserName=='0' && mypage.byUserFurigana=='0' || mypage.byUserBirth=='0')" >・</span>
        <span *ngIf="mypage.byUserBirth!=='0'">生年月日</span>
      </span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispAddress" class="box" (click)="onAddress()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/address.svg" alt=""><span class="blkTitle">住所</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispPhone" class="box" (click)="onPhone()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/phone.svg" alt=""><span class="blkTitle">電話番号</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispSecurityNumber" class="box" (click)="onSecurityNumber()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/securitynumber.svg" alt=""><span class="blkTitle">防犯登録番号</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispCarInfo" class="box" (click)="onCarInfo()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/car.svg" alt="">
      <span class="blkTitle">車両情報(クルマ)</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispMotorcycleInfo" class="box" (click)="onMotorcycleInfo()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/motorcycle.svg" alt="">
      <span class="blkTitle">車両情報(バイク)</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispWsInfo" class="box" (click)="onWsInfo()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/wsInfo.svg" alt=""><span class="blkTitle">通学/勤務先</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
</div>
</div>

<h2>アカウント情報</h2>

<div class="blkCtn">
  <button id="dispMailAddr" class="box" (click)="onMailAddr()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/mail.svg" alt=""><span class="blkTitle">メールアドレス</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispPasswd" class="box" (click)="onPasswd()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/password.svg" alt=""><span class="blkTitle">パスワード変更</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispMailNotify" class="box" (click)="onMailNotify()" [disabled]="api.disabled">
    <div>
      <img class="mailsetting" src="./assets/img/mailsetting.svg" alt=""><span class="blkTitle">メール通知設定</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button id="dispLogout" class="box" (click)="onLogout()">
    <div>
      <img class="login" src="./assets/img/logout.svg" alt=""><span class="blkTitle">ログアウト</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
</div>

<h2>会員退会</h2>
<div class="blkCtn">
  <button id="dispWithd" class="box" (click)="onUnsubscribe()" [disabled]="api.disabled">
    <div>
      <img class="login" src="./assets/img/unsubscribe.svg" alt=""><span class="blkTitle withdrawal">退会</span>
    </div>
    <img class="arrow" src="./assets/img/menu-arrow.svg" alt="">
  </button>
  <button class="box blkCell_r blank_r"> 
    <div class="blankArea">
      <img class="login" src="./assets/img/login.svg" alt=""><span class="blkTitle">ブランク</span>        
    </div>
  </button>
</div>