<app-header [showLink]="false"></app-header>

<div *ngIf="loading" class="error-textbox">
  <p class="err-txt-box">問合せ中...</p>
</div>
<ng-container *ngIf="!loading">

  <h2>{{info.title}}</h2>
  <ng-container *ngFor="let message of info.messageList">
    {{message}}<br>
  </ng-container>
  <br>
  <br>
  <div class="tableCtn" *ngIf="info.linkTitle && info.urlLink">
    <div class="tableLine">
      <div class="tableCell_l">リンク</div>
      <div class="tableCell_r">
        <div class="tdCtn"><a href="{{info.urlLink}}" target="_blank">{{info.linkTitle}}</a></div>
      </div>
    </div>
  </div>

  <div class="buttonBox">
    <button (click)="onClose()" class="cmn-btn bck-btn">閉じる</button>
  </div>
</ng-container>