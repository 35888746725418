<div class="pagination">
  <div class="pagination-btn">
    <div *ngIf="1 < pages && page != 1" (click)="onClick(page-1)" class="prev">
      <i class="icon v_right"></i>
    </div>

    <div *ngFor="let i of range" (click)="onClick(i)"
      [class.page-number]="i!==page"
      [class.current] = "i===page">{{i}}</div>

    <div *ngIf="1 < pages && page != pages" (click)="onClick(page+1)" class="next">
      <i class="icon v_right"></i>
    </div>
  </div>
</div>