import { Component, Input } from '@angular/core';
import { ValidateService } from '../../services';

@Component({
  selector: 'i-ws-name',
  templateUrl: './i-ws-name.component.html',
})
export class IWsNameComponent {
  @Input() model: { byCompanyName: string, wsName: string };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() label: string;

  message: string[];

  //
  constructor(
    public valid: ValidateService
  ) { }

  ngOnInit() {
  }

  //
  validation = () => {
    const msg = this.valid;
    this.message = [];
    if (this.model.byCompanyName === '2') {
      // required ?
      const fn = msg.validRequired;
      this.message = fn(this.model.wsName, this.label);
    }
    msg.pushWorkspaceName(this.message, this.model.wsName, this.label);
    msg.pushProhibit(this.message, this.model.wsName, this.label);
    return this.message && !this.message.length;
  }

  onChange = () => this.messages = null;

  }