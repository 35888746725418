import { Component, Input } from '@angular/core';
import { KeyService, ValidateService } from '../../services';

//
@Component({
  selector: 'i-passwd',
  templateUrl: './i-passwd.component.html',
})
export class IPasswdComponent {
  @Input() model: { passwd: string };
  @Input() label: string;
  @Input() placeholder: string;
  @Input() spanRequire: boolean;
  @Input() messages: string[];

  //
  message: string[];

  //
  constructor(
    public key: KeyService,
    public valid: ValidateService
  ) { }

  //
  validation = () => {
    const msg = this.valid;
    const passwd = this.model.passwd;

    const fn = msg.validRequired;
    this.message = fn(passwd, this.label);

    msg.pushPasswd(this.message, passwd, this.label);

    return !this.message.length;
  }

}
