<div class="collapsible">
  <div (click)="onClick()">
    <div>駐車場情報</div>
    <div [ngClass]="{ expanded: !isCollapsed }">ⓥ</div>
  </div>
  <div class="accordion-content" *ngIf="!isCollapsed">
    <div class="tableCtn">
      <p-single label="駐車場" [value]="parkingPlace.name" />
      <p-single label="住所" [value]="parkingPlace.address" />
    </div>
  </div>
</div>