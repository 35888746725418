<div class="tableCtn">
  <div class="tableLine">
    <div class="tableCell_l">{{label}}</div>
    <div class="tableCell_r">
      <input [(ngModel)]="model.mailAddr" (keydown)="key.blockCopyPaste($event)" (contextmenu)="key.blockMenu()"
        [ngClass]="{'error_border_r':valid.hasError(message, messages)}" type="email" class="w100" maxlength="256"
        placeholder="passcard_@pkweb.jp">
      <p-error [messages]="message"></p-error>
    </div>
  </div>
</div>
<p class="formText">※メールアドレス確認のメールを送信します<br>「parkingweb@amano.co.jp」からの<br class="br-sp">メールを受信できるように設定してください</p>