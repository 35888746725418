import { Component, Input } from '@angular/core';
import { KeyService, ValidateService } from '../../../services';

@Component({
  selector: 'i-username',
  templateUrl: './i-username.component.html',
})
export class IUsernameComponent {
  @Input() model: any;
  @Input() messages: string[];
  @Input() label: string;
  @Input() placeHolder: string
  @Input() field: string;
  @Input() maxLength: number;

  message: string[];

  constructor(
    public key: KeyService,
    public valid: ValidateService
  ) { }

  validation = () => {
    const msg = this.valid;

    this.message = msg.validRequired(this.model[this.field], this.label);
    if (this.message.length === 0) {
      const len = msg.countStrLength(this.model[this.field]);
      if (32 < len) {
        this.message.push(msg.get(msg.string.nameLength, this.label, '16','32'));
      }
    }

    msg.pushProhibit(this.message, this.model[this.field], this.label);

    return this.message && !this.message.length;
  }

  onChange = () => this.messages = null;
}