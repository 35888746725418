import { Component, OnInit } from '@angular/core';
import { NavigateService } from '../services';

@Component({
  selector: 'app-complete',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  constructor(
    private navi: NavigateService
  ) { }

  ngOnInit() { }

  onClick = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/login');
  }

}
