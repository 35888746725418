import { Component, Input } from '@angular/core';
import { KeyService, ValidateService } from '../../services';

//
@Component({
  selector: 'i-email',
  templateUrl: './i-email.component.html',
})
export class IEMailComponent {
  @Input() model: { email: string };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() label: string;

  message: string[];

  constructor(
    public key: KeyService,
    public valid: ValidateService
  ) { }

  //
  validation = () => {
    const msg = this.valid;

    const fn = msg.validRequired;
    this.message = fn(this.model.email, this.label);
    msg.pushEmail(this.message, this.model.email, this.label);

    return this.message && !this.message.length;
  }

  onChange = () => this.messages = null;
}
