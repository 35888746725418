import { Component, Input } from '@angular/core';
import { KeyService, ValidateService } from '../../services';

//
@Component({
  selector: 'i-email2',
  templateUrl: './i-email2.component.html',
})
export class IEMail2Component {
  @Input() model: { mailAddr: string };

  label = 'メールアドレス';

  email: string;

  //
  message: string[];
  messages: string[];

  //
  constructor(
    public key: KeyService,
    public valid: ValidateService
  ) { }

  //
  validation = () => {
    const email = this.model.mailAddr;
    const msg = this.valid;
    const fn = msg.validRequired;
    this.message = fn(email, this.label);
    msg.pushEmail(this.message, email, this.label);
    return !this.message.length;
  }

}
