<ng-container *ngIf="reservation">
    <h2>定期情報</h2>
    <status-tip (click)="onStatusTipClick()" [status]="Status" [endDate]="reservation.reApplyPeriod"
        [order]="reservation.currentNum"></status-tip>
    <apply-info [viewOnly]="viewOnly" [reservation]="reservation">
        <div class="buttons" *ngIf="!viewOnly && Status > BS.Waiting">
            <div class="large-button apply-button" *ngIf="Status == BS.Recepting" (click)="onReApply()">
                <label title="定期申請">
                    <div><img title="" alt="" src="/assets/img/note-pen.svg"></div>
                    <div>定期申請</div>
                </label>
            </div>
            <div class="large-button apply-button" *ngIf="Status == BS.Reject" (click)="onModifiedConfirm()">
                <label title="修正確認">
                    <div><img title="" alt="" src="/assets/img/note-pen.svg"></div>
                    <div>修正確認</div>
                </label>
            </div>
            <div class="large-button" (click)="onCancelConfirm()">
                <label title="申請取消">
                    <div><img title="" alt="" src="/assets/img/cancel.svg"></div>
                    <div>申請取消</div>
                </label>
            </div>
        </div>
        <br>
    </apply-info>
    <div class="button-wrapper" *ngIf="ShowDeliveryEditBtn">
        <button type="button" class="cmn-btn bck-btn" (click)="onEditDelivery()">配送先情報編集</button>
    </div>
</ng-container>