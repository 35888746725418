<!DOCTYPE html>
<h2>新規会員登録</h2>
<!--
<reg-stepbar [step]="5"></reg-stepbar>
-->
<flow-box-registration [steps]="['アドレス入力', 'お客様情報入力', '確認', '完了']" [processing]="3" [processed]="3" />

<p class="complete-contents">
  <img class="complete-icon" src="./assets/img/success.svg" alt="">
</p>
<p class="complete-contents">
  <span class="text-gray">会員登録完了しました</span>
</p>

<div class="buttonBox">
  <span *ngIf="loginURLFlg; else notLoginURL">
    <!--<button (click)="onBack()" class="cmn-btn bck-btn button-box-color-w-50">定期申し込みに戻る</button>-->
    <span *ngIf="parkingCodeZero; else notParkingCodeZero">
      <button (click)="onBack()" class="cmn-btn bck-btn button-box-color-w-50">トップに戻る</button>
    </span>
    <ng-template #notParkingCodeZero>
      <button (click)="onBack()" class="cmn-btn bck-btn button-box-color-w-50">定期申し込みに戻る</button>
    </ng-template>
  </span>
  <ng-template #notLoginURL>
    <button (click)="onBack()" class="cmn-btn bck-btn button-box-color-w-50">定期申し込みに戻る</button>
  </ng-template>
</div>
