import { Component, OnInit, Input } from '@angular/core';
import { KeyService, ValidateService } from '../../services';

//
@Component({
  selector: 'i-passwd2',
  templateUrl: './i-passwd2.component.html',
})
export class IPasswd2Component implements OnInit {
  @Input() model: { passwd: string };
  @Input() label: string;
  @Input() placeholder1: string;
  @Input() placeholder2: string;
  @Input() spanRequire: boolean;
  @Input() messages: string[];

  label1: string;
  label2: string;

  passwd: string;

  //
  message1: string[];
  message2: string[];

  //
  constructor(
    public key: KeyService,
    public valid: ValidateService
  ) { }

  ngOnInit() {
    this.label1 = this.label;
    this.label2 = this.label + '（再入力）';
  }

  //
  validation = () => {
    const msg = this.valid;
    const passwd = this.model.passwd;

    const fn = msg.validRequired;
    this.message1 = fn(passwd, this.label1);
    this.message2 = fn(this.passwd, this.label1);

    msg.pushPasswd(this.message1, passwd, this.label1);
    if (!this.message2.length && passwd !== this.passwd)
      this.message2.push(msg.get(msg.string.notEqual2, this.label1));

    return !this.message1.length && !this.message2.length;
  }

  onChange = () => this.messages = null;
}
