import { Component, Input } from '@angular/core';
import { KeyService, ValidateService } from '../../services';

//
@Component({
  selector: 'i-kanakigo',
  templateUrl: './i-kanakigo.component.html',
})
export class IKanakigoComponent {
  @Input() model: { byKanaKigo:string, kanakigo: string };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() label: string;

  message: string[];

  constructor(
    public key: KeyService,
    public valid: ValidateService
  ) { }

  //
  validation = () => {
    const msg = this.valid;
    this.message = [];
    if (this.model.byKanaKigo === '2') {
      const fn = msg.validRequired;
      this.message = fn(this.model.kanakigo, this.label);
    }
    msg.pushKanakigo(this.message, this.model.kanakigo, this.label);
    return this.message && !this.message.length;
  }

  onChange = () => this.messages = null;

}