<div *ngIf="loading" class="error-textbox">
  <p class="err-txt-box">問合せ中...</p>
</div>

<ng-container *ngIf="!loading">
  <h2>パスワード変更</h2>

  <t-error [message]="error"></t-error>
  <div class="infoText">
    <div class="infoLine">
      <div>
        <img class="info-icon" src="./assets/img/info.svg" alt="">
      </div>
      <div class="meg">
        パスワード再発行を行います。<br>
        新しいパスワードを入力し、<br class="br-sp">「登録」を押してください。<br>
      </div>
    </div>
  </div>

  <section>
    <div class="tableCtn">
      <i-passwd2
        [model]="model"
        (keyup)="onKeyup()"
        [messages]="errors"
        [placeholder1]="'パスワードを入力'"
        [placeholder2]="'パスワードを入力'"
        [label]="'新しいパスワード'">
      </i-passwd2>
    </div>
    <br>
    ※パスワードは最低８文字で半角英数字<br class="br-sp">（大文字、小文字）、記号が使用できます
  </section>

  <div *ngIf="!model.completed" class="buttonBox">
    <loading-button
      (apply)="onApply()"
      [disabled]="disabled"
      [loading]="api.disabled"
      [label]="'登録'"
      [class]="'cmn-btn'"
      [lClass]="'button-box-loading'">
    </loading-button>
  </div>
</ng-container>