import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'loading-button',
  templateUrl: './loading-button.component.html',
})
export class LoadingButtonComponent {
  @Input() label: string;

  @Input() class: string;
  @Input() lClass: string;

  @Input() disabled: boolean;
  @Input() loading: boolean;

  //
  @Output() apply: EventEmitter<null> = new EventEmitter();

  //
  constructor() { }

  onClick = () => {
    if (!this.disabled && !this.loading)
      this.apply.emit();
  }

  getClass = () => {
    let o = [this.class];

    if (this.loading)
      o.push(this.lClass);

    return o.join(' ');
  }

}
