import { Component, Input } from '@angular/core';

@Component({
  selector: 't-info',
  templateUrl: './t-info.component.html',
})
export class TInfoComponent {
  @Input() value1: string;
  @Input() value2: string;

  constructor() { }
}