import { Component, Input } from '@angular/core';

@Component({
  selector: 'p-birthdate',
  templateUrl: './p-birthdate.component.html',
})
export class PBirthdateComponent {
  @Input() label: string;
  @Input() value: string[];

  constructor() { }
}
