<h2>車両情報(バイク)</h2>
<t-error [message]="error"></t-error>

<section>
  <div class="tableCtn">
    <i-motorcyclenumber
      [model]="model"
      [label]="'ナンバープレート'"
      (change)="onChange()"
      (keyup)="onKeyup()"
      [messages]="messages.motorcycleNumber"
      [spanRequire]="true">
    </i-motorcyclenumber>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="disabled"
    [loading]="api.disabled"
    [label]="'確認'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>