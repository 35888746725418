<h2>お知らせ</h2>

<div>
  <div *ngIf="noticeInfoList.length > 0" class="infoWrap">
    <ul class="infoList">
      <li *ngFor="let info of noticeInfoList;let i=index" class="infoContent">
        <div class="flex">
          <div class="date">{{info.siteNoticeStart|number2date}}</div>
        </div>
        <div class="text">
          <a (click)="onDetail(i)">{{info.title}}</a>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="noticeInfoList.length <= 0">
    <p class="no_notice"> お知らせはありません</p>
  </div>

  <app-pagination
    *ngIf="1 < pageModel.pages"
    [pages]="pageModel.pages"
    [page]="pageModel.page"
    [popup]="!api.Disabled"
    (onPage)="onPage($event)"
  ></app-pagination>

  <div class="buttonBox">
    <button (click)="navi.goBack()" class="cmn-btn bck-btn">戻る</button>
  </div>
</div>