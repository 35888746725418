import { Component, Input } from '@angular/core';
import { ParkingPlace } from '../../models/parking-place';

@Component({
  selector: 'place-info',
  templateUrl: './place-info.component.html'
})
export class PlaceInfoComponent {
  @Input() parkingPlace!: ParkingPlace;
  isCollapsed = true;

  onClick() {
    this.isCollapsed = !this.isCollapsed;
  }
}
