import { Component, OnInit } from '@angular/core';
import { ApiService, NavigateService } from '../../services';

interface Infomation {
  id: string;
  time: number;
  title: string;
  unread: boolean;
}

@Component({
  selector: 'app-message-list',
  templateUrl: './list.component.html',
})
export class ListComponent implements OnInit {
  list: Infomation[];

  constructor(
    private api: ApiService,
    public navi: NavigateService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  //
  private getList = () => {
    this.api.disabled = true;

    const req = {}

    this.api.post<{ list: Infomation[] }>('/message/list', req, 1).subscribe(
      res => {
        this.api.enabled();
        this.list = res.body.list;
      }
    );
  }

  //
  onDetail = (e: Event, id: string) => {
    e.preventDefault();
    this.navi.navigateByUrl('/message/detail/' + id);
  }

}
