import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-guide',
  templateUrl: './guide.component.html',
})
export class GuideComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
