import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';
import { SecurityNumberModel } from '../security-number.model';
import { MypageModel } from '../../mypage.model';

import { ISecurityNumberComponent } from '../../../shared/i-securitynumber/i-securitynumber.component';


//
interface Req {
  bicycleRegistrationNo: string;
}

interface Res {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
  securityNumberValidator: {
    validatorMessageSecurityNumber: string[];
  }
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(ISecurityNumberComponent, { static: false }) securityNunber: ISecurityNumberComponent;


  // for Server
  error: string;
  errors: string[];
  messages: { securityNumber: string[]};


  //
  disabled: boolean;

  //
  constructor(
    public model: SecurityNumberModel,
    public mypage: MypageModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
  ) { }

  ngOnInit() {
    this.disabled = true;
    this.messages = {
      securityNumber:null
    };
  }

  ngDoCheck() {
    const b1 = this.securityNunber ? this.securityNunber.validation() : false;
    var b2 = true;
    if (this.model.bySecurityNumber === '0') {
      b2 = false;
    }
    this.disabled = !(b1 && b2);
  }

  onKeyup = () => {
    this.error = null;
    this.errors = null;
  }

  //
  private afterPost = (body: Res) => {
    if (body.resultCode === 0) {
      this.model.saveStorage();
      this.navi.navigateByUrl('/mypage/security-number/confirm');
    } else {
      this.api.enabled();

      this.error = body.mainMessage;
      this.messages.securityNumber = body.validatorMessageList;
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const req: Req = {
      bicycleRegistrationNo: this.model.securityNumber
    };

    this.api.post<Res>('/bicycleRegistrationNo/check', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
