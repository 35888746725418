<div class="confirm-info" [ngClass]="infoClass">
  <div><i class="confirm-img" [ngClass]="infoImg"></i></div>
  <div>
    <p>{{infoText[0]}}</p>
    <p>{{infoText[1]}}</p>
  </div>
</div>

<h2>{{app.ParkingPlace.name}}</h2>
<flow-box *ngIf="showFlowboxes" [steps]="Steps" [processing]="CurrentProc" [processed]="CurrentProc" />
<h3>定期情報</h3>
<passcard-info *ngIf="!showPasscardStatus; else showStatus" [model]="passcard"
  [showStatus]="false"></passcard-info>
<ng-template #showStatus>
  <div class="passcard-select passcard-normal">
    <div>&nbsp;</div>
    <div class="status">
      <div class="parking-status" *ngIf="showStatus"
        [ngClass]="{'status-busy': passcard.status == PasscardStatus.WaitingVacancies, 'status-idle': passcard.status == PasscardStatus.Available }">{{StatusText}}</div>
    </div>
    <div class="select-label"><label>{{passcard.name}}</label></div>  
    <div class="cost font-small"> 1ヵ月あたり{{passcard.unitPrice | currency:'JPY'}}</div>
  </div>
</ng-template>
<ng-container *ngIf="passcard.status == PasscardStatus.Available">
  <br>
  <div class="tableCtn">
    <p-single label="定期期間" [value]="fixedPeriod" />
  </div>
</ng-container>
<div class="button-wrapper">
  <button type="button" class="cmn-btn bck-btn" (click)="onReInput(1)">定期情報入力に戻る</button>
</div>
<br>
<place-info [parkingPlace]="app.ParkingPlace"></place-info>
<br>
<ng-container *ngIf="ShowDelivery">
  <h3>配送先情報</h3>
  <div class="comment delivery-tip"><span>※定期券の配送先となるため、</span><span>お間違えの無いようにご入力ください</span></div>
  <br>
  <delivery [delivery]="deliveryInfo"></delivery>
  <div class="button-wrapper">
    <button type="button" class="cmn-btn bck-btn" (click)="onReInput(2)">配送先情報入力に戻る</button>
  </div>
  <br>
</ng-container>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button (apply)="onApply()" [disabled]="api.Disabled" [loading]="api.Disabled" [label]="'申請'"
    [class]="'cmn-btn dcd-btn six'" [lClass]="'button-box-loading'" />
</div>