import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'p-correction',
  templateUrl: './p-correction.component.html',
})
export class PCorrectionComponent implements OnInit {
  @Input() label: string;
  @Input() value1: string;
  @Input() value2: string;

  correction: boolean;

  constructor() { }

  ngOnInit() {
    this.correction = this.value1 !== this.value2;
  }
}
