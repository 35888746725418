import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class PasswdModel {
  passwd: string;

  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = () => {
    this.completed = false;
    this.passwd = null;
    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('passwd', this);
  }

  loadStorage = () => {
    this._storage.load('passwd', this);
  }
}
