import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class MailAddrModel {
  email: string;
  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = (mailAddr: string) => {
    this.completed = false;
    this.email = mailAddr;
    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('mailaddr', this);
  }

  loadStorage = () => {
    this._storage.load('mailaddr', this);
  }
}
