import { Component, OnInit } from '@angular/core';
import { NavigateService } from '../../services';

@Component({
  selector: 'app-rejected',
  templateUrl: './rejected.component.html',
})
export class RejectedComponent implements OnInit {

  constructor(
    private navi: NavigateService
  ) { }

  ngOnInit() { }

  onApply = () => {
    this.navi.navigateByUrl('/mypage/top');
  }
}
