import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class WsInfoModel {
  wsName: string;
  wsKana: string;
  wsPostcode: string[];
  wsAddress1: string;
  wsAddress2: string;
  wsPhone: string;
  byCompanyName : string;
  byCompanyFurigana : string;
  byCompanyTel : string;
  byCompanyZip : string;
  byCompanyAddress1 : string;
  byCompanyAddress2 : string;
  completed: boolean;

  // 表示非表示フラグ
  dispWsName: boolean;
  dispWsKana: boolean;
  dispWsPhone: boolean; 
  dispWsAddress: boolean; 
  

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = (wsName: string,wsKana: string,wsPhone: string,wsPostcode: string[],wsAddress1: string,wsAddress2: string,
    byCompanyName: string,byCompanyFurigana: string,byCompanyTel: string,byCompanyZip: string,byCompanyAddress1: string, byCompanyAddress2: string) => {
    this.completed = false;
    this.wsName = wsName;
    this.wsKana = wsKana;
    this.wsPhone = wsPhone;
    this.wsPostcode = wsPostcode;
    this.wsAddress1 = wsAddress1;
    this.wsAddress2 = wsAddress2;
    this.byCompanyName = byCompanyName;
    this.byCompanyFurigana = byCompanyFurigana;
    this.byCompanyTel = byCompanyTel;
    this.byCompanyZip = byCompanyZip;
    this.byCompanyAddress1 = byCompanyAddress1;
    this.byCompanyAddress2 = byCompanyAddress2;
  
    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  setModel = () => {
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('ws-info', this);
  }

  loadStorage = () => {
    this._storage.load('ws-info', this);
  }
}
