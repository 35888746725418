import { Component, inject } from '@angular/core';
import { ApplyKind, PeriodKind, PasscardStatus, formatPeriod, Passcard, PasscardApplyApi } from '../../../passcard.model';
import { PasscardBookingService } from '../../../passcard-booking.service';
import { AppModel } from 'src/app/app.model';
import { ApiService, NavigateService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { ActivatedRoute, ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';

declare var MONTH_SELECT_ABLE_DAY;

@Component({
  selector: 'apply01',
  templateUrl: './apply01.component.html'
})
export class Apply01Component {
  private readonly _route: ActivatedRoute = inject(ActivatedRoute);
  PERIODKIND = PeriodKind;
  passcardSelected!: Passcard;
  passcards!: Passcard[];
  fixedPeriod: string = '';
  periodKind = 0;
  targetMonth = 2;

  isReApply = false;
  showPeriod = false;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _bookingService: PasscardBookingService
  ) {
    this.isReApply = _bookingService.applyKind == ApplyKind.ReApply;
    this._route.data.pipe(map(({ data }) => data)).subscribe(x => {
      const { systemDate } = x;
      const day = new Date(systemDate).getDate();
      if (day > MONTH_SELECT_ABLE_DAY) {
        this.targetMonth += 1;
      }

    });
    this.periodKind = _bookingService.ValidPeriodKind;
    this.getPasscardList();
  }

  get Steps() {
    return this._bookingService.applyCardDelivery == 0 ? ['定期選択', '確認', '完了']
      : ['定期選択', '配送情報', '確認', '完了'];
  }

  get IsComplete() {
    return this._bookingService.complete;
  }

  get NextDisabled() {
    return this.api.Disabled
      || !this.passcardSelected
      || this._bookingService.passcardApplyPossible != 1
      || (this.passcardSelected.status == PasscardStatus.Free && !this.periodKind);
  }

  private async getPasscardList() {
    if (this.periodKind >= PeriodKind.NextMonth) {
      this.fixedPeriod = formatPeriod([this._bookingService.periods[this.periodKind - 1], null]);
    }

    if (this.isReApply) {
      this.showPeriod = true;
      this.passcardSelected = { ...this._bookingService.PasscardInfo };
      this.passcardSelected.status = PasscardStatus.Free;
    }
    else {
      this.passcards = this._bookingService.passcards;
      if (this._bookingService.PasscardInfo) {
        this.passcardSelected = this.passcards.find(x => x.passcardType == this._bookingService.PasscardInfo.passcardType);
        this.showPeriod = this.passcardSelected.status == PasscardStatus.Free;
      }
    }
  }

  getStatusText(status: PasscardStatus) {
    switch (status) {
      case PasscardStatus.Busy:
        return '△空き待ち';
      case PasscardStatus.Free:
        return '〇申込可'
    }
  }

  getStatusCls(status: PasscardStatus) {
    switch (status) {
      case PasscardStatus.Busy:
        return 'status-busy'
      case PasscardStatus.Free:
        return 'status-idle';
    }
  }

  getStausTextExtra(ticket: Passcard) {
    switch (ticket.status) {
      case PasscardStatus.Busy:
        return `現在${ticket.waitNum}人待ちです`
      default:
        return '';
    }
  }

  getPasscardClass(ticket: Passcard) {
    if (!this.passcardSelected) {
      this.passcardSelected = ticket;
      this._bookingService.PasscardInfo = ticket;
    }

    return ticket.passcardType === this.passcardSelected.passcardType ? 'passcard-selected' : 'passcard-normal';
  }

  onPeriodChange(periodKind: number) {
    this.periodKind = periodKind;
    this.fixedPeriod = '';
    if (this.periodKind == this.targetMonth) {
      this.fixedPeriod = formatPeriod([this._bookingService.periods[periodKind - 1], null]);
    }
  }

  onPasscardChange(ticket: Passcard) {
    if (!ticket || ticket == this.passcardSelected) return;

    this.fixedPeriod = '';
    this.periodKind = 0;
    this.passcardSelected = ticket;
    this.showPeriod = this.passcardSelected.status == PasscardStatus.Free;
  }

  onNext() {
    this._bookingService.PasscardInfo = this.passcardSelected;
    this._bookingService.ValidPeriodKind = this.periodKind;
    if (this._bookingService.applyCardDelivery != 0
      && this.passcardSelected.status == PasscardStatus.Free) {
      this.api.deliveryInfo().then(x => {
        this._bookingService.myPage = x;
        this._navi.navigateByUrl('/passcard/new/step2');
      });
    }
    else {
      this._navi.navigateByUrl('/passcard/new/step3');
    }
  }
}

export const PasscardTypesResolver: ResolveFn<PasscardApplyApi> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  navi = inject(NavigateService),
  api: ApiService = inject(ApiService),
  apiProxy = inject(PasscardApiProxy),
  bookingService = inject(PasscardBookingService),
  app = inject(AppModel)
): Observable<PasscardApplyApi> => {
  const passcardApplyNo = bookingService.passcardApplyNo;
  let result: Subject<HttpResponse<PasscardApplyApi>> = null;
  if (passcardApplyNo != null && passcardApplyNo != undefined) {
      result = api.post<PasscardApplyApi>('/passcardReApplyInfo', { passcardApplyNo }, 1);
  }
  else {
      result = api.get<PasscardApplyApi>('/passcardTypeList', 1)
  }

  return result.pipe(
      catchError(() => {
          navi.navigateByUrl('/error', true);
          return EMPTY;  
      }),
      map(({ body }) => {
          setTimeout(() => apiProxy.error = body.mainMessage);
          bookingService.passcards = body.passcardTypeList ?? [];
          const dateNow = body.systemDate ? new Date(body.systemDate) : new Date();
          const year = dateNow.getFullYear();
          const m = dateNow.getMonth();
          const currMonth = Date.UTC(year, m, dateNow.getDate());
          const nextMonth = Date.UTC(year, m + 1, 1);
          bookingService.periods = [ currMonth, nextMonth, Date.UTC(year, m + 2, 1) ];
          bookingService.passcardApplyPossible = body.passcardApplyPossible;
          bookingService.applyCardDelivery = body.applyCardDelivery;
          if (body.resultCode == 0 && !app.ParkingPlace.name)  {
              const { parkingName, address1, address2 } = body;
              setTimeout(() => {
                  app.ParkingPlace.name = parkingName;
                  app.ParkingPlace.address = `${address1 ?? ''}${address2 ?? ''}`;
              })
          }

          if (body.resultCode != 0 && body.resultCode != 4) {
              navi.navigateByUrl('/error', true);
          }

          return body;
      }),
      take(1)
  );
}
