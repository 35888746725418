<h2>氏名変更確認</h2>
<t-error [message]="error" [messages]="errors"></t-error>

<t-info [value1]="'以下の内容で氏名を更新します。'" [value2]="'内容を確認し、「確定」を押してください。'"></t-info>

<section>
  <div class="tableCtn tableCtn-confirm">
    <div *ngIf="model.byUserName!=='0'">
      <p-kana [label]="'氏名'" [value]="model.fullname"></p-kana>
    </div>
    <div *ngIf="model.byUserFurigana!=='0'">
      <p-kana [label]="'氏名（カナ）'" [value]="model.kana"></p-kana>
    </div>
    <div *ngIf="model.byUserBirth!=='0'">
      <p-birthdate [label]="'生年月日'" [value]="mypage.birthdate"></p-birthdate>
    </div>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>