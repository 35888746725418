import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AppModel } from '../app.model';
import { NavigateService } from './navigate.service';
import { StorageService } from './storage.service';

import { gtagOption } from '../shared/gtag';

//
const app_body = '';
const print_body = 'background: #eee;';

//
@Injectable({
  providedIn: 'root'
})
export class RouterService {
  // mode: app, qrcode, print, unexpected
  mode = 'app';

  //
  constructor(
    private _app: AppModel,
    private _navi: NavigateService,
    private _title: Title,
    private _router: Router,
    private _route: ActivatedRoute,
    private _storage: StorageService
  ) { }

  setWatcher = async() => {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url;

        // guard mypage by login
        if (this._app.guard(url)) {
          this._navi.navigateByUrl('/error', true);
          return;
        }

        this.setModeByUrl(url);

        // gtag
        const option = gtagOption(url);
        this._title.setTitle(option.page_title);

        // reCaptcha
        // this.recaptcha.visible(url);
        if (this._app.login) {
          if (event.url.startsWith('/?parking-code')
            || event.url.startsWith('/login')
            || event.url.startsWith('/registration/confirm')) {
            this._app.login = false;
          }
        }

        // 駐車場コード対応
        const queryMap = this._route.snapshot.queryParamMap;
        const oldParkingCode = this._storage._load('parkingCode');
        const parkingCode = queryMap.get('parking-code');
        if (!this._app.login && parkingCode) {
          // 未ログインかつクエリパラメータで渡ってきた場合、保存する
          this._storage._save('parkingCode', parkingCode);
        }

        // ルーティング後、トップへスクロール
        window.scrollTo(0, 0);
      }
    });
  }

  setModeByUrl = (url: string) => {
    if (url.startsWith('/qrcode'))
      this.mode = 'qrcode';
    else if (url.startsWith('/unexpected'))
      this.mode = 'unexpected';
    else if (url.startsWith('/print'))
      this.mode = 'print';
    else if (url.startsWith('/illegal'))
      this.mode = 'illegal';
    else if (url.startsWith('/notice-info/detail'))
      this.mode = 'notice-info';
    else
      this.mode = 'app';

    //
    const body = document.querySelector('body');
    body.style.cssText = this.mode === 'print' ? print_body : app_body;
  }
}
