import { Injectable } from '@angular/core';

import { Time9 } from '../shared/time9';

//
@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  required = '{0}を入力してください。';

  number = {
    length: '{0}には{1}桁で入力してください。',
    maxLength: '{0}には{1}桁以内で入力してください。',
    minLength: '{0}には{1}桁以上で入力してください。',
    numeric: '{0}には半角数値を入力してください。',
    phone: '{0}には半角数値、「-」を入力してください。',
    post: '{0}には半角数値 3桁-4桁の形式で入力してください。',
    range: '{0}には{1}～{2}の数値を入力してください。',
    sequence: '{0}は{1}より大きな値を入力してください。',
    terminalNo: '{0}には半角数値 1桁-4桁-3桁の形式で入力してください。'
  };

  date = {
    format: '{0}には正しい日付を入力してください。',
    notEqual: '{0}は{1}の日付が一致していません。',
    range: '{0}と{1}の間隔は{2}日以下の日付を指定してください。',
    sequence: '{0}は{1}より後の日付を指定してください。',
  };

  string = {
    alphaNumeric: '{0}には半角英数字を入力してください。',
    dupMulti: '{0}に登録済みのデータが入力されています。',
    dupSingle: '{0}が重複しています。',
    email: '{0}には正しい形式で入力してください。',
    equalPasswd: '{0}と{1}の文字列は異なるように入力してください。',
    fullWidth: '{0}には全角文字を入力してください。',
    halfWidth: '{0}には半角文字を入力してください。',
    katakana: '{0}には全角カナ文字を入力してください。',
    length: '{0}は{1}文字で入力してください。',
    maxLength: '{0}は{1}文字以内で入力してください。',
    minLength: '{0}は{1}文字以上で入力してください。',
    notEqual: '{0}と{1}の文字列が一致していません。',
    notEqual2: '{0}が一致していません。',
    notEqualLength: '{0}と{1}の桁数が一致していません。',
    passwd: '{0}には半角英文字と半角数字を含むように入力してください。',
    passwd2: '{0}に使用できない文字が含まれています。',
    prohibit: '{0}に未対応の文字が含まれています。',
    prohibit2: '{0}には「\\/:*?"<>|&」の文字は使用できません。',
    regxp: '{0}には正しい形式で入力してください。',
    requiredList: '{0}を選択してください。',
    fullname: '{0}には使用できない文字が含まれています。',
    kanakigo: '{0}にはカナもしくは記号を入力してください',
    zenhankana: '{0}にはカナ文字を入力してください。',
    nameLength : '{0}は全角{1}文字、半角{2}文字以内で入力してください。',

  };

  time = {
    sequence: '{0}には{1}より後の時刻を指定してください。',
    minDays: '入場予定日と出場予定日の間隔は{0}日以上の日付を指定してください。'
  };

  numberPlate = 'この車両情報は使用できません。';

  digit = '{0}の入力に誤りがあります。';

  credit = {
    certErr: 'クレジットカードの認証に失敗しました。',
    countLimit: 'クレジットカードの認証エラーの回数制限に達しました。',
  };

  //
  constructor() { }

  //
  get = (str: string, ...args: string[]) => {
    args.forEach((v, i) => str = str.replace(`{${i}}`, v));
    return str;
  }

  // required
  fnRequired = (name: string, ...values) =>
    !values.every(v => !!v) ? [this.get(this.required, name)] : [];

  validRequired = (value: string, name: string) =>
    !value ? [this.get(this.required, name)] : [];

  // cross field
  xValidRequired = (value1: string, value2: string, name: string) =>
    !value1 && (!value1 !== !value2) ? [this.get(this.required, name)] : [];

  xValidRequired2 = (value1: string, value2: string, name: string) =>
    !value1 !== !value2 ? [this.get(this.required, name)] : [];

  // Test
  alphaTest = (value: string) => /^[a-zA-Z]+$/.test(value);
  alphaNumberTest = (value: string) => /^[a-zA-Z0-9]+$/.test(value);
  passwdTest = (value: string) => /^[a-zA-Z0-9#$+\-./:=?@\[\]^_`|]+$/.test(value);
  numberTest = (value: string) => /^[0-9]+$/.test(value);
  prohibitTest = (value: string) => !/[\\/:*?"<>|&]/.test(value);
  surrogateTest = (value: string) => !value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g);
  kanaTest = (value: string) => /^[ァ-ヶー]+$/.test(value); ///[ｱ-ﾝﾞﾟ]+/
  hankataTest = (value: string) => /[ｱ-ﾝﾞﾟ]+/.test(value);
  mailTest = (value: string) => /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
  fullWidthTest = (value: string) => /^[^\x01-\x7E\xA1-\xDF]+$/.test(value);
  setnameTest = (value: string) => !value.match(/^[ 　\r\n\t]*$/);

  // お客様情報入力_氏名用（全角文字＆半角英数字のみ許可）
  fullnameTest = (value: string) => /(^[^ｱ-ﾝﾞﾟ!-/:-@¥\[-`{-~\]]+$)/.test(value);
  // 防犯登録番号
  securityNumberTest = (value: string) => /^[ -~]+$/.test(value);
  // 郵便番号
  postcodeTest = (value: string) => /^[0-9]{3}-[0-9]{4}$/.test(value);
  // カナ・記号
  kanakigoTest = (value: string) => /^[ァ-ヶー｡-ﾟ+ -/:-@\[-\`\{-\~]+$/.test(value);
  // カナ（全角・半角）
  zenhankanaTest = (value: string) => /^[ァ-ヴｦ-ﾟー]+$/.test(value);

  // 文字列の長さ（バイト数）カウント
  countStrLength = (value: string) => {
    let byteLen = 0;
    if (!value) {
      return byteLen;
    }
    const strLen = value.length;
    for (let i = 0; i < strLen; i++) {
      (value[i].match(/[ -~]/)) ? byteLen += 1 : byteLen += 2;
    }
    return byteLen;
  }


  //
  pushPasswd = (messages: string[], value: string, name: string) => {
    if (value) {
      // #$+-./:=?@[]^_`|
      if (!this.passwdTest(value))
        messages.push(this.get(this.string.passwd2, name));
      else if (value.length < 8)
        messages.push(this.get(this.string.minLength, name, '8'));
      else if (64 < value.length)
        messages.push(this.get(this.string.maxLength, name, '64'));
      else if (!(/[A-Za-z]/.test(value) && /[0-9]/.test(value)))
        messages.push(this.get(this.string.passwd, name));
    }
  }

  //
  pushEmail = (messages: string[], value: string, name: string) => {
    if (value && !this.mailTest(value))
      messages.push(this.get(this.string.regxp, name));
  }

  // surrogate pair
  pushProhibit = (messages: string[], value: string, name: string) => {
    if (value && !this.surrogateTest(value))
      messages.push(this.get(this.string.prohibit, name));
    else if (value && !this.prohibitTest(value))
      messages.push(this.get(this.string.prohibit2, name))
  }

  //
  pushKana = (messages: string[], value: string, name: string) => {
    if (value && !this.kanaTest(value))
      messages.push(this.get(this.string.katakana, name));
  }

  pushNumeric = (messages: string[], value: string, name: string) => {
    if (value && !this.numberTest(value))
      messages.push(this.get(this.number.numeric, name));
  }

  pushAlpaNumeric = (messages: string[], value: string, name: string) => {
    if (value && !this.alphaNumberTest(value))
      messages.push(this.get(this.string.alphaNumeric, name));
  }

  pushFullWidth = (messages: string[], value: string, name: string) => {
    if (value && (!this.fullWidthTest(value) || this.hankataTest(value)))
      messages.push(this.get(this.string.fullWidth, name));
  }

  //
  pushPhone = (messages: string[], value: string, name: string) => {
    if (value) {
      if (!this.numberTest(value))
        messages.push(this.get(this.number.numeric, name));
      else if (value.length < 10)
        messages.push(this.get(this.number.minLength, name, '10'));
      else if (11 < value.length)
        messages.push(this.get(this.number.maxLength, name, '11'));
    }
  }

  //
  pushSetname = (messages: string[], value: string, name: string) => {
    if (value && !this.setnameTest(value))
    messages.push(this.get(this.required, name));
  }

  //
  pushNumber = (messages: string[], value: string, name: string) => {
    if (value) {
      if (!this.numberTest(value))
        messages.push(this.get(this.number.numeric, name));
      else if (value.length !== 6)
        messages.push(this.get(this.number.length, name, '6'));
    }
  }

  //
  pushSecurity = (messages: string[], value: string, name: string) => {
    if (value) {
      if (!this.numberTest(value))
        messages.push(this.get(this.number.numeric, name));
      else if (value.length < 3)
        messages.push(this.get(this.number.minLength, name, '3'));
      else if (4 < value.length)
        messages.push(this.get(this.string.maxLength, name, '4'));
    }
  }

  pushCredit2 = (messages: string[], value: string, name: string) => {
    if (value) {
      if (!this.numberTest(value))
        messages.push(this.get(this.number.numeric, name));
      else if (value.length < 2)
        messages.push(this.get(this.number.minLength, name, '2'));
    }
  }

  pushCredit4 = (messages: string[], value: string, name: string) => {
    if (value) {
      if (!this.numberTest(value))
        messages.push(this.get(this.number.numeric, name));
      else if (value.length !== 4)
        messages.push(this.get(this.number.length, name, '4'));
    }
  }

  //
  pushETCCard = (messages: string[], value: string, name: string) => {
    if (value) {
      if (!this.numberTest(value))
        messages.push(this.get(this.number.numeric, name));
      else if (value.length < 13)
        messages.push(this.get(this.number.minLength, name, '13'));
      // ETC の Luhnアルゴリズムチェック
      // else if (!checkDigit(value))
      //   messages.push(this.get(this.digit, name));
    }
  }

  //
  pushDateToday = (messages: string[], value: number, name: string) => {
    if (value) {
      // const today = Time9.date();
      // today.setHours(0, 0, 0, 0);
      const today = Time9.today0();

      if (value < today.valueOf())
        messages.push(this.get(this.date.sequence, name, '現在日'));
    }
  }

  pushTimeNow = (messages: string[], time: number, hour: string, minute: string, name: string) => {
    if (time && hour && minute) {
      const dt = Time9.date(time);
      dt.set({ hour: +hour, minute: +minute });

      if (dt.valueOf() < new Date().getTime())
        messages.push(this.get(this.time.sequence, name, '現在時刻'));
    }
  }

  // 氏名用
  pushFullname = (messages: string[], value: string, name: string) => {
    const len = this.countStrLength(value)
    if (16 < len) {
      messages.push(this.get(this.string.nameLength, name,'8','16'));
    }
  }

  // 氏名用（カナ）
  pushFullnameKana = (messages: string[], value: string, name: string) => {
    if (value && (8 < value.length)) {
      messages.push(this.get(this.string.maxLength, name, '8'));
    } else  if (value && !this.kanaTest(value)) {
      messages.push(this.get(this.string.katakana, name));
    }
  }

  // 生年月日用
  pushBirthDate =  (messages: string[], year: string, month: string, day: string, name: string) => {
    if (year && month && day) {
      // 年、月、日が入力されている場合、日付の妥当性チェックを行う
      const birthdate = String(year) + '/' + String(month) + '/' + String(day);
      const dateBirthdate = new Date(birthdate);
      const dateYear = dateBirthdate.getFullYear();
      const dateMonth = ('00' + (dateBirthdate.getMonth()+1)).slice(-2);
      const dateDay = ('00' + (dateBirthdate.getDate())).slice(-2);
      if (birthdate !== String(dateYear) + '/' + String(dateMonth) + '/' + String(dateDay) ) {
        messages.push(this.get(this.date.format, name));
      }
    }
  }

  // 防犯登録番号
  pushSecurityNumber = (messages: string[], value: string, name: string) => {
    if (value && (30 < value.length)) {
      messages.push(this.get(this.string.maxLength, name, '30'));
    }
  }

  // 郵便番号
  pushPostcode = (messages: string[], postcode1: string, postcode2: string, name: string) => {
    if (postcode1 && postcode2) {
      const value = String(postcode1) + '-' + String(postcode2);
      if (!this.postcodeTest(value)) {
        messages.push(this.get(this.number.post, name));
      }
    }
  }

  // 住所
  pushAddress= (messages: string[], value: string, name: string) => {
    if (value && (32 < value.length)) {
      messages.push(this.get(this.string.maxLength, name, '32'));
    }
  }

  // 勤務先/通学先
  pushWorkspaceName = (messages: string[], value: string, name: string) => {
    if (value && (32 < value.length)) {
      messages.push(this.get(this.string.maxLength, name, '32'));
    } else if (value && !this.fullWidthTest(value)) {
      messages.push(this.get(this.string.fullWidth, name));
    }
  }

  // 勤務先/通学先（カナ）
  pushWorkspaceNameKana = (messages: string[], value: string, name: string) => {
    if (value && (32 < value.length)) {
      messages.push(this.get(this.string.maxLength, name, '32'));
    } else  if (value && !this.kanaTest(value)) {
      messages.push(this.get(this.string.katakana, name));
    }
  }

  // 郵便番号
  pushWorkspacePostcode = (messages: string[], postcode1: string, postcode2: string, name: string) => {
    if (postcode1 && postcode2) {
      const value = String(postcode1) + '-' + String(postcode2);
      if (!this.postcodeTest(value)) {
        messages.push(this.get(this.number.post, name));
      }
    }
  }

  // 市区町村
  pushMunicipalities = (messages: string[], value: string, name: string) => {
    if (value && (16 < value.length)) {
      messages.push(this.get(this.string.maxLength, name, '16'));
    }
  }

  // カナ・記号
  pushKanakigo = (messages: string[], value: string, name: string) => {
    if (value && (8 < value.length)) {
      messages.push(this.get(this.string.maxLength, name, '8'));
    }
    // else if (value && !this.kanakigoTest(value)){
    //  messages.push(this.get(this.string.kanakigo, name));
    //}
  }

  // 登録番号
  pushRegistrationNumber = (messages: string[], value: string, name: string) => {
    if (value && (4 < value.length)) {
      messages.push(this.get(this.string.maxLength, name, '4'));
    } else if (value && !this.numberTest(value)) {
      messages.push(this.get(this.number.numeric, name));
    }
  }
  
  // 車両情報（バイク）
  pushMotorcycleNumber = (messages: string[], value: string, name: string) => {
    const len = this.countStrLength(value)
    if (30 < len) {
      messages.push(this.get(this.string.nameLength, name,'15','30'));
    }
  }

  //
  hasError(...arrays: any[]) {
    for (const ary of arrays) {
      if (ary && ary.length)
        return true;
    }
    return false;
  }

  merge(...arrays: any[]) {
    const a = [];

    arrays.forEach(ary => {
      if (ary)
        ary.forEach(s => {
          if (!a.includes(s))
            a.push(s);
        });
    });

    return a;
  }
}

// check digit for credit (ETC Card)
// Luhn
function checkDigit(value: string) {
  return Array.from(value).reverse().map(s => +s).reduce((acc, val, i) => {
    if (i % 2) {
      const acc2 = val * 2;
      return acc + (acc2 < 10 ? acc2 : acc2 - 9);
    } else
      return acc + val;
  }) % 10 === 0;
}
