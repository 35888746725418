import { Component, OnInit, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../services';

import { AppModel } from '../app.model';
import { MypageModel } from '../mypage/mypage.model';

//
interface TopReq {
}

interface TopRes {
  resultCode: string;
  loginStatusCode: string;
}

//
@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
})
export class TopComponent implements OnInit {

  constructor(
    public api: ApiService,
    public conf: ConfigService,
    private navi: NavigateService,
    public app: AppModel,
    private mypage: MypageModel,
  ) { }

  ngOnInit() {
    this.conf.setAreaSpace(0);

    if (!this.app.login)
      this.topApi();
  }

  private topApi() {
    const req: TopReq = {
    };

    this.api.post<TopRes>('/top', req, 1).subscribe(
      res => this.autoLogin(res.body)
    );
  }

  private autoLogin(body: TopRes) {
    if (body.resultCode === '0' && body.loginStatusCode === '1') {
      this.mypage.getMypage();
      this.app.login = true;
      this.app.saveStorage();
    }
  }

  onRegistration = () => {
    this.navi.navigateByUrl('/registration/mail');
  }

  private goCreate = (time: number) => {
  }

  onTime1 = (evt: CustomEvent) => {
  }

  onArea = (_i: number) => {
  };

}
