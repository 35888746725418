import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ApiService } from './api.service';

//
@Injectable({
  providedIn: 'root'
})
export class NavigateService {
  api: ApiService;
  private locked: boolean;

  //
  constructor(
    private router: Router,
    private location: Location,
  ) { }

  navigateByUrl = (url: string, force?: boolean) => {
    if (force || !this.locked) {
      this.router.navigateByUrl(url);
      if (this.api)
        this.api.enabled(0);
    }
  }

  navigate = (url: string, force?: boolean, extras?: any) => {
    if (force || !this.locked) {
      this.router.navigate([url], { state: extras });
      if (this.api)
        this.api.enabled(0);
    }
  }


  current = () => this.location.path();

  /**
   * Navigates back in the platform's history.
   */
  goBack = () => {
    if (!this.locked)
      this.location.back();
  }

  //
  isLocked = () => this.locked;
  lock = (b: boolean) => this.locked = b;
}
