import { Component, OnInit } from '@angular/core';
import { NavigateService } from '../../services/navigate.service';

declare var LOOPBACK: boolean;

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
})
export class SendComponent implements OnInit {

  constructor(private navi: NavigateService) { }

  ngOnInit() { }

  onDblclick = () => {
    if (LOOPBACK) {
      //this.navi.navigateByUrl('/registration/confirm/token/1536307335486');
      //this.navi.navigateByUrl('/registration/confirm?token=sampleToken&date=1536307335486&parkingCode=1235678');
      //this.navi.navigateByUrl('/registration/confirm?token=sampleToken&date=1536307335486');
      this.navi.navigateByUrl('/registration/confirm/sampleToken/1536307335486/1235678'); // 駐車場コード
    }
  }

}
