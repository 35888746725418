import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService, ConfigService, KeyService, NavigateService , StorageService} from '../../services';
import { PasswdReissueModel } from '../passwd-reissue.model';

import { Time9 } from '../../shared/time9';

import { IPasswd2Component } from '../../shared/i-passwd2/i-passwd2.component';

//
interface ConfirmReq {
  parkingCode: number;
  mailSendToken: string;
  mailSendDate: string;
}

interface ConfirmRes {
  resultCode: number;
  linkStatusCode: number;
  agreementStatus: number;
}

interface UpdateReq {
  mailSendToken: string;
  mailSendDate: string;
  password: string;
}

interface UpdateRes {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(IPasswd2Component, { static: false }) passwd: IPasswd2Component;

  // for Server
  error: string;
  errors: string[];

  //
  loading: boolean;
  disabled: boolean;

  //
  constructor(
    public model: PasswdReissueModel,
    public api: ApiService,
    private conf: ConfigService,
    private navi: NavigateService,
    public key: KeyService,
    private _storage: StorageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;
    this.disabled = true;
    this.model.passwd = null;

    const map = this.route.snapshot.paramMap;
    this.postParams(map.get('p1'), map.get('p2'));
  }

  ngDoCheck() {
    if (this.passwd)
      this.disabled = !this.passwd.validation();
  }

  //
  private postParams = (p1: string, p2: string) => {
    this.api.disabled = true;

    if (p1 !== this.model.p1 || p2 !== this.model.p2) {
      this.model.reset();
      this.model.p1 = p1;
      this.model.p2 = p2;
    }

    //
    const req: ConfirmReq = {
      parkingCode: Number(this._storage._load('parkingCode')),
      mailSendDate: p2,
      mailSendToken: p1
    };

    this.api.post<ConfirmRes>('/support/reissue/confirm', req, 1).subscribe(
      _res => {
        this.api.enabled(0);
        this.loading = false;

        // 利用規約同意の不具合対応
        let agreementStatus = '0';
        if (_res.body.agreementStatus != null && _res.body.agreementStatus != undefined) {
          agreementStatus = String(_res.body.agreementStatus);
        }
        this._storage._save('agreementStatus', agreementStatus);

      }
    );
  }

  //
  onKeyup = () => {
    this.error = null;
    this.errors = null;
  }

  //
  private afterUpdate = (body: UpdateRes) => {
    if (body.resultCode === 0) {
      this.model.apply();
      this.navi.navigateByUrl('/passwd-reissue/complete');
    } else {
      this.api.enabled();

      this.error = body.mainMessage;
      this.errors = body.validatorMessageList;
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const m = this.model;
    const req: UpdateReq = {
      mailSendDate: m.p2,
      mailSendToken: m.p1,
      password: m.passwd
    };

    this.api.put<UpdateRes>('/support/reissue/update', req, 1).subscribe(
      res => this.afterUpdate(res.body)
    );
  }

}
