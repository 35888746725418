import { Component, Input } from '@angular/core';
import { ReservationStatus, PeriodKind, formatPeriod, PasscardApply, Passcard, Delivery, mapStatus } from '../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { ApiService } from 'src/app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'apply-info',
  templateUrl: './apply-info.component.html'
})
export class ApplyInfoComponent {
  BS = ReservationStatus;
  confirmedClass: string | null = null;
  viewProc = 0;

  @Input() reservation: PasscardApply | null = null;

  // お知らせ表示フラグ
  noticeInfoShow = false;

  constructor(
    public app: AppModel,
    public api: ApiService,
    private router: Router) {
  }

  ngOnInit(): void {
    // お知らせを表示するか判定
    if (this.router.url.startsWith('/passcard/applyInfo')) {
      this.noticeInfoShow = true;
    } else {
      this.noticeInfoShow = false;
    }
  }
  
  get Status() {
    if (this.reservation.passcardAvailable == 1) return ReservationStatus.Available;
    
    return mapStatus(this.reservation.status);
  }

  get PasscardInfo(): Passcard {
    return {
      status: this.reservation.requestStatus,
      no: this.reservation.no,
      passcardType: this.reservation.passcardType,
      extended: 0,
      name: this.reservation.name,
      unitPrice: this.reservation.unitPrice,
      waitNum: this.reservation.waitNum,
    };
    
  }

  get ParkingArea() {
    return {
      name: this.reservation?.parkingName,
      address: this.reservation?.parkingAddress,
      noticeInfo: this.reservation?.noticeInfoList
    };
  }

  get NoticeInfo(){
    return this.reservation?.noticeInfoList;
  }

  /**
   * 月末方式選択月タイプ(int),
   * 0：選択なし、1：今月から、2：翌月から、3：翌々月から
   */
  get SelectMonthType() {
    return PeriodKind[this.reservation.selectTargetMonth] ?? '選択なし';
  }

  get ValidPeriod() {
    const { passcardValidFrom }= this.reservation!;
    return formatPeriod([passcardValidFrom, null]);
  }

  get Delivery(): Delivery {
    if (this.reservation?.applyCardDelivery == 0 || !this.reservation?.deliveryZipCode) return null;

    return this.reservation
  }
}
