<p *ngIf="!hasNP()" class="confirmText">登録されていません</p>

<div *ngIf="hasNP()" class="numberplate">
  <div class="carBox">
    <div class="carTop">
      <p class="topLeft">{{np[0]}}</p>
      <p class="topRight">{{np[1]}}</p>
    </div>
    <div class="carBottom">
      <p class="bottomLeft">{{np[2]}}</p>
      <p class="bottomRight" [innerHtml]="np[3]|numberPlate"></p>
    </div>
  </div>
</div>