<div *ngIf="model.byUserBirth!=='0'">
  <div class="tableLine">
    <div class="tableCell_l">{{label}}
      <span *ngIf="model.byUserBirth==='2'">
        <span class="require">必須</span>
      </span>
    </div>
    <div class="tableCell_r">
      <div class="selectTime">
        <div class="selectBox-y br-sp-pad">
          <select [(ngModel)]="model.birthdate[0]" (change)="onChangeYear()"
            [ngClass]="{'error_border_r':valid.hasError(msgYear)}">
            <option value="">--</option>
            <option *ngFor="let op of option_y" [value]="op.value">{{op.text}}</option>
          </select>
        </div>
        <span>年</span>
        <div class="selectBox">
          <select [(ngModel)]=" model.birthdate[1]" (change)="onChangeMonth()"
            [ngClass]="{'error_border_r':valid.hasError(msgMonth)}">
            <option value="">--</option>
            <option *ngFor="let op of option_m" [value]="op.value">{{op.text}}</option>
          </select>
        </div>
        <span>月</span>
        <br class="br-sp-2">
        <div class="selectBox ">
          <select [(ngModel)]="model.birthdate[2]" [ngClass]="{error_border_r:valid.hasError(msgDay)}" class="">
            <option value="">--</option>
            <option *ngFor="let op of option_d" [value]="op.value">{{op.text}}</option>
          </select>
            </div>
          <span class="br-sp-pad">日</span>
        </div>
      <p-error [messages]="valid.merge(message, messages)"></p-error>
    </div>
  </div>
</div>