import { Component, OnInit } from '@angular/core';

import { NavigateService, StorageService } from '../../services';
import { ConfigService } from '../../services/config.service';

import { AppModel } from '../../app.model';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'app-navi',
  templateUrl: './navi.component.html',
})
export class NaviComponent implements OnInit {
  get btn1Caption() {
    return !this.app.login ? '会員登録' : '定期券';
  };
  get btn2Caption() {
    return !this.app.login ? 'ログイン' : 'マイページ';
  };
  get btn1Icon() {
    return !this.app.login ? 'button-register' : 'button-passcard';
  }
  get btn2Icon() {
    return !this.app.login ? 'button-login' : 'button-person';
  }

  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    public conf: ConfigService,
    private _navi: NavigateService,
    private _storage: StorageService
  ) { }

  //
  ngOnInit() { }
  onTopBtn1Click() {
    if (this.app.login) {
      this.api.getPasscardApplyInfoAndNavi();
    } else {
      // 会員登録完了後の画面遷移先切替対応
      const url_path = location.pathname;
      this._storage._save('transitionSourceURL', url_path);
      this._navi.navigateByUrl('/registration/mail');
    }
  }

  //
  onTopBtn2Click() {
    if (this.app.login) {
      this._navi.navigateByUrl('/mypage/top');
    } else {
       this._navi.navigateByUrl('/login');
    }
  }

  //
  onNavigate = (e: Event, url: string) => {
    e.preventDefault();
    this._navi.navigateByUrl(url);
  }

  onReserve = (e: Event) => {
    e.preventDefault();
  }

  onCurrent = (e: Event) => {
    e.preventDefault();
  }

  onPast = (e: Event) => {
    e.preventDefault();
  }

  onQR = (e: Event) => {
    e.preventDefault();
  }

  onOpenUrl = (e: Event, url: string, name: string) => {
    e.preventDefault();
  }

}
