<h2>{{app.ParkingPlace.name}}</h2>
<flow-box [steps]="Steps" [processing]="0" [processed]="0" />

<h3>定期選択</h3>
<passcard-info *ngIf="isReApply && passcardSelected; else list" [model]="passcardSelected"
  [showStatus]="false"></passcard-info>
<ng-template #list>
  <div class="passcard-list">
    <div class="passcard-select input passcard-normal" *ngFor="let passcard of passcards; let i=index;"
      [ngClass]="{
        'passcard-disabled': passcard.status == PasscardStatus.NoVacancies || passcard.status == PasscardStatus.StopApply ,
        'passcard-selected': passcardSelected && passcardSelected.passcardType == passcard.passcardType
      }"
      (click)="onPasscardChange(passcard)">
      <div>
        <div class="radio-wrapper">
          <input type="radio" name="passcard" id="passcard{{i}}"
          [disabled]="passcard.status === PasscardStatus.NoVacancies || passcard.status === PasscardStatus.StopApply"
          [checked]="passcardSelected && passcard.passcardType == passcardSelected.passcardType"
          [value]="i" />
        </div>
      </div>
      <div class="status">
        <div class="parking-status" [ngClass]="getStatusCls(passcard.status)">{{getStatusText(passcard.status)}}</div>
      </div>
      <div class="select-label"><label for="passcard{{i}}">{{passcard.name}}</label></div>  
      <div class="cost font-small"> 1ヵ月あたり{{passcard.unitPrice | currency:'JPY'}}</div>
    </div>
  </div>
</ng-template>
<br>
<ng-container *ngIf="showPeriod">
  <h3>定期期間</h3>
  <div class="passcard-list">
    <div class="passcard passcard-normal" [ngClass]="{'passcard-selected': periodKind == targetMonth }"
      (click)="onPeriodChange(targetMonth)">
      <div class="input">
        <div class="radio-wrapper"><input type="radio" name="period" [checked]="periodKind == targetMonth"
            value="{{targetMonth}}" id="period2" /></div>
        <div><label class="radio-caption" for="period2">{{PERIODKIND[targetMonth]}}</label></div>
      </div>
    </div>
  </div>
  <br>
  <div class="tableCtn">
    <p-single label="定期期間" [value]="fixedPeriod" />
  </div>
  <br>
</ng-container>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button
    (apply)="onNext()"
    [disabled]="NextDisabled"
    [loading]="api.Disabled"
    [label]="'次へ'"
    [class]="'cmn-btn six'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>