import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService, StorageService } from '../../services';
import { AppModel } from '../../app.model';
import { RegistrationModel } from '../registration.model';

import { IEMail2Component } from '../../shared/i-email2/i-email2.component';

//
interface Req {
  parkingCode: number;
  mailAddress: string;
  transitionSourceURL: string;
  agreementStatus: number;
};

//
@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
})
export class MailComponent implements OnInit, DoCheck {
  @ViewChild(IEMail2Component, { static: false }) email: IEMail2Component;

  consent = false;

  // for Server
  error: string;

  //
  disabled: boolean;

  //
  strParkingCode: string;

  //
  parkingCode: number;

  //
  constructor(
    public model: RegistrationModel,
    public api: ApiService,
    private _app: AppModel,
    private conf: ConfigService,
    private _navi: NavigateService,
    private _storage: StorageService
  ) { }

  ngOnInit() {
    this.disabled = true;
    this.model.reset();
    this.consent = false;
    this.model.termsServiceURL = this._app.publishedDocumentInfo.termsServiceURL;

    // 会員登録完了後の画面遷移先切替対応
    const transitionSourceURL = this._storage._load('transitionSourceURL');
    if (!(this._storage._load('transitionSourceURL') === '/registration/mail')) {
      // 本画面からの遷移時以外の場合、遷移先として設定する
      this.model.transitionSourceURL = transitionSourceURL;
    }
  }

  ngDoCheck() {
    if (this.email)
      this.disabled = !this.email.validation();
  }

  //
  onApply = () => {
    this.api.disabled = true;
    this.strParkingCode = this._storage._load('parkingCode');
    if (this.strParkingCode) {
      this.parkingCode = Number(this.strParkingCode);
    } else {
      this.parkingCode = 0;
    }

    // 利用規約同意の不具合対応
    let agreementStatus = '0';
    //this.strAgreementStatus = this._storage._load('agreementStatus');
    if (this._storage._load('agreementStatus') != null 
		&& this._storage._load('agreementStatus') != undefined) {
      agreementStatus = this._storage._load('agreementStatus');
    }
    const req: Req = {
      parkingCode: this.parkingCode,
      mailAddress: this.model.mailAddr,
      transitionSourceURL: this.model.transitionSourceURL,
      agreementStatus: Number(agreementStatus)
    };
    this.api.post('/registration/sendMail', req, 2).subscribe(
      _res => {
        // this.api.enabled();
        // セッションストレージからtransitionSourceURLを削除する
        this._storage.remove('transitionSourceURL');
        this._navi.navigateByUrl('/registration/send');
      }
    );
  }

}
