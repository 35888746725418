import { Component, Input } from '@angular/core';
import { ValidateService } from '../../services';

@Component({
  selector: 'i-fullname',
  templateUrl: './i-fullname.component.html',
})
export class IFullnameComponent {
  @Input() model: {byUserName: string, itemShowHide: string[], fullname: string[] };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() label: string;
  @Input() label1: string;
  @Input() label2: string;

  message: string[];
  message1: string[];
  message2: string[];

  //
  constructor(
    public valid: ValidateService
  ) { }

  //
  validation = () => {
    const msg = this.valid;
    const fullname = this.model.fullname;

    this.message = [];
    this.message1 = [];
    this.message2 = [];
    if (this.model.byUserName === '2') {
      // required ?
      const fn = msg.validRequired;
      this.message1 = fn(fullname[0], this.label);
      this.message2 = fn(fullname[1], this.label);
    }
    msg.pushFullname(this.message1, fullname[0], this.label1);
    msg.pushProhibit(this.message1, fullname[0], this.label1);
    msg.pushFullname(this.message2, fullname[1], this.label2);
    msg.pushProhibit(this.message2, fullname[1], this.label2);

    this.message = this.message1;
    if (!this.message.length) {
      this.message = this.message2;
    }
    return !this.message1.length && !this.message2.length;
  }

  onChange = () => this.messages = null;

}