<h2>住所</h2>
<t-error [message]="error"></t-error>


<section>
  <div class="tableCtn">
   <i-address
      [model]="model"
      (keyup)="onKeyup()"
      [label]="'郵便番号'"
      [label1]="'郵便番号'"
      [label2]="'住所1'"
      [label3]="'住所2'"
      [messages]="messages.zipCode"
      [messages1]="messages.address1"
      [messages2]="messages.address2"
      [spanRequire]="true">
    </i-address>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="disabled"
    [loading]="api.disabled"
    [label]="'確認'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>
