import { Component, DoCheck, Input, Output, EventEmitter } from '@angular/core';

import { ValidateService } from '../../services';
import { option_numbers } from '../../shared/utils';

import { Date9 } from '../time9';

//
@Component({
  selector: 'i-datetime',
  templateUrl: './i-datetime.component.html',
})
export class IDateTimeComponent implements DoCheck {
  @Output() validation: EventEmitter<boolean> = new EventEmitter();

  @Input() model: { year: string; month: string; hour: string; minute: string; };
  @Input() messages: string[];
  @Input() label: string;

  //
  option_y = [];
  option_m = [];
  option_h = [];
  option_M = [];

  msgYear: string[];
  msgMonth: string[];
  msgHour: string[];
  msgMinute: string[];

  message: string[];

  //
  constructor(
    public valid: ValidateService
  ) {
    const dt = Date9();
    const year = dt.year();

    this.option_y = option_numbers(year - 5, year);
    this.option_m = option_numbers(1, 12);
    this.option_h = option_numbers(0, 23);
    this.option_M = option_numbers(0, 50, 10);
  }

  ngDoCheck() {
    this.validations();
  }

  //
  onChangeYear = () => { }
  onChangeMonth = () => { }

  //
  private validations = () => {
    const msg = this.valid;

    const fn = msg.validRequired;
    this.msgYear = fn(this.model.year, this.label);
    this.msgMonth = fn(this.model.month, this.label);
    this.msgHour = fn(this.model.hour, this.label);
    this.msgMinute = fn(this.model.minute, this.label);

    this.message = this.valid.merge(this.msgYear, this.msgMonth, this.msgHour, this.msgMinute);
    this.validation.emit(this.message && !this.message.length);
  }
}
