import { Component, Input } from '@angular/core';

@Component({
  selector: 'p-error',
  templateUrl: './p-error.component.html',
})
export class PErrorComponent {
  @Input() message: string;
  @Input() messages: string[];

  constructor() { }
}
