import { Component, Input, ViewChild } from '@angular/core';
import { Delivery, DeliveryInfo } from '../../passcard.model';
import { ValidateService } from 'src/app/services';
import { IAddressComponent } from 'src/app/shared/i-address/i-address.component';
import { IPhoneComponent } from 'src/app/shared/i-phone/i-phone.component';
import { IUsernameComponent } from '../../common/i-username/i-username.component';

interface Messages extends DeliveryInfo {
  // エラー
  error?: string;
}

export interface DeliveryInputModel {
  error?: boolean;
  kind: number;
  myPage: Delivery;
  deliveryInput: Messages;
}

@Component({
  selector: 'delivery-input',
  templateUrl: './delivery-input.component.html'
})
export class DeliveryInputComponent {
  @Input() model: DeliveryInputModel;

  @ViewChild(IAddressComponent, { static: false }) address: IAddressComponent;
  @ViewChild('fullname', { static: false }) fullname: IUsernameComponent;
  @ViewChild(IPhoneComponent, { static: false }) phone: IPhoneComponent;

  constructor(public valid: ValidateService) {
  }

  get Readonly() {
    return this.model?.kind == 0;
  }

  ngDoCheck() {
    if (!this.Readonly) {
      this.validation();
    }
  }

  onChange = () => {
    this.model.error = null;
  }

  validation() {
    // お客様情報
    const b1 = this.fullname ? this.fullname.validation() : false;
    const b2 = this.phone ? this.phone.validation() : false;
    // 住所
    const b3 = this.address ? this.address.validation() : false;

    this.model.error = !(b1 && b2 && b3);
  }

  onDestKbSelected(kind: number) {
    this.model.kind = kind;
  }
}
