import { Component, Input } from '@angular/core';

@Component({
  selector: 'p-datetime',
  templateUrl: './p-datetime.component.html',
})
export class PDatetimeComponent {
  @Input() label: string;
  @Input() value: number;

  constructor() { }
}
