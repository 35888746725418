import { Injectable } from '@angular/core';
import { ValidateService } from './validate.service';

//
interface Component {
  valid: ValidateService,

  //
  cardNumbers: string[];
  cardExpires: string[];
  securityCode: string;

  //
  msgCard1: string[];
  msgCard2: string[];
  msgCard3: string[];
  msgCard4: string[];
  msgCard: string[];

  msgMonth: string[];
  msgYear: string[];
  msgExpired: string[];

  msgSecurity: string[];
}

//
@Injectable({
  providedIn: 'root'
})
export class CreditService {

  constructor() { }

  validations = (comp: Component) => {
    const valid = comp.valid;
    const fn = valid.validRequired;

    // required ?
    comp.msgCard1 = fn(comp.cardNumbers[0], 'card');
    comp.msgCard2 = fn(comp.cardNumbers[1], 'card');
    comp.msgCard3 = fn(comp.cardNumbers[2], 'card');
    comp.msgCard4 = fn(comp.cardNumbers[3], 'card');

    //
    comp.msgCard = comp.msgCard1.length ||
      comp.msgCard2.length ||
      comp.msgCard3.length ||
      comp.msgCard4.length ? ['クレジットカード番号を入力してください。'] : [];

    if (!comp.msgCard.length) {
      valid.pushCredit4(comp.msgCard1, comp.cardNumbers[0], 'クレジットカード番号');
      valid.pushCredit4(comp.msgCard2, comp.cardNumbers[1], 'クレジットカード番号');
      valid.pushCredit4(comp.msgCard3, comp.cardNumbers[2], 'クレジットカード番号');
      valid.pushCredit2(comp.msgCard4, comp.cardNumbers[3], 'クレジットカード番号');
      comp.msgCard = valid.merge(comp.msgCard1, comp.msgCard2, comp.msgCard3, comp.msgCard4);
    }

    //
    comp.msgMonth = fn(comp.cardExpires[0], 'month');
    comp.msgYear = fn(comp.cardExpires[1], 'year');

    comp.msgExpired = comp.msgMonth.length ||
      comp.msgYear.length ? ['有効期限を入力してください。'] : [];

    //
    comp.msgSecurity = fn(comp.securityCode, 'セキュリティコード');
    valid.pushSecurity(comp.msgSecurity, comp.securityCode, 'セキュリティコード');
  }
}

// MM/YY -> YYYY/MM/01
export function creditExpDate(str: string) {
  if (str) {
    const s = str.split('/');
    if (s && s.length)
      return `20${s[1]}/${s[0]}/01`;
  }

  return '';
}
