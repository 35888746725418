// gtag
declare var gtag;
declare var GA_TRACKING_ID: string;

//
export function gtagOption(url: string) {
  const option = {
    page_path: url,
    page_title: '定期利用申込サービス'
  };

  gtag('config', GA_TRACKING_ID, option);

  return option;
}

//
(function gtag1st() {
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID);

  var s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = 'https://www.googletagmanager.com/gtag/js?id=' + GA_TRACKING_ID;
  document.head.appendChild(s);
})();
