<div class="tableLine">
  <div class="tableCell_l">{{label1}}<span *ngIf="spanRequire" class="require">必須</span></div>
  <div class="tableCell_r">
    <input [(ngModel)]="model.passwd" (keydown)="key.blockCopyPaste($event)" (contextmenu)="key.blockMenu()"
      (keyup)="onChange()" [ngClass]="{'error_border_r':valid.hasError(message1, messages)}" type="password"
      class="w100" maxlength="64" placeholder="{{placeholder1}}" autocomplete="new-password">
    <p-error [messages]="valid.merge(message1, messages)"></p-error>
  </div>
</div>

<div class="tableLine">
  <div class="tableCell_l">{{label2}}<span *ngIf="spanRequire" class="require">必須</span></div>
  <div class="tableCell_r">
    <input [(ngModel)]="passwd" (keydown)="key.blockCopyPaste($event)" (contextmenu)="key.blockMenu()"
      (keyup)="onChange()" [ngClass]="{'error_border_r':valid.hasError(message2)}" type="password" class="w100"
      maxlength="64" placeholder="{{placeholder2}}">
    <p-error [messages]="message2"></p-error>
  </div>
</div>

