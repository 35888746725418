import { Component, Input } from '@angular/core';

@Component({
  selector: 't-error',
  templateUrl: './t-error.component.html',
})
export class TErrorComponent {
  @Input() message: string;
  @Input() messages: string[];

  constructor() { }

  isView = () => this.message || this.messages;

}