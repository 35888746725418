<h1><span class="h_ttl">メッセージ一覧</span></h1>

<div id="container">
  <div class="msg-list">
    <table class="msg-tbl">
      <tbody>
        <tr *ngFor="let info of list">
          <td class="date">{{info.time|number2date}}<span *ngIf="info.unread" class="unread">未読</span></td>
          <td class="msg-topic"><a (click)="onDetail($event, info.id)" href="#">{{info.title}}</a></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="btn-box">
    <button (click)="navi.goBack()" class="c-btn">戻る</button>
  </div>

</div>