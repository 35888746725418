import { Component, Input } from '@angular/core';
import { hasNP } from '../utils';

@Component({
  selector: 'p-carinfo',
  templateUrl: './p-carinfo.component.html',
})
export class PCarInfoComponent {
  @Input() label: string;
  @Input() np: string[];

  constructor() { }

  hasNP = () => hasNP(this.np);
}
