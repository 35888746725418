<div class="confirm-info confirm-info-border">
  <div><i class="confirm-img info-img"></i></div>
  <div>
    <p>以下の内容で定期紛失申請を行います。</p>
    <p>内容を確認し、「申請」を押してください。</p>
  </div>
</div>

<h2>定期紛失</h2>
<flow-box [steps]="Steps" [processing]="CurrentProc" [processed]="CurrentProc" />

<h3>定期情報</h3>
<div class="card-container">
  <card *ngIf="contract" [reservation]="contract"></card>
</div>
<br>

<ng-container *ngIf="ShowDelivery">
  <h3>配送先情報</h3>
  <div class="comment delivery-tip"><span>※定期券の発送先となるため、</span><span>お間違えの無いようにご入力ください</span></div>
  <br>
  <delivery [delivery]="delivery"></delivery>
  <div *ngIf="!IsComplete" class="button-wrapper">
    <button type="button" class="cmn-btn bck-btn" (click)="onReInput(2)">配送先情報入力に戻る</button>
  </div>
  <br>
</ng-container>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button (apply)="onApply()" [disabled]="api.Disabled" [loading]="api.Disabled" [label]="'申請'"
    [class]="'cmn-btn dcd-btn six'" [lClass]="'button-box-loading'" />
</div>
