<div class="infoWrap">
  <h3>お知らせ</h3>
  <ul class="infoList" *ngIf="getParkingNoticeInfoList().length > 0">
    <li *ngFor="let info of getParkingNoticeInfoList(); let i=index;" class="infoContent">
      <div class="text">
        <div class="date">{{info.siteNoticeStart|number2date}}</div>
      </div>
      <div class="text">
          <a (click)="onDetail(i)">{{info.title}}</a>
      </div>
    </li>
  </ul>
  <div *ngIf="getParkingNoticeInfoList().length <= 0">
    <p >現在お知らせはありません</p>
  </div>
  <div class="buttonBox" *ngIf="getParkingNoticeInfoList().length > 0">
    <loading-button (apply)="onNoticeListClick()" [loading]="api.disabled" [label]="'お知らせ一覧を表示する'"
      [class]="'cmn-btn bck-btn'" [lClass]="'button-box-loading'" />
  </div>
</div>