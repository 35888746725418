<h2>
  <span *ngIf="mypage.byUserName!=='0' || mypage.byUserFurigana!=='0'">氏名</span>
  <span *ngIf="!(mypage.byUserName=='0' && mypage.byUserFurigana=='0' || model.byUserBirth=='0')" >・</span>
  <span *ngIf="model.byUserBirth!=='0'">生年月日</span>
</h2>
<t-error [message]="error"></t-error>

<section>
  <div class="tableCtn">
  <i-fullname
    [model]="model"
    [messages]="messages.fullname"
    [label]="'氏名'"
    [label1]="'氏名（姓）'"
    [label2]="'氏名（名）'"
    (keyup)="onKeyup()"
    [spanRequire]="true">
  </i-fullname>
  <i-kana
    [model]="model"
    [messages]="messages.kana"
    [label]="'氏名（カナ）'"
    [label1]="'氏名（カナ）（セイ）'"
    [label2]="'氏名（カナ）（メイ）'"
    (keyup)="onKeyup()"
    [spanRequire]="true">
  </i-kana>
  <div *ngIf="model.byUserBirth!=='0'">
    <p-birthdate [label]="'生年月日'" [value]="mypage.birthdate"></p-birthdate>
  </div>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn  bck-btn" [ngClass]="{'mr15':mypage.byUserName!=='0' || mypage.byUserFurigana!=='0'}">戻る</button>
  <span *ngIf="mypage.byUserName!=='0' || mypage.byUserFurigana!=='0'">
  <loading-button
    (apply)="onApply()"
    [disabled]="disabled"
    [loading]="api.disabled"
    [label]="'確認'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
  </span>
</div>