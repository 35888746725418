import { fromEvent } from 'rxjs';

// https://github.com/kazuhikoarase/qrcode-generator
declare var qrcode;
declare var QR_CELL;

// selector: '.qrcode'
export function generateQrcode(code: string, selector: string) {
  const typeNumber = qrcodeVersion(code);
  var qr = qrcode(typeNumber, 'Q');
  qr.addData(code);

  try {
    qr.make();

    const elm = document.querySelector(selector);
    fromEvent<MutationEvent>(elm, 'DOMNodeInserted').subscribe(() => {
      window.scrollTo(0, 0);
    });

    elm.innerHTML = qr.createImgTag(QR_CELL);
  } catch (e) {
    console.log(e);
  }
}

// qrcode level:'Q', bin
const ver_q = [
  11, 20, 32, 46, 60, 74, 86, 108, 130, 151,
  177, 203, 241, 258, 292, 322, 364, 394, 442, 482
];

function qrcodeVersion(str: string) {
  const len = str && str.length ? str.length : 0;
  for (let i = 0; i < ver_q.length; i++)
    if (len < ver_q[i])
      return i + 1;
  return 0;
}
