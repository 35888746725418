<div *ngIf="model.byUserZip!=='0' || model.byUserAddress1!=='0' ||  model.byUserAddress2!=='0'">
  <div *ngIf="model.byUserZip!=='0'">
    <div class="tableLine">
      <div class="tableCell_l">{{label1}}
        <span *ngIf="model.byUserZip==='2'">
          <span *ngIf="spanRequire" class="require">必須</span>
        </span>
      </div>
      <div class="tableCell_r">
        <div class="zipBox">
          <input (keyup)="onKeyup($event)" [(ngModel)]="model.postcode[0]" (keyup)="onChange(0)"
          [ngClass]="{'error_border_r':valid.hasError(message, messages)}" type="tel" maxlength="3" 
          placeholder="123" class="w60 frm_yubinbango_l">
          <span class="zipBox-hyphen">-</span>
          <input (keyup)="onKeyup($event)" [(ngModel)]="model.postcode[1]" (keyup)="onChange(1)"
          [ngClass]="{'error_border_r':valid.hasError(message, messages)}" type="tel" maxlength="4" 
          placeholder="4567" class="w60 frm_yubinbango_r">
        </div>
        <!--
        <div class="postcodeBox">
          <div class="postcodeBox-left">
            <input [(ngModel)]="model.postcode[0]" (keyup)="onChange(0)"
              [ngClass]="{'error_border_r':valid.hasError(message, messages)}" type="tel" maxlength="3" 
              placeholder="123">
          </div>
          <div class="postcodeBox-center">&nbsp;-&nbsp;</div>
          <div class="postcodeBox-right">
            <input [(ngModel)]="model.postcode[1]" (keyup)="onChange(1)"
              [ngClass]="{'error_border_r':valid.hasError(message, messages)}" type="tel" maxlength="4" 
              placeholder="4567">
          </div>
        </div>
        -->
        <p-error [messages]="valid.merge(message, messages)"></p-error>
      </div>
    </div>
  </div>
  <div *ngIf="model.byUserAddress1!=='0'">
    <div class="tableLine">
      <div class="tableCell_l">{{label2}}
        <span *ngIf="model.byUserAddress1=='2'">
          <span *ngIf="spanRequire" class="require">必須</span>
        </span>
      </div>
      <div class="tableCell_r">
        <input [(ngModel)]="model.address1" (keyup)="onChange()"
          [ngClass]="{'error_border_r':valid.hasError(message3, messages1)}" type="text" class="w100" maxlength="32"
          placeholder="東京都品川区〇〇〇">
          <p-error [messages]="valid.merge(message3, messages1)"></p-error>
        </div>
    </div>
  </div>
  <div *ngIf="model.byUserAddress2!=='0'">
    <div class="tableLine">
      <div class="tableCell_l">{{label3}}
        <span *ngIf="model.byUserAddress2=='2'">
          <span *ngIf="spanRequire" class="require">必須</span>
        </span>
      </div>
      <div class="tableCell_r">
        <input [(ngModel)]="model.address2" (keyup)="onChange()"
          [ngClass]="{'error_border_r':valid.hasError(message4, messages2)}" type="text" class="w100" maxlength="32"
          placeholder="〇〇〇番地">
          <p-error [messages]="valid.merge(message4, messages2)"></p-error>
        </div>
    </div>
  </div>
</div>