<div class="tableLine">
  <div class="tableCell_l">{{label}}<span *ngIf="spanRequire" class="require">必須</span></div>
  <div class="tableCell_r">
    <div class="editname">
      <input [(ngModel)]="model.receiptAddressName" (keyup)="onChange()"
        [ngClass]="{'error_border_r':valid.hasError(message, messages)}" type="text" maxlength="65">
      <p>様</p>
    </div>
    <p-error [messages]="valid.merge(message, messages)"></p-error>
  </div>
</div>
