import { Component, Input } from '@angular/core';
import { Passcard } from '../../passcard.model';

@Component({
  selector: 'passcard-info',
  templateUrl: './passcard-info.component.html'
})
export class PasscardInfoComponent {
  @Input() model: Passcard = { status: 0, name: '', no: 0, unitPrice: 0 };
  @Input() showStatus = true;

  get StatusText() {
    return this.model.waitNum > 0 ? '△空き待ち' : '〇申込可';
  }

  getStausTextExtra() {
    if (this.model.waitNum > 0) {
      return `現在${this.model.waitNum}人待ちです`;
    }

    return '';
  }
}
