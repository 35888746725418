import { Component, OnInit } from '@angular/core';
import { NavigateService } from '../../services/navigate.service';
import { StorageService } from '../../services';

declare var LOOPBACK: boolean;

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
})
export class SendComponent implements OnInit {

  constructor(
    private navi: NavigateService,
    private _storage: StorageService,
  ) { }

  ngOnInit() { 
    // 駐車場コードをセッションから削除
    if (this._storage._load('parkingCode'))
    {
      this._storage.remove('parkingCode');
    }
  }

  onDblclick = () => {
    if (LOOPBACK)
      this.navi.navigateByUrl('/passwd-reissue/confirm/token/1536307335486');
  }

}
