import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { routes as homeRoutes } from './home/home-routing.module';

//
const routes: Routes = [{
  path: '',
  component: HomeComponent,
  children: homeRoutes
}, {
  path: '**',
  redirectTo: '',
  pathMatch: 'full'
}];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
