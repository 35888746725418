<h2>パスワード変更</h2>
<t-error [message]="error"></t-error>

<t-info [value1]="'パスワードの変更を行います。'" [value2]="'パスワードを入力し、「確定」を押してください。'"></t-info>


<section>
  <div class="tableCtn">
    <i-passwd
      [model]="model0"
      [messages]="errors1"
      (keyup)="onKeyup1()"
      [placeholder]="'変更前パスワードを入力'"
      [label]="'変更前 パスワード'">
    </i-passwd>
    <i-passwd2
      [model]="model"
      [messages]="errors2"
      (keyup)="onKeyup2()"
      [placeholder1]="'変更後パスワードを入力'"
      [placeholder2]="'変更後パスワードを入力'"
      [label]="'変更後 パスワード'">
    </i-passwd2>
  </div>
  <br>
  ※パスワードは最低８文字で半角英数字（大文字、小文字）、記号が使用できます
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>