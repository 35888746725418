import { Component, OnInit } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';

import { NameBirthdateModel } from '../name-birthdate.model';
import { MypageModel } from '../../mypage.model';

//
interface Req {
  fullNameList: string[];
  furiganaList: string[];
}

interface Res {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
}

//
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {
  error: string;
  errors: string[];

  constructor(
    public model: NameBirthdateModel,
    public mypage: MypageModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService
  ) { }

  ngOnInit() {
    this.model.loadStorage();
   }

  //
  private afterPut = (body: Res) => {
    if (body.resultCode === 0) {
      this.model.apply();
      this.navi.navigateByUrl('/mypage/name-birthdate/complete');
    } else {
      this.api.enabled();

      this.error = body.mainMessage;
      this.errors = body.validatorMessageList;
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const req: Req = {
      fullNameList:this.model.fullname,
      furiganaList:this.model.kana
    };

    this.api.put<Res>('/name/update', req, 2).subscribe(
      res => this.afterPut(res.body)
    );
  }

}
