import { Injectable } from '@angular/core';
import { StorageService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class PhoneModel {
  phone: string;
  byUserTel : string;
  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = (phone: string,byUserTel: string) => {
    this.completed = false;
    this.phone = phone;
    this.byUserTel = byUserTel;
    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  setModel = (phone: string) => {
    this.phone = phone;
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('phone', this);
  }

  loadStorage = () => {
    this._storage.load('phone', this);
  }
}
