import { PasscardContract } from './models/passcard-contract';
import { UserInfo } from './models/user-info';
import { NoticeInfo } from '../services/notice-info.service';

export enum PasscardStatus {
  NoVacancies = -1,
  StopApply = 0,
  Available = 1,
  WaitingVacancies = 2,
}

export const PasscardStatusText = Object.freeze({
  '-1': '×空き無し',
  '0': '×受付停止',
  '1': '〇申込可',
  '2': '△空き待ち'
});

/**
 * 配送先情報
 */
export interface DeliveryInfo {
  /**
   * 郵便番号(3桁-4桁)
   */
  postcode: string[];

  /**
   * 住所1
   */
  address1: string;

  /**
   * 住所2
   */
  address2: string;

  /**
   * 宛名
   */
  userName: string;

  /**
   * 電話番号
   */
  phone: string;

  byUserZip?: string;
  byUserAddress1?: string;
  byUserAddress2?: string;
  byUserTel?: string;
}

export function deliveryInputToMyPage(delivery: DeliveryInfo): UserInfo {
  return {
    memberShipZipCode: delivery.postcode.join('-'),
    memberShipAddress1: delivery.address1,
    memberShipAddress2: delivery.address2,
    memberShipName: delivery.userName,
    memberShipTel: delivery.phone,
  };
}

export function deliveryInputToDelivery(delivery: DeliveryInfo) {
  return {
    deliveryZipCode: delivery.postcode.join('-'),
    deliveryAddress1: delivery.address1,
    deliveryAddress2: delivery.address2,
    deliveryName: delivery.userName,
    deliveryTel: delivery.phone,
  };
}


export function deliveryToDeliveryInput(delivery: Delivery) {
  return {
    postcode: delivery.deliveryZipCode.split('-'),
    address1: delivery.deliveryAddress1,
    address2: delivery.deliveryAddress2,
    userName: delivery.deliveryName,
    phone: delivery.deliveryTel,
    byUserZip: '2',
    byUserAddress1: '2',
    byUserAddress2: '2',
    byUserTel: '2'
  };
}

export function myPageToDeliveryInput(mypage: UserInfo): DeliveryInfo {
  return {
    postcode: mypage.memberShipZipCode.split('-'),
    address1: mypage.memberShipAddress1,
    address2: mypage.memberShipAddress2,
    userName: mypage.memberShipName,
    phone: mypage.memberShipTel,
    byUserZip: '2',
    byUserAddress1: '2',
    byUserAddress2: '2',
    byUserTel: '2'
  };
}

export const ApplyStatus = Object.freeze(
  {
    /**
     * なし
     */
    None: 0, '0': 'なし',

    /**
     * 空き待ち
     */
    WaitingFree: 1, '1': '空き待ち',

    /**
     * 再申請待ち
     */
    WaitingReApply: 2, '2': '再申請待ち',

    /**
     * 申請確認待ち
     */
    WaitingConfirm: 3, '3': '申請確認待ち',

    /**
     * 申請修正待ち
     */
    WaitingModify: 4, '4': '申請修正待ち',

    /**
     * 定期発行前支払い待ち
     */
    WaitingPay: 5, '5': '定期発行前支払い待ち',

    /**
     * 定期発行待ち
     */
    WaitingIssue: 6, '6': '定期発行待ち',

    /**
     * 定期発送待ち
     */
    WaitingDelivery: 7, '7': '定期発送待ち',

    /**
     * ICカード登録待ち
     */
    WaitingICRegist: 8, '8': 'ICカード登録待ち',

    /**
     * 定期発行後支払い待ち
     */
    WaitingPayAfterIssued: 9, '9': '定期発行後支払い待ち',

    /**
     * 定期更新支払い待ち
     */
    WaitingPayForRefresh: 31, '31': '定期更新支払い待ち',

    /**
     * 紛失定期再発行前支払い待ち
     */
    WaitingPayForLost: 41, '41': '紛失定期再発行前支払い待ち',

    /**
     * 紛失定期再発行待ち
     */
    WaitingReissueForLost: 42, '42': '紛失定期再発行待ち',

    /**
     * 紛失定期発送待ち
     */
    WaitingDeliveryFoReissue: 43, '43': '紛失定期発送待ち',

    /**
     * 紛失定期ICカード登録待ち
     */
    WaitingICRegistForLost: 44, '44': '紛失定期ICカード登録待ち',

    /**
     * 紛失定期再発行後支払い待ち
     */
    WaitingPayAfterReissue: 45, '45': '紛失定期再発行後支払い待ち',
  }
);

export const ReservationStatus = Object.freeze(
  {
    /**
     * 申請中
     */
    Applying: 0, '0': '申請中',

    /**
     * お手続き待ち
     */
    Waiting: 1, '1': 'お手続き待ち',

    /**
     * 利用可
     */
    Available: 2, '2': '利用可',

    /**
     * 申請受付中
     */
    Recepting: 3, '3': '申請受付中',

    /**
     * 順番待ち
     */
    Queuing: 4, '4': '順番待ち',

    /**
     * 否認
     */
    Reject: 5, '5': '申請中'
  });

export const PeriodKind = Object.freeze({
  CurrentMonth: 1, '1': '今月から',
  NextMonth: 2, '2': '翌月から',
  NextNextMonth: 3, '3': '翌々月から'
});

export const ApplyKind = Object.freeze({
  New: 0, '0': '新規',
  ReApply: 1, '1': '再申請',
  Lost: 2, '2': '紛失',
});

export interface PeriodInfo {
  kind: number,
  period: string[],
  allow: string[],
}

export const weekDays: ReadonlyArray<string> = ['日', '月', '火', '水', '木', '金', '土']

function formatDate(dateStr: string | number | undefined) {
  if (!dateStr) return '';

  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const weekDay = date.getDay();

  let d1 = `${year}/${`${month}`.padStart(2, '0')}/${`${day}`.padStart(2, '0')}(${weekDays[weekDay]})`;

  return `${d1}`
}

export function formatPeriod(period: (string | number | undefined)[]) {
  if (!period[0] && !period[1]) return '';

  return `${formatDate(period[0])} ～ ${formatDate(period[1])}`
}

export interface Passcard {
  status: number;               // ステータス(int),
  no: number;                   // 定期券種別管理番号(int),
  passcardType?: number;         // 定期券種別番号(int),
  extended?: number;             // 基本・拡張フラグ(int),
  name: string;                 // 定期券種別名称(String),
  unitPrice: number;            // 定期券販売単価(int)
  waitNum?: number;              // 待ち人数(int),
}

export interface Delivery {
  deliveryZipCode: string;      // 配送先郵便番号(String),
  deliveryAddress1: string;     // 配送先住所1 (String),
  deliveryAddress2: string;     // 配送先住所2(String),
  deliveryName: string;         // 配送先宛名(String),
  deliveryTel: string;          // 配送先電話番号(String),
}

export interface ApiResultGetPasscardApply extends ApiResult {
  passcardApplyList?: PasscardApply[];
}

export interface PasscardApply extends Delivery, CorrectionReq, PasscardContract {
  passcardApplyNo: number;      // 定期申請管理番号(int),
  status: number;               // ステータス(int),
  passcardAvailable: number;    // 0:申請中、1:利用可能
  applyCardDelivery: number;    // 配送有無(int),0:配送無し、1:配送有り
  menuSettingLostReissue: number;  // メニュー表示設定(紛失再発行) (int),
  selectTargetMonth: number;    // 0：選択なし、1：今月から、2：来月から
  dcParkingNo: number;          // DC駐車場管理番号(int),
  parkingName: string;          // 駐車場名称(String),
  parkingAddress: string;       // 駐車場住所(String)
  no: number;                   // 定期券種別管理番号(int),
  passcardType: number;         // 定期券種別番号(int),
  passcardExtended: number;     // 基本・拡張フラグ(int),
  name: string;                 // 定期券種別名称(String),
  unitPrice: number;            // 定期券販売単価(int)
  waitNum: number;              // 待ち人数(int),
  currentNum: number;           // 現在の順番(int)
  contactNo: string;            // お問い合わせ番号(String),
  deliveryVendorNo: number;      // 配送業者番号(int),
  deliveryVendorName: string;   // 配送業者名称(String),
  deliveryType?: number;        // 0：マイページと同じ、1：直接入力
  reApplyPeriod?: number;       // 定期申請中のステータス=2(再申請待ち)で、DcPasscardApplySettingDef.sReApplyPeriod≠0の場合は、申込期限を設定
  periodKind: number;

  /**
   * システム年月日(long)
   */
  systemDate?: number;

  /**
   * 定期券発送後の手続き方法URL(String)
   */
  passcardAfterShippingProcedureURL?: string;
  noticeInfoList: NoticeInfo[];
  requestStatus: number;
}

export interface ApiResult {
  resultCode: number;
  mainMessage?: string;
}

export interface PasscardApplyApi extends ApiResult {
  passcardTypeList?: Passcard[];
  applyCardDelivery: number;  // 0：配送無し、1：配送有り
  passcardApplyPossible?: number; // 0：定期申請不可、1：定期申請可能
  systemDate?: number;  // システム年月日(long)
  periods?: number[];
  applyCardIssueCommission?: number;
  applyLostIssueCommission?: number;
  applySealIssueCommission?: number;
  parkingName?: string;
  address1?: string;
  address2?: string;
}

export interface PasscardApplyResultApi extends ApiResult {
  receiptNo?: string;
}

export function mapStatus(status: number): number {
  switch (status) {
    case ApplyStatus.WaitingFree:
      return ReservationStatus.Queuing;
    case ApplyStatus.WaitingReApply:
      return ReservationStatus.Recepting;
    case ApplyStatus.WaitingPayAfterIssued:
      return ReservationStatus.Waiting;
    case ApplyStatus.WaitingModify:
      return ReservationStatus.Reject;
    default:
      if (status < 30) {
        return ReservationStatus.Applying
      }

      return ReservationStatus.Waiting
  }
}

export function getDefaultDelivery(): DeliveryInfo {
  return {
    postcode: ['', ''],
    address1: '',
    address2: '',
    userName: '',
    phone: '',
    byUserZip: '2',
    byUserAddress1: '2',
    byUserAddress2: '2',
    byUserTel: '2'
  }
}

export interface CorrectionReq {
  denialReason: string,
  correctLimitDate: number,
  applyInfo: string,
  parkingName: string,
  receiptNo: string
}

export const NOSHOWORDER = 999999;