<div *ngIf="model.byRegistrationNumber!=='0'">
  <div class="tableLine">
    <div class="tableCell_l">{{label}}
      <span *ngIf="model.byRegistrationNumber==='2'">
        <span *ngIf="spanRequire" class="require">必須</span>
      </span>
    </div>
    <div class="tableCell_r">
      <input [(ngModel)]="model.registrationNumber" (keyup)="onChange()"
        [ngClass]="{'error_border_r':valid.hasError(message, messages)}" type="text" class="w100" maxlength="4"
        placeholder="1234">
        <p-error [messages]="valid.merge(message, messages)"></p-error>      
    </div>
  </div>
</div>