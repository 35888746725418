declare var apiurl: string;
declare var version_interval: number;
declare var TIME_DELAY: number;
declare var LOOPBACK: boolean;

// for test
const SLEEP = 0;

//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, } from '@angular/common/http';

import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

import { NavigateService } from './navigate.service';
import { StorageService } from './storage.service';

import { ErrorModel } from '../error/error.model';

import { get_subject } from '../shared/calendar';

//
export enum UrlCategory { Raw, Base, MyPage }

// jwt
export interface OPTION {
  body?: any;
  headers: HttpHeaders;
  observe: 'response';
  withCredentials: boolean;
}

interface Version {
  'app': string;
}

//
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  disabled: boolean;
  timer: number;

  //
  private ver_url: string;
  private version: Version;
  private last_checked: number;

  private urls = ['', '', ''];

  private jwt_token: string;
  private option: OPTION;
  private option_version: OPTION;
  private option_upload: OPTION;

  //
  constructor(
    private http: HttpClient,
    private navi: NavigateService,
    private storage: StorageService,
    private err: ErrorModel
  ) {
    this.navi.api = this;

    this.setUrl(LOOPBACK ? '' : apiurl);

    this.option = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      }),
      observe: 'response',
      withCredentials: !LOOPBACK
    };

    this.option_version = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      }),
      observe: 'response',
      withCredentials: !LOOPBACK
    };

    this.option_upload = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      }),
      observe: 'response',
      withCredentials: true
    }

    this.loadStorage();
  }

  // 連打対策
  enabled = (delay: number = TIME_DELAY, scroll = true) => {
    // console.log('****', delay, this.timer, '****');
    // console.trace();

    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = undefined;
    }

    if (scroll)
      window.scrollTo(0, 0);

    if (delay) {
      this.timer = window.setTimeout(() => {
        this.disabled = false;
        this.timer = undefined;
      }, delay);
    } else
      this.disabled = false;
  }

  //
  private reloadIf = () => {
    if (!this.last_checked)
      this.last_checked = 0;

    const now = new Date().getTime();
    const t = now - this.last_checked;
    if (t < version_interval * 1000 * 60)
      return;
    else
      this.last_checked = now;

    // check version
    const url = this.ver_url + '?t=' + new Date().getTime();
    this.http.get<Version>(url, this.option_version).subscribe(
      res => {
        const ver = res.body;
        console.log(this.version, ver);

        if (!this.version) {
          this.version = ver;
        } else if (this.version.app !== ver.app) {
          this.storage.logout();
          location.reload();
          location.assign('/');
        }
      }
    );
  }

  //
  loadStorage = () => {
    this.jwt_token = this.storage._load('api-token');
    this._set_token();
  }

  saveStorage = () => {
    this.storage._save('api-token', this.jwt_token);
  }

  //
  getUrl = () => this.urls[1];
  setUrl = (url: string) => {
    console.log('setUrl:', url);
    this.urls[1] = url;
    this.urls[2] = url + '/user';

    this.ver_url = document.head.querySelector('base').href + 'assets/version.json';
  }

  // jwt
  private _set_token = () => {
    if (this.jwt_token && !LOOPBACK) {
      this.option.headers = this.option.headers.set('Authorization', 'Bearer ' + this.jwt_token);
      this.option_upload.headers = this.option_upload.headers.set('Authorization', 'Bearer ' + this.jwt_token);
    }
  };

  private set_token = <T>(res: HttpResponse<T>) => {
    const token = res.headers.get('JWTAuth');
    if (token) {
      this.jwt_token = token;
      this.saveStorage();
      this._set_token();
    }
  }

  private del_jwt_token = () => {
    this.jwt_token = null;
    this.storage.remove('api-token');
    this.option.headers = this.option.headers.delete('Authorization');
    this.option_upload.headers = this.option_upload.headers.delete('Authorization');
  };

  //
  public clear = () => {
    this.del_jwt_token();
    this.storage.logout();
  }

  // common success proc
  private success = <T>(res: HttpResponse<T>, subj: Subject<HttpResponse<T>>) => {
    this.navi.lock(false);
    this.set_token(res);

    const body: {
      resultCode: number;
      mainMessage: string;
      linkStatusCode: string;
    } = <any>res.body;

    if (body) {
      const code = body.resultCode.toString();
      // TODO: 2:logoutしない、3:logoutする
      if (code === '1' || code === '2' || code === '3') {
        subj.error(body);

        this.err.set(body.mainMessage, null);

        this.disabled = false;
        this.navi.navigateByUrl('/error', true);
        return;
      }

      // link status code
      const status = body.linkStatusCode;
      if (!!status && status !== '0') {
        subj.error(body);

        this.disabled = false;
        this.navi.navigateByUrl('/error-link/' + status, true);
        return;
      }

      // normal
      subj.next(res);
    }
  }

  // common error proc
  private error = <T>(error, subj: Subject<T>, uc: UrlCategory) => {
    console.log(error);

    this.navi.lock(false);
    this.disabled = false;
    subj.error(error);

    if (uc)
      this.navi.navigateByUrl('/error', true);
  }

  //
  fetch = <T>(method: string, path: string, req, uc: UrlCategory) => {
    let url = this.urls[uc] + path;
    if (uc && LOOPBACK) {
      console.log(method, url, req);

      const subj = get_subject<T>(this.http, method, url, req, this.option);
      if (subj)
        return subj;

      url = './assets/api' + url + '.' + method + '.json' + '?t=' + new Date().getTime();
      method = 'GET';
    }

    this.option.body = req;

    this.navi.lock(true);
    this.reloadIf();
    const subj = new Subject<HttpResponse<T>>();

    this.http.request<T>(method, url, this.option)
      .pipe(delay(SLEEP))
      .subscribe(
        res => this.success(res, subj),
        error => this.error(error, subj, uc)
      );

    return subj;
  }

  //
  get = <T>(path: string, uc: UrlCategory) => this.fetch<T>('GET', path, null, uc);
  post = <T>(path: string, req, uc: UrlCategory) => this.fetch<T>('POST', path, req, uc);
  put = <T>(path: string, req, uc: UrlCategory) => this.fetch<T>('PUT', path, req, uc);
  delete = <T>(path: string, uc: UrlCategory) => this.fetch<T>('DELETE', path, null, uc);

  //
  post_upload = <T>(path: string, req, uc: UrlCategory) => {
    this.navi.lock(true);
    this.reloadIf();
    const subj = new Subject<HttpResponse<T>>();

    this.http.post<T>(this.urls[uc] + path, req, this.option_upload).subscribe(
      res => this.success(res, subj),
      error => this.error(error, subj, uc)
    );

    return subj;
  }

  uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.post_upload<any>('/upload', formData, UrlCategory.Base);
  }

  /**
   * Get cookie by name from document
   */
  getCookie = (name: string) => {
    if (!document.cookie)
      return null;

    const cookie = document.cookie.split(';').find(s => s.split('=')[0].trim() === name);
    return cookie ? cookie.split('=')[1].trim() : null;
  }

  //
  getHtml = (url: string) =>
    this.http.get(url, {
      responseType: 'text'
    });
}
