import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';

import { MailAddrModel } from '../mail-addr.model';
import { MypageModel } from '../../mypage.model';

import { IEMailComponent } from '../../../shared/i-email/i-email.component';

//
interface CheckReq {
  mailAddress: string;
}

interface CheckRes {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
}

interface SendReq {
  mailAddress: string;
}

interface SendRes {
  resultCode: number;
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(IEMailComponent, { static: false }) email: IEMailComponent;

  disabled: boolean;

  error: string;
  errors: string[];

  //
  constructor(
    public model: MailAddrModel,
    public mypage: MypageModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService
  ) { }

  ngOnInit() {
    this.disabled = true;
  }

  ngDoCheck() {
    if (this.email)
      this.disabled = !this.email.validation();
  }

  //
  private afterSendMail = (body: SendRes) => {
    if (body.resultCode === 0) {
      this.model.apply();
      this.navi.navigateByUrl('/mypage/mail/send');
    } else
      this.api.enabled();
  }

  private sendMail = () => {
    const c = this.conf;

    const req: SendReq = {
      mailAddress: this.model.email
    };

    this.api.post<SendRes>('/mailAddress/sendMail', req, 2).subscribe(
      res => this.afterSendMail(res.body)
    );
  }

  //
  private afterCheckMail = (body: CheckRes) => {
    if (body.resultCode === 0) {
      this.sendMail();
    } else {
      this.api.enabled();
      this.error = body.mainMessage;
      this.errors = body.validatorMessageList;
    }
  }

  onKeyup = () => {
    this.error = null;
    this.errors = null;
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const req: CheckReq = {
      mailAddress: this.model.email
    };

    this.api.post<CheckRes>('/mailAddress/check', req, 2).subscribe(
      res => this.afterCheckMail(res.body)
    );
  }

}
