import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService, NavigateService } from '../../services';

interface Infomation {
  id: string;
  time: number;
  title: string;
  detail: string;
}

@Component({
  selector: 'app-message-detail',
  templateUrl: './detail.component.html',
})
export class DetailComponent implements OnInit {
  info: Infomation;

  constructor(
    private api: ApiService,
    public navi: NavigateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getInfo();
  }

  //
  private getInfo = () => {
    this.api.disabled = true;

    const map = this.route.snapshot.paramMap;
    const req = { id: map.get('id') }

    this.api.post<Infomation>('/message/get', req, 1).subscribe(
      res => {
        this.api.enabled()
        this.info = res.body;
      }
    );
  }

}
