import { Injectable } from '@angular/core';
import { ValidateService } from './validate.service';

//
const npMoji = 'あいうえかきくけこさすせそたちつてとなにぬねのはひふほまみむめもやゆよらりるれろわをＡＢＴＹ';

interface Component {
  label: string;
  valid: ValidateService;
  model: { numberPlate: string[] };
  message1: string[];
  message2: string[];
  message3: string[];
  message4: string[];
  message: string[];
}

//
@Injectable({
  providedIn: 'root'
})
export class CarInfoService {
  npMoji: string[];

  constructor() {
    this.npMoji = npMoji.split('');
  }

  validationRequired = (comp: Component) => {
    const np = comp.model.numberPlate;
    comp.message = [];
    if (!np[0] && !np[1] && !np[2] && !np[3]) {
      comp.message = [comp.label + 'を入力してください。'];
    }
    return !comp.message || !comp.message.length;
  }

  validation = (comp: Component) => {
    const np = comp.model.numberPlate;
    const b =
      (!!np[0] && !!np[1] && !!np[2] && !!np[3]) !==
      (!!np[0] || !!np[1] || !!np[2] || !!np[3]);

    comp.message1 = b && !np[0] ? ['1'] : [];
    comp.message2 = b && !np[1] ? ['2'] : [];
    comp.message3 = b && !np[2] ? ['3'] : [];
    comp.message4 = b && !np[3] ? ['4'] : [];
    comp.message = b ? [comp.label + 'を入力してください。'] : [];

    const msg = comp.valid;
    msg.pushProhibit(comp.message1, np[0], '陸運支局');
    msg.pushFullWidth(comp.message1, np[0], '陸運支局');
    msg.pushAlpaNumeric(comp.message2, np[1], '車種分類番号');
    if (np[1] && np[1].startsWith('0'))
      comp.message2.push('この車種分類番号は使用できません。');
    msg.pushNumeric(comp.message4, np[3], '標識番号');
    if (np[3] && np[3].startsWith('0'))
      comp.message4.push('この標識番号は使用できません。');

    if (!comp.message.length) {
      if (comp.message1.length)
        comp.message.push(...comp.message1);
      if (comp.message2.length)
        comp.message.push(...comp.message2);
      if (comp.message4.length)
        comp.message.push(...comp.message4);
    }

    return !comp.message || !comp.message.length;
  }

}
