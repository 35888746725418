import { Component, OnInit } from '@angular/core';
import { ApiService, NavigateService, StorageService } from '../../services';
import { NoticeInfoService, NoticeInfo } from '../../services/notice-info.service';


@Component({
  selector: 'app-info-list',
  templateUrl: './list.component.html',
})
export class ListComponent implements OnInit {

  // お知らせ表示最大件数
  readonly NOTICE_MAX_COUNT = 20;

  noticeInfoList: NoticeInfo[] = [];
  allNoticeInfoList: NoticeInfo[] = [];

  pageModel = {
    pages: 0,
    countPerPage: this.NOTICE_MAX_COUNT,
    page: 1,
  }

  constructor(
    public api: ApiService,
    public navi: NavigateService,
    private noticeService: NoticeInfoService,
    private storageService : StorageService,
  ) { }

  ngOnInit() {
    // お知らせ情報取得
    this.allNoticeInfoList = this.storageService.load(NoticeInfoService.LOCAL_STORAGE_KEY_NORMAL_NOTICEINFO, this.allNoticeInfoList);
    this.pageModel.page = 1;
    if (this.allNoticeInfoList && this.allNoticeInfoList.length > 0) {
      this.pageModel.pages = Math.ceil(this.allNoticeInfoList.length / this.pageModel.countPerPage);
      this.setPageData();
    }
  }

  /**
   * 各お知らせタイトル押下時イベント
   * @param id お知らせNo
   */
  onDetail = (id: number) => {
   // 選択されたお知らせをローカルストレージへ保存
    this.storageService.save(NoticeInfoService.LOCAL_STORAGE_KEY_NOTICEINFO , this.noticeInfoList[id]);
    window.open(this.noticeService.getNoticeDetailPath(), '_blank');
  }

  /**
   * ページクリック時イベント
   * @param $event 
   */
  onPage($event) {
    this.pageModel.page = $event;
    this.setPageData();
  }

  /**
   * 表示データ設定
   */
  private setPageData() {
    // 表示開始位置・終了位置の計算
    const startPos = (this.pageModel.page - 1) * this.pageModel.countPerPage;
    let endPos = startPos + this.pageModel.countPerPage - 1;
    // 終了位置調整
    if (this.allNoticeInfoList.length - 1 < endPos) {
      endPos = this.allNoticeInfoList.length - 1;
    }
    // 表示データ設定
    this.noticeInfoList = [];
    if (this.allNoticeInfoList.length >= startPos) {
      for (let i = startPos; i <= endPos; i++) {
        this.noticeInfoList.push(this.allNoticeInfoList[i]);
      }
    }
   }

}

