import { Component, Input } from '@angular/core';
import { Passcard } from '../../passcard.model';
import { PasscardStatusText, PasscardStatus } from '../../passcard.model';

@Component({
  selector: 'passcard-info',
  templateUrl: './passcard-info.component.html'
})
export class PasscardInfoComponent {
  @Input() model: Passcard = { status: 0, name: '', no: 0, unitPrice: 0 };
  @Input() showStatus = true;

  PasscardStatus = PasscardStatus;

  get StatusText() {
    return PasscardStatusText[this.model.status];
  }

  getStausTextExtra() {
    if (this.model.waitNum > 0) {
      return `現在${this.model.waitNum}人待ちです`;
    }

    return '';
  }

  getStatusCls(status: PasscardStatus) {
    switch (status) {
      case PasscardStatus.StopApply:
      case PasscardStatus.NoVacancies:
        return 'status-stop';
      case PasscardStatus.WaitingVacancies:
        return 'status-busy';
      case PasscardStatus.Available:
        return 'status-idle';
    }
  }
}
