import { Component } from '@angular/core';
import { deliveryInputToDelivery, getDefaultDelivery, myPageToDeliveryInput } from '../../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { NavigateService } from 'src/app/services';
import { DeliveryEditService } from '../delivery-edit.service';
import { DeliveryInputModel } from 'src/app/passcard/common/delivery-input/delivery-input.component';

@Component({
  selector: 'edit-delivery',
  templateUrl: './edit-delivery.component.html'
})
export class EditDeliveryComponent {
  deliveryInfo: DeliveryInputModel;

  constructor(
    public app: AppModel,
    private _navi: NavigateService,
    private _cacheService: DeliveryEditService) {
      const temp = myPageToDeliveryInput(_cacheService.myPage);
      this.deliveryInfo = {
        kind: _cacheService.deliveryInputKind,
        myPage: deliveryInputToDelivery(temp),
        deliveryInput: _cacheService.inputedDelivery ?? getDefaultDelivery()
      }
  }

  get IsComplete() {
    return this._cacheService.complete;
  }

  onBack() {
    this._navi.navigate(`/passcard/applyInfo`, false, { reservation: this._cacheService.passcardApply });
  }

  onNext() {
    if (this.deliveryInfo.kind == 1 && this.deliveryInfo.error) {
      return;
    }

    this._cacheService.deliveryInputKind = this.deliveryInfo.kind;
    if (this.deliveryInfo.kind == 1) {
      this._cacheService.inputedDelivery = this.deliveryInfo.deliveryInput;
      this._cacheService.delivery = deliveryInputToDelivery(this._cacheService.inputedDelivery);
    }
    else {
      this._cacheService.delivery = { ...this.deliveryInfo.myPage };
    }

    this._cacheService.maxProcessed = Math.max(this._cacheService.maxProcessed, 1);
    this._navi.navigateByUrl('/passcard/delivery-edit/step2')
  }
}
