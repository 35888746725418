import { Component, Input, OnInit } from '@angular/core';
import { NoticeInfoService, NoticeInfo } from '../../../services/notice-info.service';
import { ApiService, NavigateService, StorageService } from '../../../services';

@Component({
  selector: 'notice-info',
  templateUrl: './notice-info.component.html'
})
export class NoticeInfoComponent implements OnInit {
  @Input() parkingNoticeInformation: NoticeInfo[] = [];

  dispNoticeInfo: NoticeInfo[] = []

  isCollapsed = true;

  // お知らせ表示最大件数
  readonly NOTICE_MAX_COUNT = 3;

  constructor(
    public api: ApiService,
    private noticeService: NoticeInfoService,
    private navi: NavigateService,
    private storageService: StorageService
  ) {
  }

  ngOnInit(): void {
    // ストレージのお知らせを消去
    this.storageService.remove(NoticeInfoService.LOCAL_STORAGE_KEY_NORMAL_NOTICEINFO);
    if (this.parkingNoticeInformation) {
      if (this.parkingNoticeInformation.length > this.NOTICE_MAX_COUNT) {
        this.dispNoticeInfo = [...this.parkingNoticeInformation];
        this.dispNoticeInfo.splice(this.NOTICE_MAX_COUNT, this.dispNoticeInfo.length - this.NOTICE_MAX_COUNT);
      } else {
        this.dispNoticeInfo = [...this.parkingNoticeInformation];
      }
      // ストレージへ保存
      this.storageService.save(NoticeInfoService.LOCAL_STORAGE_KEY_NORMAL_NOTICEINFO, this.parkingNoticeInformation);
    } else {
      this.dispNoticeInfo = [];
    }
  }

  onClick() {
    this.isCollapsed = !this.isCollapsed;
  }

  onNoticeListClick() {
    this.navi.navigateByUrl('/notice-info/list');
  }

  getParkingNoticeInfoList() {
    return this.dispNoticeInfo;
  }

  /**
 * 各お知らせタイトル押下時イベント
 * @param id お知らせNo
 */
  onDetail = (id: number) => {
    // 選択されたお知らせをローカルストレージへ保存
    this.storageService.save(NoticeInfoService.LOCAL_STORAGE_KEY_NOTICEINFO, this.parkingNoticeInformation[id]);
    window.open(this.noticeService.getNoticeDetailPath(), '_blank');
  }
}
