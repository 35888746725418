import { Component, OnInit } from '@angular/core';

import { ApiService, ConfigService, NavigateService, creditExpDate, expired2array } from '../../../services';

import { CreditModel } from '../credit.model';
import { MypageModel } from '../../mypage.model';

//
interface Req {
  dcReserveCompanyNo: number;
  dcMemberCompanyNo: number;
  dcBusinessAreaNo: number;
  transactionId: string;
  maskCreditNo: string;
  dtCreditExpirationDate: string;
  etcCardNo: string;
  etcExpirationYear: number;
  etcExpirationMonth: number;
  creditAccountId: string;
  creditCardId: string;
  mdkToken: string;
  dcParkingSpaceNoList: number[];
  memberCategoryType: number;
}

interface Res {
  resultCode: string;
  mainMessage: string;
}

//
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {
  error: string;

  constructor(
    public model: CreditModel,
    public mypage: MypageModel,
    public api: ApiService,
    public conf: ConfigService,
    public navi: NavigateService
  ) { }

  ngOnInit() { }

  //
  private afterPut = (body: Res) => {
    if (body.resultCode === '0') {
      this.model.apply();
      this.navi.navigateByUrl('/mypage/credit/complete');
    } else {
      this.api.enabled();
      this.error = body.mainMessage;
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const c = this.conf;
    const m = this.model;
    const exp = expired2array(m.etccard.expire);

    const req: Req = {
      dcReserveCompanyNo: c.reserveCompany,
      dcMemberCompanyNo: c.memberCompany,
      dcBusinessAreaNo: c.businessArea,

      maskCreditNo: m.veritrans.reqCardNumber,
      dtCreditExpirationDate: creditExpDate(m.veritrans.cardExpire),
      mdkToken: m.veritrans.cardToken,

      etcCardNo: m.etccard.number,
      etcExpirationYear: exp[1],
      etcExpirationMonth: exp[0],

      transactionId: m.credit.transaction,
      creditAccountId: m.credit.account,
      creditCardId: m.credit.card,

      dcParkingSpaceNoList: c.spaces,
      memberCategoryType: c.memberCategory
    };

    this.api.put<Res>('/creditCard/update', req, 2).subscribe(
      res => this.afterPut(res.body)
    );
  }

}
