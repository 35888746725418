import { Routes } from '@angular/router';

import { DetailComponent } from './detail/detail.component';
import { ListComponent } from './list/list.component';

//
export const routes: Routes = [{
  path: 'detail/:id',
  component: DetailComponent
}, {
  path: 'list',
  component: ListComponent
}];

//
export const components = [
  DetailComponent,
  ListComponent,
];
