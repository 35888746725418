import { Component, OnInit } from '@angular/core';
import { NavigateService } from '../../../services/navigate.service';

declare var LOOPBACK: boolean;

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
})
export class SendComponent implements OnInit {

  constructor(private navi: NavigateService) { }

  ngOnInit() { }

  onDblclick = () => {
    if (LOOPBACK)
      this.navi.navigateByUrl('/mypage/mail/complete/token/1536307335486');
  }
}
