import { Injectable } from '@angular/core';
import { StorageService } from '../services';

//
@Injectable({
  providedIn: 'root'
})
export class RegistrationModel {
  // お客様情報
  fullname: string[];
  kana: string[];
  birthdate: string[];
  mailAddr: string;
  phone: string;
  securityNumber: string;
  // 住所
  postcode: string[];
  address1: string;
  address2: string;
  // 勤務先/通学先
  wsName: string;
  wsKana: string;
  wsPhone: string;
  wsPostcode: string[];
  wsAddress1: string; 
  wsAddress2: string; 
  // 車両情報（クルマ）
  numberPlate: string[];
//  municipalities: string;
//  kanakigo: string;
//  registrationNumber: string;
  // 車両情報（バイク）
  motorcycleNumberPlate: string;
  // パスワード
  passwd: string;
  // 表示非表示設定（0:非表示、1:表示、2:表示必須入力）
  byUserName : string;
  byUserFurigana : string;
  byUserBirth : string;
  byUserMail : string;
  byUserTel : string;
  bySecurityNumber : string;
  byUserZip : string;
  byUserAddress1 : string;
  byUserAddress2 : string;
  byCompanyName : string;
  byCompanyFurigana : string;
  byCompanyTel : string;
  byCompanyZip : string;
  byCompanyAddress1 : string;
  byCompanyAddress2 : string;
  byCarInfo : string;
  byApplyMotorcycleNumber : string;
  // メール
  mail?: {
    token: string;
    date: string;
  };
  // 駐車場コード
  parkingCode : number;
  // 遷移元パス
  transitionSourceURL : string;
  // 
  regist : boolean;
  // 登録完了フラグ
  completed : boolean;
  // 利用規約URL
  termsServiceURL: string;


  //
  constructor(
    private _storage: StorageService
  ) {
    //super();
    this.regist = true;
    this.loadStorage();
  }

  //
  reset = () => {
    // お客様情報
    this.fullname = ['', ''];
    this.kana = ['', ''];
    this.birthdate = ['2000', '', ''];
    this.mailAddr = '';
    this.phone = '';
    this.securityNumber = '';
    // 住所
    this.postcode = ['', ''];
    this.address1 = '';
    this.address2 = '';
    // 勤務先/通学先
    this.wsName = '';
    this.wsKana = '';
    this.wsPostcode = ['', ''];
    this.wsAddress1 = '';
    this.wsAddress2 = '';
    this.wsPhone = '';
    // 車両情報（クルマ）
    this.numberPlate = ['', '', '', ''];
    // 車両情報（バイク）
    this.motorcycleNumberPlate = '';
    // パスワード
    this.passwd = '';
    // 表示非表示フラグ（0:非表示、1:表示、2:表示必須入力）
    this.byUserName = '2';
    this.byUserFurigana = '2';
    this.byUserBirth = '2';
    this.byUserMail = '2';
    this.byUserTel = '2';
    this.bySecurityNumber = '1';
    this.byUserZip = '2';
    this.byUserAddress1 = '2';
    this.byUserAddress2 = '2';
    this.byCompanyName = '2';
    this.byCompanyFurigana = '2';
    this.byCompanyTel = '2';
    this.byCompanyZip = '2';
    this.byCompanyAddress1 = '2';
    this.byCompanyAddress2 = '2';
    this.byCarInfo = '1';
    this.byApplyMotorcycleNumber = '1';
    // メール
    this.mail = {
      token: '',
      date: ''
    };
    // 駐車場コード
    this.parkingCode = null;
    // 遷移元パス
    this.transitionSourceURL = '';
    //
    this.regist = true;
    // 登録完了フラグ
    this.completed = false;
    // 利用規約URL
    this.termsServiceURL = '';
    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('registration', this);
  }

  loadStorage = () => {
    this._storage.load('registration', this);
  }
  
}