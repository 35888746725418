import { Component } from '@angular/core';
import { deliveryInputToDelivery, getDefaultDelivery, myPageToDeliveryInput } from '../../../passcard.model';
import { NavigateService } from 'src/app/services';
import { LostService } from '../../lost.service';
import { DeliveryInputModel } from 'src/app/passcard/common/delivery-input/delivery-input.component';

@Component({
  selector: 'lost02',
  templateUrl: './lost02.component.html'
})
export class Lost02Component {
  deliveryInfo: DeliveryInputModel;

  constructor(private _navi: NavigateService,
    private _lostService: LostService) {
    const deliveryInput = myPageToDeliveryInput(this._lostService.myPage);
    this.deliveryInfo = {
      kind: _lostService.deliveryInputKind,
      myPage: deliveryInputToDelivery(deliveryInput),
      deliveryInput: _lostService.inputedDelivery ?? getDefaultDelivery()
    }
  }

  get IsComplete() {
    return this._lostService.complete;
  }

  onBack() {
    this._navi.navigateByUrl('/passcard/lost-handler/step1');
  }

  onNext() {
    if (this.deliveryInfo.kind == 1 && this.deliveryInfo.error) {
      return;
    }

    this._lostService.inputedDelivery = this.deliveryInfo.deliveryInput;
    this._lostService.deliveryInputKind = this.deliveryInfo.kind;
    if (this.deliveryInfo.kind == 0) {
      this._lostService.delivery = { ...this.deliveryInfo.myPage };
    }
    else {
      this._lostService.delivery = deliveryInputToDelivery(this.deliveryInfo.deliveryInput);
    }

    this._lostService.maxProcessed = Math.max(this._lostService.maxProcessed, 1);
    this._navi.navigateByUrl('/passcard/lost-handler/step3');
  }

}
