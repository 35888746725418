import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService , StorageService} from '../../services';
import { PasswdReissueModel } from '../passwd-reissue.model';

import { IEMailComponent } from '../../shared/i-email/i-email.component';

//
interface Req {
  parkingCode: number;
  mailAddress: string;
  agreementStatus: number;
}

interface Res {
  resultCode: number;
}

//
@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
})
export class MailComponent implements OnInit, DoCheck {
  @ViewChild(IEMailComponent, { static: false }) email: IEMailComponent;

  // for Server
  error: string;

  //
  disabled: boolean;

  //
  constructor(
    public model: PasswdReissueModel,
    public api: ApiService,
    private conf: ConfigService,
    private navi: NavigateService,
    private _storage: StorageService,
  ) { }

  ngOnInit() {
    this.disabled = true;
    this.model.reset();
  }

  ngDoCheck() {
    if (this.email)
      this.disabled = !this.email.validation();
  }

  private afterPost = (body: Res) => {
    // this.api.enabled();
    if (body.resultCode === 0) {
      this.navi.navigateByUrl('/passwd-reissue/send');
    } else {
      this.api.enabled();
      this.navi.navigateByUrl('/error', true);
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;
    const c = this.conf;

    // 利用規約同意の不具合対応
    let agreementStatus = '0';
    if (this._storage._load('agreementStatus') != null
     && this._storage._load('agreementStatus') != undefined) {
      agreementStatus = this._storage._load('agreementStatus');
    }

    const req: Req = {
      parkingCode: Number(this._storage._load('parkingCode')),
      mailAddress: this.model.email,
      agreementStatus: Number(agreementStatus)
    };

    this.api.post<Res>('/support/reissue/sendMail', req, 1).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
