<div *ngIf="loading" class="error-textbox">
    <p class="err-txt-box">問合せ中...</p>
</div>
<h2>{{app.ParkingPlace.name}}</h2>
<div #contents></div>
<br>
<br>
<ng-container *ngIf="!loading">
    <section class="checkWrapper">
        <label [ngClass]="{'checkBoxArea':isAgree,'checkBoxAreaNot':!isAgree}">
            <input [(ngModel)]="isAgree" type="checkbox" class="unsubscribeConfirm_check">
            <div class="checkboxMeg">利用規約に同意する</div>
        </label>
    </section>
    <br>
    <div class="buttonBox">
        <loading-button (apply)="onApplyStart()"
            [disabled]="!isAgree" [loading]="false"
            [label]="'定期申し込みを開始する'" [class]="'ui primary button cmn-btn button-box-color-b-50-c'"
            [lClass]="'button-box-loading'">
        </loading-button>
    </div>
</ng-container>