<h2>定期紛失</h2>
<flow-box [steps]="['定期確認', '配送情報', '確認', '完了']" [processing]="1" [processed]="1" />
<delivery-input [model]="deliveryInfo"></delivery-input>
<br>
<div *ngIf="!IsComplete" class="buttonBoxVertical">
  <button type="button" (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onNext()"
    [disabled]="deliveryInfo.kind == 1 && deliveryInfo.error"
    [loading]="false"
    [label]="'次へ'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>
