import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

import { OPTION } from '../services/api.service';

//
declare var dcParkingSpaceNo: number[];

//
function generate_days(index: number) {
  const b = !(index % 2);
  const days = [];

  const dt = new Date();
  dt.setUTCDate(1);

  dt.setUTCHours(-9, 0, 0, 0);
  console.log(dt, dt.getTime());

  for (let i = 0; i < 500; i++) {
    const d = dt.getDate();

    let level = 2;
    // if ([1, 2, 8, 9, 28].includes(d))
    if ([1, 2, 8, 9, 28].includes(d))
      level = b ? 1 : 0;
    // if ([5, 6, 17, 19].includes(d))
    if ([17, 19].includes(d))
      level = b ? 0 : 1;

    if (![25, 26].includes(d))
      days.push({
        reserveDate: dt.getTime(),
        freeCount: 0,
        crowdCode: `${level}`
      });

    dt.setTime(dt.getTime() + 24 * 60 * 60 * 1000);
  }

  return days;
}

// generate capacity
function get_capacity(req: { dcParkingSpaceNo: number }) {
  const days = generate_days(dcParkingSpaceNo.indexOf(req.dcParkingSpaceNo));
  const day = days[days.length - 1].reserveDate - (1000 * 60 * 60 * 24 * 5);

  const res = {
    body: {
      mainMessage: null,
      reservationCapacityInfoList: days,
      reserveMinDays: 0,
      reserveMaxDays: 5,
      selectStartDate: day,
      reserveBasic: 'OUT'
    }
  };
  console.log(res);

  return res;
}

//
function set_time(res) {
  const dt = new Date();
  const t = dt.setHours(9, 30, 0, 0);
  const t2 = dt.setHours(10, 30, 0, 0);

  const d1 = 24 * 60 * 60 * 1000;

  res.body.reserveInfoList.forEach((info, i) => {
    info.reserveFrom = t + (i + 1) * d1;
    info.reserveTo = t2 + (i + 1) * d1;
  });

  return res;
}

//
function get_file<T>(http: HttpClient, method: string, url: string, opt: OPTION) {
  let subj = new Subject<HttpResponse<T>>();
  url = './assets/api' + url + '.' + method + '.json' + '?t=' + new Date().getTime();

  http.request<T>('GET', url, opt)
    .subscribe(
      res => subj.next(res),
      err => subj.error(err)
    );

  return subj;
}

function get_current<T>(http: HttpClient, method: string, url: string, opt) {
  let subj = new Subject<HttpResponse<T>>();

  get_file<T>(http, method, url, opt).subscribe(
    res => {
      setTimeout(() => {
        subj.next(set_time(res))
      }, 400);
    },
    err => subj.error(err)
  );

  return subj;
}

function get_inquiry<T>(http: HttpClient, method: string, url: string, opt) {
  let subj = new Subject<HttpResponse<T>>();

  get_file<T>(http, method, url, opt).subscribe(
    res => subj.next(set_time(res)),
    err => subj.error(err)
  );

  return subj;
}

//
export function get_subject<T>(http: HttpClient, method: string, url: string, req, opt): Subject<HttpResponse<T>> {
  if (url === '/calendar/capacity') {
    let subj = new Subject<HttpResponse<T>>();
    setTimeout(() => {
      subj.next(get_capacity(req) as any);
    }, 400);
    return subj;
  }
  else if (url === '/reserve/list/current') {
    return get_current<T>(http, method, url, opt);
  }
  else if (url === '/reserve/list/inquiryNoSearch') {
    return get_inquiry<T>(http, method, url, opt);
  }

  return null;
}
