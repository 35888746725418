import { Component, Input } from '@angular/core';
import { ValidateService } from '../../services';

@Component({
  selector: 'i-ws-kana',
  templateUrl: './i-ws-kana.component.html',
})
export class IWsKanaComponent {
  @Input() model: { byCompanyName: string, wsKana: string };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() label: string;

  message: string[];

  //
  constructor(
    public valid: ValidateService
  ) { }

  ngOnInit() {
  }

  //
  validation = () => {
    const msg = this.valid;
    this.message = [];
    if (this.model.byCompanyName === '2') {
      // required ?
      const fn = msg.validRequired;
      this.message = fn(this.model.wsKana, this.label);
    }
    msg.pushWorkspaceNameKana(this.message, this.model.wsKana, this.label);
    return this.message && !this.message.length;
  }

  onChange = () => this.messages = null;

  }