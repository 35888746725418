<div class="tips {{status != 0 ? 'clickable':''}}" [ngClass]="TipClass">
  <div><img title="" alt="" src="{{ImgUrl}}"></div>
  <div>
    <ng-container [ngSwitch]="status">
      <ng-template [ngSwitchCase]="4">
        <div>順番待ちです。</div>
      </ng-template>
      <ng-template ngSwitchDefault>
        <div [ngClass]="MainMsgClass">
          <div *ngFor="let mainMsg of MainMessage">{{mainMsg}}</div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngSwitch]="status">
      <ng-template [ngSwitchCase]="3">
        <div class="sub-tip">
          <p>{{SubMessage[0]}}</p>
          <p *ngIf="endDate"><span><b>{{endDate | date: 'yyyy/MM/dd'}}まで</b></span>にお手続きください。</p>
        </div>
      </ng-template>
      <ng-template ngSwitchDefault>
        <div class="sub-tip" *ngIf="SubMessage.length">
          <p *ngFor="let subMsg of SubMessage">{{subMsg}}</p>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div *ngIf="status != 0"><span><b>></b></span></div>
</div>