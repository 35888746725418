<section class="terms">
  <div class="scrollbox">
    <p class="rules-title-b">&#9632;〇〇〇〇〇駐車場　ETC利用規約</p>
    <p class="rules_day">（令和元年〇月〇日　規程第〇号）</p>
    <p class="rules-title-top">（本規程の趣旨）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第1条</td>
          <td>
            〇〇〇〇〇駐車場　ETC利用規約（以下「本規程」といいます。）は、〇〇〇〇〇株式会社（以下「会社」といいます。）が駐車場優先予約サービス利用者（以下「利用者」といいます。）に対して、有料道路上の決済に限られているETCシステムの民間サービスへの適用を目的に、駐車場での入出庫及び決済をネットワーク型ETC技術の試行運用として利用した駐車場優先予約サービス（以下「本サービス」といいます。）の利用等について基本的事項を規定したものです。
          </td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（用語定義等）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第2条</td>
          <td>
            「ネットワーク型ETC技術」とは、遠隔地に設置したセキュリティ機能を有した情報処理機器と、駐車場等における複数の路側機を通信ネットワークで接続し、路側機で取得した情報を集約させて一括処理することで、ETCカードを用いた決済の安全性を確保する技術をいいます。
          </td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">2</td>
          <td>「ETCシステム」とは、道路整備特別措置法（昭和３１年法律第７号。以下「特措法」といいます。）第２条第５項に規定する料金の徴収を自動化するための機器及びこれを作動させるシステムの集合体をいいます。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">3</td>
          <td>「車載器」とは、特措法第２４条第１項本文の自動車又は車両に搭載して無線の交信により道路を通行したことを記録するための装置をいいます。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">4</td>
          <td>「利用者」とは、次の各号の要件をすべて満たす者をいいます。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(1)</td>
          <td>会社が認めたクレジットカード会社の発行するクレジットカード、及びこれに紐づくETCカードを所持すること。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(2)</td>
          <td>クレジットカード会社から本サービス利用者として選考された個人であること。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(3)</td>
          <td>本規程に同意の上、第４条に定める事項を登録していること。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(4)</td>
          <td>本サービスの利用期間中に一号に規定するETCカードを使用できること。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(5)</td>
          <td>ETCカードをETC車載器又はETC2.0車載器に正しく挿入し、車載器メーカーが別途指定する方法により適正に取り付けられた車両で使用すること。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(6)</td>
          <td>その他、会社が本サービスの運営に支障があると認める事由がないこと。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">5</td>
          <td>「駐車場予約サイト」（以下「本サイト」といいます。）とは、ETCカードの登録を行うための本サービス専用のホームページのことをいいます。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（運用期間）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第3条</td>
          <td>本サービスの運用期間は、本サイトにおいて告知するものとします。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（会員登録）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第4条</td>
          <td>利用者は本サービス利用のため、「テスト駐車場予約サービス利用規約」で定める申込の他に本サービスで使用する第2条４項第１号に定めるETCカード番号を登録していただきます。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（ETCカードの利用方法）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第5条</td>
          <td>利用者は本サイトで予約を行い、予約入場する場合、ETCカードを挿入した車載器を介してETCカード番号を読取り、駐車券の発行なしで入場可能となります。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">2</td>
          <td>
            予約入場後に出場する場合、ETCカードを挿入した車載器を介してETCカード番号を読取り、この情報を元に入庫からの駐車料金を算出し、登録したETCカードに紐づくクレジットカードで料金の支払いを行う事ができるものとします。
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（ETCカードの利用代金支払い方法）</p>

    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第6条</td>
          <td>登録したクレジットカードの利用代金として、クレジットカード会社より請求されます。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（サービス内容及び利用上の注意事項）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第7条</td>
          <td>本サービスは、〇〇〇〇〇駐車場での利用のみを対象とします。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">2</td>
          <td>利用者が本サービスを利用する場合は、必ず車載器に第４条の定めにより登録したETCカードを挿入し、車載器の動作に異常のないことをあらかじめ確認しなければなりません。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">3</td>
          <td>通信エラー又は機器障害・機器メンテナンスによりETCシステムが利用できない場合、本サービスは適用されません。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">4</td>
          <td>本サービスを利用する場合は、駐車場の入出場ゲートの手前で、一旦停止のうえ利用することとします。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">5</td>
          <td>その他、利用者は本サイト等により告知される本サービス利用方法、並びにテスト駐車場予約サービス利用規約を遵守するものとします。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">6</td>
          <td>インターネットにより提供されているETC利用照会サービス、及びETC利用履歴発行プリンターでは、本サービスの利用履歴は確認できません。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（本サービスの利用停止）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第8条</td>
          <td>会社は、試行運用期間中に次の各号のいずれかの事象を確認した場合、不正利用とみなし利用者の本サービスの利用を停止します。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(1)</td>
          <td>利用者から本サービスに登録したETCカードを利用者以外の第三者に譲渡し、サービスを利用した場合。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(2)</td>
          <td>有効でないETCカード番号を登録していることが確認された場合。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(3)</td>
          <td>ETCを利用して入庫後、出庫せず別の車両を使用しETCで入庫した場合。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（利用に関する疑義への対応）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第9条</td>
          <td>会社は、本サービス参加申込時のETCカード番号の記載誤りなどにより本サービスが正しく利用できないと認めたときは、利用者に代わり、又は利用者と共同で、ETCカード番号の調査及び確認をすることがあります。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">2</td>
          <td>利用者は、前項に定める措置に対し、会社からの問合せ等に対し回答する必要があります。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（本サービス提供の終了等）</p>

    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第10条</td>
          <td>会社は、次の各号のいずれかの事由があるときは、利用者に事前に通知することなく、本サービスの提供を停止又は終了することがあります。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(1)</td>
          <td>本サービスの提供のための施設及びETCシステムに係る保守点検又は更新作業を定期的又は緊急に行うとき。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(2)</td>
          <td>天災地変その他の不可抗力、停電及びシステム障害等により、本サービスの提供が困難であるとき。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-03">
      <tbody>
        <tr>
          <td class="number3">(3)</td>
          <td>前二号に掲げるほか、会社が本サービスの提供の停止又は終了が必要と判断したとき。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">2</td>
          <td>前項に定める場合のほか、会社は、技術上又は営業上の判断により、本サービスの提供を停止もしくは終了又は変更することができます。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">3</td>
          <td>前２項に基づく措置が採られたことにより、利用者又は第三者に何らかの損害又は不利益が生じた場合であっても、会社は一切の責任を負いません。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（委託）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第11条</td>
          <td>利用者は、当社が本サービスの運営の一部について、会社との契約関係にある業者に対して委託することがあります。</td>
        </tr>
      </tbody>
    </table>

    <p class="rules-title-top">（規程の変更）</p>
    <table class="rules-tab-01">
      <tbody>
        <tr>
          <td class="number1">第12条</td>
          <td>会社は、本規程の内容を予告なしに、また、利用者から事前に同意を得ることなく、変更する場合があります。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">2</td>
          <td>前項の変更を行った場合、会社は、変更の内容を利用者に通知します。</td>
        </tr>
      </tbody>
    </table>

    <table class="rules-tab-02">
      <tbody>
        <tr>
          <td class="number2">3</td>
          <td>会社が本規程の変更を通知した後に、利用者が本サービスを利用したときは、本規程の変更に同意したものとします。</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>