import { Component, OnInit } from '@angular/core';

import { ApiService, ConfigService, NavigateService, StorageService } from '../../services';
import { AppModel } from 'src/app/app.model';

//
interface Req {
}

interface Res {
  resultCode: number;
  mainMessage: string;
  withdrawalPossibleFlg: boolean;
}

//
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {
  consent = false;

  constructor(
    private app: AppModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
    private storage: StorageService,
  ) { }

  ngOnInit() { }

  //
  private afterPost = (body: Res) => {
    // this.api.enabled();
    if (body.resultCode === 0) {
      if (body.withdrawalPossibleFlg) {
        this.app.login = false;
        this.storage.logout();
        this.navi.navigateByUrl('/unsubscribe/complete');
      } else
      this.navi.navigateByUrl('/unsubscribe/rejected');
    } else {
      this.api.enabled();
      this.navi.navigateByUrl('/error', true);
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;
    const c = this.conf;

    const req: Req = {
    };

    this.api.put<Res>('/withdrawal', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
