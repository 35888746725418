<div class="tableLine">
  <div class="tableCell_l">{{label}}</div>
  <div class="tableCell_r">
    <div *ngIf="value[0]">
      <p class="confirmText">{{value[0]|registif}}-{{value[1]|registif}}</p>
    </div>
    <div *ngIf="!value[0]">
      <p class="confirmText">{{value[0]|registif}}</p>
    </div>

  </div>
</div>