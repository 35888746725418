import { Component, OnInit, Input } from '@angular/core';

import { EtcCardService, ValidateService } from '../../../services';
import { option_numbers } from '../../../shared/utils';

import { PaymentModel } from '../veritrans.interface';

import { Date9 } from '../../time9';

//
@Component({
  selector: 'reserve-etccard',
  templateUrl: './etc-card.component.html',
})
export class EtcCardComponent implements OnInit {
  @Input() model: PaymentModel;
  @Input() messages: { number: string[]; expired: string[] }

  option_y = [];
  option_m = [];

  // ETC Card
  etcConfirm: boolean;
  etcNumber: string;
  etcExpires: string[];

  // ETC Card
  msgEtcCard: string[] = [];
  msgEtcMonth: string[] = [];
  msgEtcYear: string[] = [];
  msgEtcExpired: string[] = [];

  //
  constructor(
    private etccard: EtcCardService,
    public valid: ValidateService,
  ) {
    const dt = Date9();
    const year = dt.year() - 2000;
    this.option_y = option_numbers(year, year + 10);
    this.option_m = option_numbers(1, 12);
  }

  //
  ngOnInit() {
    this.model2Control();
  }

  onChange1() {
    this.messages.number = null;
  }

  onChange2() {
    this.messages.expired = null;
  }

  //
  private model2Control = () => {
    const e = this.model.etccard;

    // ETC Card
    this.etcNumber = e.number;
    this.etcExpires = e.expire ? e.expire.split('/') : ['', ''];
  }

  private control2Model = () => {
    const e = this.model.etccard;

    e.number = this.etcNumber;
    e.expire = this.etcExpires.join('/');
  }

  //
  validation = () => {
    this.control2Model();
    this.etccard.validations(this);
    return !this.msgEtcCard.length && !this.msgEtcExpired.length;
  }
}
