import { Injectable } from '@angular/core';
import { ValidateService } from './validate.service';

import { Date9 } from '../shared/time9';

//
interface Component {
  valid: ValidateService,

  //
  etcConfirm: boolean;
  etcNumber: string;
  etcExpires: string[];

  //
  msgEtcCard: string[];
  msgEtcMonth: string[];
  msgEtcYear: string[];
  msgEtcExpired: string[];
}

//
@Injectable({
  providedIn: 'root'
})
export class EtcCardService {

  constructor() { }

  validations = (comp: Component) => {
    const valid = comp.valid;
    const fn = valid.validRequired;

    // required ?
    comp.msgEtcCard = fn(comp.etcNumber, 'ETCカード番号');
    valid.pushETCCard(comp.msgEtcCard, comp.etcNumber, 'ETCカード番号');

    comp.msgEtcMonth = fn(comp.etcExpires[0], 'month');
    comp.msgEtcYear = fn(comp.etcExpires[1], 'year');

    comp.msgEtcExpired = comp.msgEtcMonth.length ||
      comp.msgEtcYear.length ? ['有効期限を入力してください。'] : [];

    if (!comp.msgEtcExpired.length && isExpired(+comp.etcExpires[0], +comp.etcExpires[1]))
      comp.msgEtcExpired.push('有効期限が過ぎています');
  }
}

function isExpired(month: number, year: number) {
  const dt1 = Date9();
  const dt2 = Date9();
  dt2.set({ year: 2000 + year, month: month + 1, date: 1 });
  return dt2.valueOf() - dt1.valueOf() < 0;
}

// 01/20 -> [1, 20]
export function expired2array(expired: string) {
  return expired ? expired.split('/').map(s => +s) : [undefined, undefined];
}
