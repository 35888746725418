<h2>定期情報</h2>
<ng-container *ngIf="IsRecepting">
  <status-tip [status]="1" (click)="onStatusTipClick()" />
  <br>
</ng-container>
<contract-detail [contract]="contract">
  <div class="buttons" *ngIf="ShowButtons">
    <div class="large-button" (click)="onReissue('lost')">
      <label title="紛失">
        <div><img title="" alt="" src="/assets/img/lost.svg"></div>
        <div>紛失</div>
      </label>
    </div>
  </div>
  <br>
</contract-detail>
<br>
<notice-info [parkingNoticeInformation]="NoticeInfo"></notice-info>

