import { Component } from '@angular/core';
import { Delivery } from '../../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { LostService } from '../../lost.service';
import { NavigateService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'lost03',
  templateUrl: './lost03.component.html'
})
export class Lost03Component {
  delivery!: Delivery;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _lostService: LostService) {
    this.delivery = _lostService.delivery;
  }
  
  get Steps() {
    return this._lostService.applyCardDelivery == 0 ? ['定期確認', '確認', '完了']
      : ['定期確認', '配送情報', '確認', '完了'];
  }

  get CurrentProc() {
    return this._lostService.applyCardDelivery == 0 ? 1 : 2;
  }

  get ShowDelivery() {
    return this._lostService.applyCardDelivery != 0;
  }

  get IsComplete() {
    return this._lostService.complete;
  }

  get contract() {
    return this._lostService.passcardApply;
  }

  get Charge() {
    return this._lostService.reissueFee;
  }

  onReInput(kb: number) {
    this._navi.navigateByUrl(`/passcard/lost-handler/step${kb}`);
  }

  /**
 * 申請を行う
 */
  onApply() {
    this._lostService.maxProcessed = 2;
    const { no, passcardType, passcardExtended, passcardValidFrom } = this._lostService.passcardApply;
    // status:
    //  4：紛失の申請を行う場合
    const status = 4;
    const required = {
      passcardApplyNo: null,
      dcParkingNo: this._lostService.passcardApply.dcParkingNo,
      status,
      no,
      passcardType,
      extended: passcardExtended,
      applyCardDelivery: this._lostService.applyCardDelivery,
      passcardMonth: 0,
      passcardValidFrom
    };

    const delivery = this._lostService.applyCardDelivery == 0 ? {}
      : {
        deliveryType: this._lostService.deliveryInputKind,
        ...this._lostService.delivery
      };

    this.api.passcardApply({
      ...required,
      ...delivery

    }).then(res => {
      this._lostService.receiptNo = res;
      this._navi.navigateByUrl(`/passcard/lost-handler/complete`);
    });
  }
}
