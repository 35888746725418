//
export const url = {
  credit: {
    root: '/creditCard',
    no_member: '/creditCard/nonMember',
    update: '/creditCard/update',
    islocked: '/creditCard/inputLimit',
    lock: '/creditCard/inputLimitRegist',
  },
  etc_card: {
    root: '/etc_card',
    check: '/etc_card/check'
  }
};

//
export interface VeritransReq {
  token_api_key?: string;
  card_number: string;
  card_expire: string;
  security_code: string;
  lang?: string;
}

export interface VeritransRes {
  token: string;
  token_expire_date: string;
  req_card_number: string;
  status: string;
  code: string;
  message: string;
}

export interface VeritransErr {
  status: string;
  code: string;
  message: string;
}

//
export interface CreditReq {
  dcReserveCompanyNo: number;
  dcMemberCompanyNo: number;
  dcBusinessAreaNo: number;
  mdkToken: string;
  maskCreditNo: string;
  etcCardNo: string;
  etcExpirationYear: number;
  etcExpirationMonth: number;
  telephoneNumber: string;
  memberCategoryType: number;
}

export interface CreditNoMemberReq {
  dcReserveCompanyNo: number;
  mdkToken: string;
  telephoneNumber: string;
  memberRegistFlg: boolean;
}

export interface CreditRes {
  resultCode: string;
  mainMessage: string;
  nextMainMessage: string;
  transactionId: string;
  maskETCCardNo: string;
  creditAccountId: string;
  creditCardId: string;
  validatorMessageETCCardNo: string;
  validatorMessageETCCardExpirationDate: string;
  validatorMessageCreditCardNo: string;
}

// for Lock
export interface LockReq {
  dcMemberCompanyNo: number;
  mailSendToken: string;
  mailSendDate: string;
}

export interface LockRes {
  resultCode: string;
}

export interface IsLockedReq extends LockReq { }

export interface IsLockedRes {
  resultCode: string;
  creditInputCountLimit: number;
  creditInputInvalidTime: number;
  creditInputIntervalTime: number;
}

//
export class PaymentModel {
  veritrans: {
    _cardNumber: string;
    _securityCode: string;
    cardExpire: string;
    reqCardNumber: string;
    cardToken: string;
  };

  etccard: {
    number: string;
    expire: string;
    masked: string;
  };

  credit: {
    account: string;
    card: string;
    transaction: string;
  }

  phone: string;

  mail?: {
    token: string;
    date: string;
  };

  regist: boolean;

  //
  completed: boolean;

  //
  constructor() {
    this.reset();
  }

  //
  reset() {
    this.veritrans = {
      _cardNumber: '',
      cardExpire: '',
      _securityCode: '',
      reqCardNumber: '',
      cardToken: ''
    };

    this.etccard = {
      number: '',
      expire: '',
      masked: ''
    };

    this.credit = {
      account: '',
      card: '',
      transaction: ''
    };

    this.phone = '';
    this.mail = {
      token: '',
      date: ''
    };

    this.regist = false;

    this.completed = false;
  }
}
