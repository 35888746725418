<div class="heroInner">
  <div class="heroTitle-area">
    <div class="heroTitle"><br></div>
  </div>
  <div *ngIf="!app.login" class="heroBtn-area">
  <!--  
    <button (click)="onLogin()" class="cmn-btn">ログインする</button>
  -->
  </div>
</div>

