import { Component, Input } from '@angular/core';

@Component({
  selector: 'complete',
  templateUrl: './complete.component.html'
})
export class CompleteComponent {
  @Input() message1: string = '';
  @Input() message2: string = '';
}
