import { Component, OnInit, Input } from '@angular/core';

import { focusNext, CreditService, ValidateService } from '../../../services';
import { option_numbers } from '../../../shared/utils';

import { PaymentModel } from '../veritrans.interface';

import { Date9 } from '../../time9';

//
@Component({
  selector: 'reserve-credit',
  templateUrl: './credit.component.html',
})
export class CreditComponent implements OnInit {
  @Input() model: PaymentModel;
  @Input() messages: string[];

  option_y = [];
  option_m = [];

  //
  cardNumbers: string[];
  cardExpires: string[];
  securityCode: string;

  //
  msgCard1: string[] = [];
  msgCard2: string[] = [];
  msgCard3: string[] = [];
  msgCard4: string[] = [];
  msgCard: string[] = [];

  msgMonth: string[] = [];
  msgYear: string[] = [];
  msgExpired: string[] = [];

  msgSecurity: string[] = [];

  help = false;

  //
  constructor(
    private credit: CreditService,
    public valid: ValidateService,
  ) {
    const dt = Date9();
    const year = dt.year() - 2000;
    this.option_y = option_numbers(year, year + 10);
    this.option_m = option_numbers(1, 12);
  }

  //
  ngOnInit() {
    this.model2Control();
  }

  //
  private model2Control = () => {
    const v = this.model.veritrans;

    let cn = v._cardNumber
    if (cn) {
      this.cardNumbers = [];
      for (let i = 0; i < cn.length; i += 4)
        this.cardNumbers.push(cn.substr(i, 4));
    } else {
      this.cardNumbers = ['', '', '', ''];
    }

    this.cardExpires = v.cardExpire ? v.cardExpire.split('/') : ['', ''];
    this.securityCode = v._securityCode;
  }

  private control2Model = () => {
    const v = this.model.veritrans;
    v._cardNumber = this.cardNumbers.join('');
    v.cardExpire = this.cardExpires.join('/');
    v._securityCode = this.securityCode;
  }

  //
  validation = () => {
    this.credit.validations(this);
    this.control2Model();
    return !this.msgCard.length && !this.msgExpired.length && !this.msgSecurity.length;
  }

  //
  onHelp = () => this.help = !this.help;

  //
  onKeyup = (evt: KeyboardEvent) => {
    if ('0' <= evt.key && evt.key <= '9') {
      const elm = <HTMLInputElement>evt.target;
      if (+elm.maxLength - 1 < elm.value.length)
        focusNext(elm.nextElementSibling);
    }
  }

}
