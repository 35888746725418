<h2>新規会員登録</h2>
<!--
<reg-stepbar [step]="1"></reg-stepbar>
-->
<flow-box-registration [steps]="['アドレス入力', 'お客様情報入力', '確認', '完了']" [processing]="0" [processed]="0" />

<div (dblclick)="onDblclick()" (touchmove)="onDblclick()" class="notice-sentence">
  <p class="sendText"><img class="mail-send" src="./assets/img/sendmail.svg" alt=""></p>
  <br/>
  <p class="sendText">
    <span class="notice-text">確認メールのリンクから<br/>申請を進めてください</span>
  </p>
  <br/>
  <p class="sendText">
    ご入力いただいたメールアドレスに<br class="br-sp">確認の<span class="notice-blue">メールを送信</span>しました。
    <br/>確認<span class="notice-blue">メールのURL</span>から<br class="br-sp">会員登録を完了させてください。
  </p>
  <br/>
  <p class="sendText">
    ※メールが届かない場合は、<br class="br-sp">メールアドレスをご確認の上、<br class="br-sp">再度お試しください。
    <br/>また、下記メールアドレスの<br class="br-sp">受信許可設定をお願いいたします。
    <br/>parkingweb@amano.co.jp
  </p>
</div>