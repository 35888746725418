<div *ngIf="model.byUserName!=='0'">
  <div class="tableLine">
    <div class="tableCell_l">{{label}}
      <span *ngIf="model.byUserName==='2'">  
        <span *ngIf="spanRequire" class="require">必須</span>
      </span>
    </div>
    <div class="tableCell_r">
      <div class="nameBox">
        <div class="nameBox-left">
          <input [(ngModel)]="model.fullname[0]" (keyup)="onChange()"
            [ngClass]="{'error_border_r':valid.hasError(message1, messages)}" type="text" maxlength="16" 
            placeholder="（姓）天野">
        </div>
        <div class="nameBox-right">
          <input [(ngModel)]="model.fullname[1]" (keyup)="onChange()"
            [ngClass]="{'error_border_r':valid.hasError(message2, messages)}" type="text" maxlength="16" 
            placeholder="（名）太郎">
        </div>
      </div>
      <p-error [messages]="valid.merge(message, messages)"></p-error>
    </div>
  </div>
</div>