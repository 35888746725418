<h2>{{app.ParkingPlace.name}}</h2>
<flow-box *ngIf="showFlowboxes" [steps]="Steps" [processing]="CurrentProc" [processed]="CurrentProc" />

<complete [message1]="completeMsg[0]" [message2]="completeMsg[1]"></complete>
<div class="apply-status" *ngIf="receiptNo">
  <span class="status-color">受付No.{{receiptNo}}</span>
  <br>
</div>

<div class="buttonBox">
  <loading-button (apply)="onToConfirm()" [disabled]="api.Disabled" [loading]="api.Disabled" [label]="'申請状況を確認する'"
    [class]="'cmn-btn bck-btn2 six'" [lClass]="'button-box-loading'" />
</div>