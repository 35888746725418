import { Component, OnInit } from '@angular/core';
import { NavigateService } from '../../../services';
import { MailNotifyModel } from '../mail-notify.model';

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit {

  constructor(
    public model: MailNotifyModel,
    public navi: NavigateService
  ) { }

  ngOnInit() { }

  //
  onApply = () => {
    this.model.saveStorage();
    this.navi.navigateByUrl('/mypage/mail-notify/confirm');
  }

}
