import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';
import { PasswdModel } from '../passwd.model';

import { IPasswdComponent } from '../../../shared/i-passwd/i-passwd.component';
import { IPasswd2Component } from '../../../shared/i-passwd2/i-passwd2.component';

//
interface Req {
  oldPassword: string;
  password: string;
}

interface Res {
  resultCode: number;
  mainMessage: string;
  validatorMessageOldPassword: string;
  validatorMessagePasswordList: string[];
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(IPasswdComponent, { static: false }) passwd0: IPasswdComponent;
  @ViewChild(IPasswd2Component, { static: false }) passwd: IPasswd2Component;

  model0 = { passwd: null };

  // for Server
  error: string;
  errors1: string[];
  errors2: string[];

  //
  disabled: boolean;

  //
  constructor(
    public model: PasswdModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
  ) { }

  ngOnInit() {
    this.model.passwd = null;
    this.disabled = true;
  }

  ngDoCheck() {
    if (this.passwd0 && this.passwd)
      this.validation();
  }

  //
  validation = () => {
    const b0 = this.passwd0.validation();
    const b = this.passwd.validation();
    this.disabled = !b0 || !b;
  }

  onKeyup1 = () => {
    this.error = null;
    this.errors1 = null;
  }

  onKeyup2 = () => {
    this.error = null;
    this.errors2 = null;
  }

  //
  private afterPut = (body: Res) => {
    if (body.resultCode === 0) {
      this.model.apply();
      this.navi.navigateByUrl('/mypage/passwd/complete');
    } else {
      this.api.enabled();
      
      this.error = body.mainMessage;
      this.errors1 = [body.validatorMessageOldPassword];
      this.errors2 = body.validatorMessagePasswordList;
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const req: Req = {
      oldPassword: this.model0.passwd,
      password: this.model.passwd
    };

    this.api.put<Res>('/password/update', req, 2).subscribe(
      res => this.afterPut(res.body)
    );
  }

}
