import { Component } from '@angular/core';
import { PasscardApply, Passcard, formatPeriod } from '../../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { ApiService, NavigateService } from 'src/app/services';
import { DeliveryEditService } from '../delivery-edit.service';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'delivery-confirm',
  templateUrl: './changed-confirm.component.html'
})
export class DeliveryConfirmComponent {
  reservation: PasscardApply;
  viewOnly = false;

  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _cacheService: DeliveryEditService) {
    this.reservation = this._cacheService.passcardApply;
  }

  get PasscardInfo(): Passcard {
    return this.reservation;
  }

  get ValidPeriod() {
    const { passcardValidFrom } = this.reservation!;
    return formatPeriod([passcardValidFrom, null]);
  }

  get Delivery() {
    return this._cacheService.delivery;
  }

  get IsComplete() {
    return this._cacheService.complete;
  }

  /**
   * 配送先情報入力に戻る
   */
  onReInput() {
    this._navi.navigateByUrl('/passcard/delivery-edit/step1');
  }

  /**
   * 申請を行う
   */
  async onApply() {
    this._cacheService.receiptNo = await this.api.deliveryInfoUpdate({
      passcardApplyNo: this.reservation.passcardApplyNo,
      deliveryType: this._cacheService.deliveryInputKind,
      deliveryZipCode: this._cacheService.delivery.deliveryZipCode,
      deliveryAddress1: this._cacheService.delivery.deliveryAddress1,
      deliveryAddress2: this._cacheService.delivery.deliveryAddress2,
      deliveryName: this._cacheService.delivery.deliveryName,
      deliveryTel: this._cacheService.delivery.deliveryTel
    });

    if (this._cacheService.receiptNo) {
      this._navi.navigateByUrl('/passcard/delivery-edit/complete');
    }
  }
}
