<h2>通学/勤務先変更確認</h2>
<t-error [message]="error" [messages]="errors"></t-error>

<t-info [value1]="'以下の内容で通学/勤務先を更新します。'" [value2]="'内容を確認し、「確定」を押してください。'"></t-info>


<section>
  <div class="tableCtn tableCtn-confirm">
    <div *ngIf="model.byCompanyName!=='0'">
      <p-single [label]="'通学/勤務先名'" [value]="model.wsName"></p-single>
    </div>
    <div *ngIf="model.byCompanyFurigana!=='0'">
      <p-single [label]="'通学/勤務先名（カナ）'" [value]="model.wsKana"></p-single>
    </div>
    <div *ngIf="model.byCompanyTel!=='0'">
      <p-single [label]="'電話番号'" [value]="model.wsPhone"></p-single>
    </div>
    <div *ngIf="model.byCompanyZip!=='0'">
      <p-postcode [label]="'郵便番号'" [value]="model.wsPostcode"></p-postcode>
    </div>
    <div *ngIf="model.byCompanyAddress1!=='0'">
      <p-single [label]="'住所1'" [value]="model.wsAddress1"></p-single>
    </div>
    <div *ngIf="model.byCompanyAddress2!=='0'">
      <p-single [label]="'住所2'" [value]="model.wsAddress2"></p-single>
    </div>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>