<h2>定期情報</h2>
<div class="card-list" *ngIf="reservations.length; else noReservations">
  <ng-container *ngFor="let reservation of reservations;let isLast=last; let i = index;">
    <div>
      <card [reservation]="reservation" [clickable]="true" (cardClick)="onCardClick(reservation)"></card>
    </div>
    <div class="dummy-card" *ngIf="isLast && i % 2 == 0">&nbsp;</div>
  </ng-container>
</div>
<ng-template #noReservations>
  <div *ngIf="!api.error" class="no-apply">
    <div>現在契約中の定期はありません</div>
    <div *ngIf="app.publishedDocumentInfo.applySiteTopLinkName && app.publishedDocumentInfo.applySiteTopLinkURL">
      <p>定期券の新規申請は<a #toplink target="top-link" class="passcard-link" href="{{app.publishedDocumentInfo.applySiteTopLinkURL}}">{{app.publishedDocumentInfo.applySiteTopLinkName}}</a>から</p>
      <p>お申し込みください。</p>
    </div>
  </div>
  <div class="buttonBoxVertical" *ngIf="app.publishedDocumentInfo.applySiteTopLinkName && app.publishedDocumentInfo.applySiteTopLinkURL">
    <button class="cmn-btn six icon" (click)="toplink.nativeElement.click()"><i class="icon left open"></i>{{app.publishedDocumentInfo.applySiteTopLinkName}}へ</button>
  </div>
</ng-template>