import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ApiService, ConfigService, StorageService } from '../services';
import { AppModel } from '../app.model';

export interface MyPage {
  resultCode: number;
  fullNameList: string[];
  nameFuriganaList: string[];
  birth: string[];
  zipCodeList: string[];
  address1: string;
  address2: string;
  bicycleRegistrationNo: string;
  companyName: string;
  companyNameFurigana: string;
  companyTel: string;
  companyZipCodeList: string[];
  companyAddress1: string;
  companyAddress2: string;
  mailAddress: string;
  telephoneNumber: string;
  numberPlateList?: string[];
  motorcycleNumberPlate: string;
  // 小田急対応では使用しない
//  applyUpdateNoticeType: boolean;
//  applyUpdateBeforeNoticeType: boolean;

  applyNameSetting:number;
  applyFuriganaSetting:number;
  applyZipSetting:number;
  applyAddress1Setting:number;
  applyAddress2Setting:number;
  applyTelSetting:number;
  applyCompanySetting:number;
  applyCompanyAddress2Setting:number;
  applyBirthSetting:number;
  applyRegistrationNoSetting:number;
  applyCarNumberSetting:number;
  applyMotorcycleNumberSetting:number;
  helpURL:string;
};

@Injectable({
  providedIn: 'root'
})
export class MypageModel {
  name: string[] = [];
  mailAddr: string;
  phone: string;
  notify = {
    updateNotice: false,
    updateBeforeNotice: false
  };
  birthdate: string[];
  kana: string[];
  postcode: string[];
  addr1: string;
  addr2: string;
  securityNumber: string;
  wsName: string;
  wsKana: string;
  wsTelephoneNumber: string;
  wsPostcodeList: string[];
  wsAddress1: string; 
  wsAddress2: string;
  numberPlate: string[];
  motorcycleNumberPlate: string;

  dispNoticeInfo: boolean;
  dispNameBirthdate: boolean;
  dispAddress: boolean;
  dispPhone: boolean;
  dispSecurityNumber: boolean;
  dispCarInfo: boolean;
  dispMotorcycleInfo: boolean;
  dispWsInfo: boolean;
  dispMailNotify: boolean;

  byUserName : string;
  byUserFurigana : string;
  byUserBirth : string;

  byUserMail : string;
  byUserTel : string;
  bySecurityNumber : string;
  byUserZip : string;
  byUserAddress1 : string;
  byUserAddress2 : string;
  byCompanyName : string;
  byCompanyFurigana : string;
  byCompanyTel : string;
  byCompanyZip : string;
  byCompanyAddress1 : string;
  byCompanyAddress2 : string;
  byCarInfo : string;
  byMotorcycleInfo: string;
  byMunicipalities : string;
  byKanaKigo : string;
  byRegistrationNumber : string;
  npMoji:string;
  helpURL:string;


  constructor(
    private _app: AppModel,
    private _api: ApiService,
    private _conf: ConfigService,
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  getMypage = () => {
    const subj = new Subject();

    //const req = { dcMemberCompanyNo: this._conf.memberCompany };

    this._api.get<MyPage>('/user/mypage', 1).subscribe(
      res => {
        this.setModel(res.body);
        subj.next();
      },
      _err => {
        this._app.login = false;
        this._api.clear();
      }
    );

    return subj;
  }

  //
  setModel = (body: MyPage) => {
    this.name = body.fullNameList;
    /*this.name = body.nameFuriganaList;*/
    this.kana = body.nameFuriganaList;
    this.mailAddr = body.mailAddress;
    this.phone = body.telephoneNumber;
    this.numberPlate = body.numberPlateList;

    this.notify = {
      // 小田急対応では使用しない
//      updateNotice: body.applyUpdateNoticeType,
//      updateBeforeNotice: body.applyUpdateBeforeNoticeType
      updateNotice: false,
      updateBeforeNotice: false

    };
    this.birthdate = body.birth;
    this.postcode = body.zipCodeList;
    this.addr1 = body.address1;
    this.addr2 = body.address2;
    this.securityNumber = body.bicycleRegistrationNo;
    this.wsName = body.companyName;
    this.wsKana = body.companyNameFurigana;
    this.wsTelephoneNumber = body.companyTel;
    this.wsPostcodeList =  body.companyZipCodeList;
    this.wsAddress1 = body.companyAddress1; 
    this.wsAddress2 = body.companyAddress2;
    this.motorcycleNumberPlate = body.motorcycleNumberPlate;

    // DBから取得する表示項目
    // マイページトップに表示、非表示設定
    // 小田急対応ではお知らせは未使用
    this.dispNoticeInfo = false;
    if (body.applyNameSetting.toString() == '0' && body.applyFuriganaSetting.toString() == '0' && body.applyBirthSetting.toString() == '0')
    {
      this.dispNameBirthdate = false;
    }
    else
    {
      this.dispNameBirthdate = true;
    }
    if (body.applyZipSetting.toString() == '0' && body.applyAddress1Setting.toString() == '0' && body.applyAddress2Setting.toString() == '0')
    {
      this.dispAddress = false;
    }
    else
    {
      this.dispAddress = true;
    }
    if (body.applyTelSetting.toString() == '0')
    {
      this.dispPhone = false;
    }
    else
    {
      this.dispPhone = true;
    }
    if (body.applyRegistrationNoSetting.toString() == '0')
    {
      this.dispSecurityNumber = false;
    }
    else
    {
      this.dispSecurityNumber = true;
    }
    if (body.applyCarNumberSetting.toString() == '0')
    {
      this.dispCarInfo = false;
    }
    else
    {
      this.dispCarInfo = true;
    }
    if (body.applyMotorcycleNumberSetting.toString() == '0')
    {
      this.dispMotorcycleInfo = false;
    }
    else
    {
      this.dispMotorcycleInfo = true;
    }
    if (body.applyCompanySetting.toString() == '0' && body.applyCompanyAddress2Setting.toString() == '0')
    {
      this.dispWsInfo = false;
    }
    else
    {
      this.dispWsInfo = true;
    }
    // 小田急対応ではメール通知設定は未使用
    this.dispMailNotify = false;
    

    this.byUserName = body.applyNameSetting.toString();
    this.byUserFurigana = body.applyFuriganaSetting.toString();
    this.byUserBirth = body.applyBirthSetting.toString();
    this.byUserZip = body.applyZipSetting.toString();
    this.byUserAddress1 = body.applyAddress1Setting.toString();
    this.byUserAddress2 = body.applyAddress2Setting.toString();

    this.byCompanyName = body.applyCompanySetting.toString();
    this.byCompanyFurigana = body.applyCompanySetting.toString();
    this.byCompanyTel = body.applyCompanySetting.toString();
    this.byCompanyZip = body.applyCompanySetting.toString();
    this.byCompanyAddress1 = body.applyCompanySetting.toString();
    this.byCompanyAddress2 = body.applyCompanyAddress2Setting.toString();
  
    this.bySecurityNumber = body.applyRegistrationNoSetting.toString();
    this.byUserTel = body.applyTelSetting.toString();
    this.byCarInfo = body.applyCarNumberSetting.toString();
    this.byMotorcycleInfo = body.applyMotorcycleNumberSetting.toString();
    this.byMunicipalities = body.applyMotorcycleNumberSetting.toString();
    this.byKanaKigo = body.applyMotorcycleNumberSetting.toString();
    this.byRegistrationNumber = body.applyMotorcycleNumberSetting.toString();
    
    this.helpURL = body.helpURL;

    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('mypage', this);
  }

  loadStorage = () => {
    this._storage.load('mypage', this);
  }

}
