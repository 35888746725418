<div class="card-container">
  <br>
  <card [reservation]="reservation">
  </card>
</div>
<br>
<ng-content></ng-content>
<h3>定期情報</h3>
<passcard-info [model]="PasscardInfo"></passcard-info>
<br>
<ng-container *ngIf="Status <= BS.Available || Status == BS.Reject">
  <div class="tableCtn">
    <p-single label="定期期間" [value]="ValidPeriod" />
  </div>
  <br>
</ng-container>

<notice-info [parkingNoticeInformation]="NoticeInfo" *ngIf="noticeInfoShow"></notice-info>
<br>

<place-info [parkingPlace]="ParkingArea"></place-info>
<br>

<ng-container *ngIf="Delivery">
  <h3>配送先情報</h3>
  <delivery [delivery]="Delivery"></delivery>
</ng-container>