<div class="card" (click)="onCardClick()" [ngClass]="{ clickable: clickable }">
  <div>
    <div>
      <div>
        <i class="bike"></i>
      </div>
      <div>
        <i class="bike"></i>
      </div>
    </div>
    <div>
      <div>
        <div>
          <div class="parking-status status" [ngClass]="StatusClass">{{StatusText}}</div>
        </div>
        <div>
          <div class="logo">
            <img class="logo-image" src="{{app?.publishedDocumentInfo?.headerImageURL}}">&nbsp;
          </div>
        </div>
      </div>
      <div>
        <div class="card-normal">
          <div>
            <p>{{reservation.parkingName}}</p>
            <p>{{reservation.name}}</p>
          </div>
          <div [ngSwitch]="status">
            <ng-template [ngSwitchCase]="RS.Available">
              <span>{{TermYear}}年<span class="font-xlarge"><b>{{TermMMDD}}</b></span><b>({{TermWeekDay}})</b>まで</span>
            </ng-template>
            <ng-template [ngSwitchCase]="RS.Queuing">
              <span class="font-xlarge">順番待ちです</span>
            </ng-template>
            <ng-template ngSwitchDefault>
              <span class="font-xlarge">{{Tips}}</span>
            </ng-template>
          </div>
          <div>
            <ng-container *ngIf="contract">
              <div *ngIf="waitingForRefresh">
                <div class="parking-status status-idle status2">
                  <p>更新</p>
                  <p>受付中</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="font-small">区画</p>
                  <p>{{contract.divisionName}}</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="font-small">位置</p>
                  <p>{{contract.placeName}}</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="font-small">No.</p>
                  <p>{{contract.passcardNo}}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>    
    </div>
  </div>
</div>