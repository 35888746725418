import { Routes } from '@angular/router';

import { CompleteComponent } from './complete/complete.component';
import { ConfigComponent } from './config/config.component';
import { SendComponent } from './send/send.component';

//
export const routes: Routes = [{
  path: 'complete/:p1/:p2',
  component: CompleteComponent
}, {
  path: 'config',
  component: ConfigComponent
}, {
  path: 'send',
  component: SendComponent
}];

//
export const components = [
  CompleteComponent,
  ConfigComponent,
  SendComponent
];
