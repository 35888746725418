import { Component, Input, Output, EventEmitter, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService, ValidateService } from '../../services';
import { Time9 } from '../time9';

import { AppModel } from '../../app.model';

import { CreditComponent } from './credit/credit.component';
import { EtcCardComponent } from './etc-card/etc-card.component';

//
import {
  url, PaymentModel, VeritransReq, VeritransRes, VeritransErr,
  CreditReq, CreditNoMemberReq, CreditRes,
  IsLockedReq, IsLockedRes, LockReq, LockRes
} from './veritrans.interface';

//
declare var use_veritrans: boolean;

// url = 'https://api.veritrans.co.jp/4gtoken';
// token = 'cd76ca65-7f54-4dec-8ba3-11c12e36a548';
declare var veritrans: {
  url: string;
  token: string
}

//
declare var CREDIT_LOCK_COUNT: number;

//
@Component({
  selector: 'reserve-veritrans',
  templateUrl: './veritrans.component.html',
})
export class VeritransComponent implements OnInit, DoCheck {
  @Input() model: PaymentModel;
  @Output() apply: EventEmitter<null> = new EventEmitter();

  @ViewChild(CreditComponent, { static: false }) credit: CreditComponent;
  @ViewChild(EtcCardComponent, { static: false }) etccard: EtcCardComponent;

  disabled: boolean;

  valids = {
    credit: false,
    etccard: false
  };

  // count Limit
  errorLimit: string;

  // for server
  error: string;
  msgCredit: string[];
  msgEtcCard = { number: null, expired: null };

  //
  etc: boolean;

  // for Lock
  count = 0;
  limit = 0;
  interval = 0;

  //
  constructor(
    private app: AppModel,
    public api: ApiService,
    public conf: ConfigService,
    public navi: NavigateService,
    public valid: ValidateService,
  ) { }

  ngOnInit() {
    this.etc = !!this.model.etccard.number;

    this.limit = CREDIT_LOCK_COUNT;
    if (this.noMember() && this.app.locked)
      this.errorLimit = this.valid.credit.countLimit;
    else if (!this.noMember())
      this.postLocked();
  }

  ngDoCheck() {
    this.validation();
  }

  // noMember (non JWT)
  private noMember = () => !this.model.mail.token && !this.app.login;

  //
  // lock
  //
  private postLocked = () => {
    const mail = this.model.mail;
    const token = mail.token ? mail.token : undefined;
    const date = mail.date ? Time9.toString(+mail.date, '_') : undefined;

    const req: IsLockedReq = {
      dcMemberCompanyNo: this.conf.memberCompany,
      mailSendToken: token,
      mailSendDate: date
    };

    this.api.post<IsLockedRes>(url.credit.islocked, req, 2).subscribe(
      res => this.afterLocked(res.body)
    );
  }

  private afterLocked = (body: IsLockedRes) => {
    if (body.resultCode === '0') {
      this.limit = body.creditInputCountLimit;
      this.interval = body.creditInputIntervalTime;

      this.app.locked = new Date().getTime() < body.creditInputInvalidTime;
      if (this.app.locked)
        this.errorLimit = this.valid.credit.countLimit;
    }
  }

  //
  // validation
  //
  private validation = () => {
    this.valids.credit = this.credit ? this.credit.validation() : false;

    if (this.etc)
      this.valids.etccard = this.etccard ? this.etccard.validation() : false;
    else
      this.model.etccard = { number: null, expire: null, masked: null };

    this.disabled = !this.valids.credit || (this.etc && !this.valids.etccard);
  }

  onChange1 = () => {
    this.error = null;
    this.msgCredit = null;
  }

  onChange2 = () => {
    this.error = null;
  }

  //
  // veritrans
  //
  private postVeritrans = () => {
    const v = this.model.veritrans;
    const req: VeritransReq = {
      token_api_key: veritrans.token,
      card_number: v._cardNumber,
      card_expire: v.cardExpire,
      security_code: v._securityCode,
      lang: 'ja'
    };

    if (use_veritrans) {
      // use Veritrans
      this.api.post<VeritransRes>(veritrans.url, req, 0).subscribe(
        res => this.afterVeritrans(res.body),
        error => this.errorVeritrans(error.error)
      );
    } else {
      // no Veritrans
      const c = v._cardNumber;
      const mask = '*'.repeat(c.length - 8);
      const card = c.substr(0, 6) + mask + c.substr(mask.length + 6);

      const body: VeritransRes = {
        token: 'ba967195-c153-4423-a6fb-f39eabba8a03',
        token_expire_date: '20190412155021',
        req_card_number: card,
        status: 'success',
        code: 'success',
        message: 'トークンの生成に成功しました。'
      };
      this.afterVeritrans(body);
    }
  }

  private afterVeritrans = (body: VeritransRes) => {
    if (body.code === 'success' && body.status === 'success') {
      const v = this.model.veritrans;
      v.reqCardNumber = body.req_card_number;
      v.cardToken = body.token;
      this.error = null;

      if (!this.noMember())
        this.postCredit(body);
      else
        this.postCreditNoMember(body);
    } else {
      this.errorVeritrans({
        code: null,
        status: null,
        message: null
      });
    }
  }

  private errorVeritrans = (error: VeritransErr) => {
    this.api.enabled();
    this.app.locked = !!this.limit && this.limit <= ++this.count;
    this.app.saveStorage();

    if (this.app.locked) {
      this.errorLimit = this.valid.credit.countLimit;
      if (!this.noMember())
        this.postLock();
    } else
      this.error = this.valid.credit.certErr;
  }

  private postLock = () => {
    const mail = this.model.mail;
    const token = mail.token ? mail.token : undefined;
    const date = mail.date ? Time9.toString(+mail.date, '_') : undefined;

    const req: LockReq = {
      dcMemberCompanyNo: this.conf.memberCompany,
      mailSendToken: token,
      mailSendDate: date
    };
    this.api.post<LockRes>(url.credit.lock, req, 2);
  }

  //
  // credit
  //
  private postCredit = (body: VeritransRes) => {
    const c = this.conf;
    const m = this.model;
    const e = m.etccard;
    const v = m.veritrans;

    const b = !!e.number;
    const exp = b ? e.expire.split('/') : null;

    const req: CreditReq = {
      dcReserveCompanyNo: c.reserveCompany,
      dcMemberCompanyNo: c.memberCompany,
      dcBusinessAreaNo: c.businessArea,
      mdkToken: v.cardToken,
      maskCreditNo: v.reqCardNumber,
      etcCardNo: b ? e.number : undefined,
      etcExpirationMonth: b ? +exp[0] : undefined,
      etcExpirationYear: b ? +exp[1] : undefined,
      telephoneNumber: m.phone,
      memberCategoryType: c.memberCategory
    };

    this.api.post<CreditRes>(url.credit.root, req, 2).subscribe(
      res => this.afterCredit(res.body),
      _error => this.api.enabled()
    );
  }

  private postCreditNoMember = (body: VeritransRes) => {
    const m = this.model;

    const req: CreditNoMemberReq = {
      dcReserveCompanyNo: this.conf.reserveCompany,
      mdkToken: m.veritrans.cardToken,
      telephoneNumber: m.phone,
      memberRegistFlg: m.regist
    };

    this.api.post<CreditRes>(url.credit.no_member, req, 2).subscribe(
      res => this.afterCredit(res.body),
      _error => this.api.enabled()
    );
  }

  private afterCredit = (body: CreditRes) => {
    this.api.enabled();

    if (body.resultCode === '0') {
      const m = this.model;
      m.etccard.masked = body.maskETCCardNo;
      m.credit.account = body.creditAccountId
      m.credit.card = body.creditCardId;
      m.credit.transaction = body.transactionId;
      this.apply.emit();
    } else {
      this.error = body.mainMessage;
      this.msgCredit = [body.validatorMessageCreditCardNo];
      this.msgEtcCard = {
        number: [body.validatorMessageETCCardNo],
        expired: [body.validatorMessageETCCardExpirationDate]
      };
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;

    if (this.app.locked) {
      this.api.enabled();
      this.errorLimit = this.valid.credit.countLimit;
    }
    else
      this.postVeritrans();
    // this.recaptcha.execute('credit').subscribe(() => this.postVeritrans());
  }

}
