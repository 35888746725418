import { Injectable } from "@angular/core";
import { ApiService, NavigateService } from "../services";
import { ApiResult, ApiResultGetPasscardApply, PasscardApplyApi, PasscardApplyResultApi } from "./passcard.model";
import { UserInfo } from "./models/user-info";

const passcardmemberapi = Object.freeze({
    passcardApply: '/passcardApply',
    passcardReApplyInfo: '/passcardReApplyInfo',
    passcardApplyInfo: '/passcardApplyInfo',
    deliveryInfo: '/deliveryInfo',
    passcardTypeList: '/passcardTypeList',
    myPageCorrectConfirmation: '/myPageCorrectConfirmation',
    passcardApplyCancel: '/passcardApplyCancel',
    deliveryInfoUpdate: '/deliveryInfoUpdate',
    publishedDocumentInfo: '/publishedDocumentInfo'
});

@Injectable({
    providedIn: 'root'
})
export class PasscardApiProxy {
    private _error = '';
    //error = '';

    constructor(private _navi: NavigateService,
        private _api: ApiService) {
    }

    get error() {
        return this._error;
    }

    set error(val: string) {
        this._error = val;
        if (val != '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    get Disabled() {
        return this._api.disabled;
    }

    async passcardApply(passcardApply: any): Promise<string> {
        const ret = await this.post<PasscardApplyResultApi>(passcardmemberapi.passcardApply, passcardApply);
        return ret.receiptNo;
    }

    async passcardTypeList(passcardApplyNo?: number) {
        let result: PasscardApplyApi = null;
        if (passcardApplyNo != null && passcardApplyNo != undefined) {
            result = await this.post<PasscardApplyApi>(passcardmemberapi.passcardReApplyInfo, { passcardApplyNo });
        }
        else {
            result = await this.get<PasscardApplyApi>(passcardmemberapi.passcardTypeList);
        }

        if (!result.passcardApplyPossible || result.resultCode != 0)
        {
            this.error = result.mainMessage;
        }

        const dateNow = result.systemDate ? new Date(result.systemDate) : new Date();
        const year = dateNow.getFullYear();
        const m = dateNow.getMonth();
        const currMonth = Date.UTC(year, m, dateNow.getDate());
        const nextMonth = Date.UTC(year, m + 1, 1);
        result.periods = [ currMonth, nextMonth, Date.UTC(year, m + 2, 1) ];
        result.passcardTypeList ??= [];

        return result;
    }

    passcardApplyInfo() {
        return this.get<ApiResultGetPasscardApply>(passcardmemberapi.passcardApplyInfo);
    }

    async getPasscardApplyInfoAndNavi(viewOnly?: boolean) {
        const result = await this.get<ApiResultGetPasscardApply>(passcardmemberapi.passcardApplyInfo);
        const passcardApplyList = result.passcardApplyList;
        if (passcardApplyList.length > 1 || passcardApplyList.length == 0) {
            this._navi.navigate(`/passcard/list`, false, { applies: passcardApplyList });
        }
        else if (passcardApplyList.length == 1) {
            if (passcardApplyList[0].status < 30 && passcardApplyList[0].passcardAvailable == 0) {
                this._navi.navigate(`/passcard/applyInfo`, false, { viewOnly, reservation: passcardApplyList[0] });
            }
            else {
                if (passcardApplyList[0].passcardAvailable == 1
                    && passcardApplyList[0].status == 0) {
                    passcardApplyList[0].status = 30;
                }

                this._navi.navigate(`/passcard/contract`, false, { viewOnly, contract: passcardApplyList[0] });
            }
        }
    }

    async passcardApplyCancel(passcardApplyNo: any): Promise<void> {
        await this.post<ApiResult>(passcardmemberapi.passcardApplyCancel, { passcardApplyNo });
    }

    deliveryInfo() {
        return this.get<UserInfo>(passcardmemberapi.deliveryInfo);
    }

    async myPageCorrectConfirmation(passcardApplyNo: any): Promise<string> {
        const result = await this.post<ApiResult>(passcardmemberapi.myPageCorrectConfirmation, { passcardApplyNo });
        if (result.resultCode == 0) {
            return <string>result['receiptNo'];
        }

        this.error = result.mainMessage;
        return '';
    }

    async deliveryInfoUpdate(param: any) {
        const result = await this.post<ApiResult>(passcardmemberapi.deliveryInfoUpdate, param);
        if (result.resultCode == 0) {
            return <string>result['receiptNo'];
        }

        this.error = result.mainMessage;
        return '';
    }

    async getPublishedDocumentInfo(parkingCode: string): Promise<{ passcardApplyTermsServiceURL: string, passcardApplyNotesDOCList: string[] }> {
        const result = await this.post<ApiResult>(passcardmemberapi.publishedDocumentInfo, { parkingCode });
        return {
            passcardApplyTermsServiceURL: result['passcardApplyTermsServiceURL'],
            passcardApplyNotesDOCList: result['passcardApplyNotesDOCList']
        }
    }

    private post<T>(url: string, param: any) {
        this.error = '';
        return new Promise<T>((resolve, reject) => {
            this._api.disabled = true;
            this._api.post<T>(url, param, 1).subscribe(res => {
                this._api.disabled = false;
                const result = <ApiResult>res.body;
                if (result.resultCode == 0) {
                    resolve(res.body);
                    return;
                }

                if (result.resultCode == 4) {
                    this.error = result.mainMessage;
                    reject(this.error)
                    return;
                }

                this._navi.navigateByUrl('/error', true);
            });
        });
    }

    private get<T>(url: string): Promise<T> {
        this.error = '';
        return new Promise<T>((resolve, reject) => {
            this._api.disabled = true;
            this._api.get<T>(url, 1).subscribe(res => {
                this._api.disabled = false;
                const result = <ApiResult>res.body;
                if (result.resultCode == 0) {
                    resolve(res.body);
                    return;
                }

                if (result.resultCode == 4) {
                    this.error = result.mainMessage;
                    reject(this.error)
                    return;
                }

                this._navi.navigateByUrl('/error', true);
            });
        });
    }
}