import { Injectable } from '@angular/core';

import { StorageService } from '../../services';
import { PaymentModel } from '../../shared/veritrans/veritrans.interface';

@Injectable({
  providedIn: 'root'
})
export class CreditModel extends PaymentModel {
  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    super();
    this.loadStorage();
  }

  //
  reset = () => {
    super.reset();
    this.completed = false;
    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('credit', this);
  }

  loadStorage = () => {
    this._storage.load('credit', this);
  }
}
