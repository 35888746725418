import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService, NoticeInfoService } from 'src/app/services';
import { PasscardApply } from '../passcard.model';
import { Location } from '@angular/common';
import { PasscardApiProxy } from '../passcard.api.proxy';

@Component({
  selector: 'reservations',
  templateUrl: './reservations.component.html'
})
export class ReservationsComponent {
  reservations: PasscardApply[];
  @ViewChild('toplink') toplink: ElementRef<HTMLAnchorElement>;

  constructor(public app: AppModel,
    private _navi: NavigateService,
    public api: PasscardApiProxy,
    location: Location,
    
  ) {
    const state = location.getState();
    this.reservations = <PasscardApply[]>state?.['applies'];
    if (this.reservations == null) {
      this.api.passcardApplyInfo()
        .then(x => {
          this.reservations = x.passcardApplyList;
        });
    }
  }

  /**
   *　カードクリック
   * @param reservation 申請情報
   */
  onCardClick(reservation: PasscardApply) {
    if (reservation.passcardAvailable == 1
      && reservation.status == 0)
    {
      reservation.status = 30;
    }
  
    if (reservation.status >= 30 || reservation.passcardAvailable == 1) {
      this._navi.navigate(`/passcard/contract`, false, { contract: reservation });
      return;
    }

    this._navi.navigate(`/passcard/applyInfo`, false, { reservation, viewOnly: false });
  }
}
