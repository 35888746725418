import { Component } from '@angular/core';
import { PasscardStatus } from '../../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { PasscardBookingService } from '../../../passcard-booking.service';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'apply04',
  templateUrl: './apply04.component.html'
})
export class Apply04Component {
  completeMsg = ['定期券の新規申請が完了しました。', '']
  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _bookingService: PasscardBookingService) {
    this._bookingService.complete = true;
    this.completeMsg[1] = _bookingService.PasscardInfo.status == PasscardStatus.Available ? '申請が完了するまでお待ちください。' : '順番となるまでお待ちください。';
  }

  get receiptNo() {
    return this._bookingService.receiptNo;
  }

  get showFlowboxes() {
    return this._bookingService.PasscardInfo.status == PasscardStatus.Available;
  }

  get Steps() {
    return this._bookingService.applyCardDelivery == 0 ? ['定期選択', '確認', '完了']
      : ['定期選択', '配送情報', '確認', '完了'];
  }

  get CurrentProc() {
    return this._bookingService.applyCardDelivery == 0 ? 2 : 3;
  }

  /**
   * 申請状況を確認する
   */
  onToConfirm() {
    this.api.getPasscardApplyInfoAndNavi(true);
  }
}
