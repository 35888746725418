import { Pipe, PipeTransform } from '@angular/core';

import { zero2, minute2string } from './utils';
import { day2string, Date9, Time9 } from './time9';

//
const WEEK = ['日', '月', '火', '水', '木', '金', '土'];

// 10000 -> 10,000
@Pipe({ name: 'number3' })
export class Number3Pipe implements PipeTransform {
  transform(n: number) {
    return ('' + n).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

// 1234 -> 12-34
@Pipe({ name: 'numberPlate' })
export class NumberPlatePipe implements PipeTransform {
  constructor() { }

  transform(s: string) {
    if (!s)
      return '';

    const l = s.length;
    if (l === 4)
      return s.slice(0, 2) + '-' + s.slice(2, 4);
    else if (l < 4)
      return '&middot;'.repeat(4 - s.length) + s;

    return s;
  }
}

// 登録されていません
@Pipe({ name: 'registif' })
export class RegistIfPipe implements PipeTransform {
  transform(str: string) {
    return !str ? '登録されていません' : str;
  }
}

// yyyy/mm/dd
@Pipe({
  name: 'number2date'
})
export class Number2DatePipe implements PipeTransform {
  transform(time: number) {
    if (!time)
      return '';

    const d = new Time9(time);
    return d.getYMD();
  }
}

// yyyy/mm/dd(火) 12:00
@Pipe({
  name: 'datetime'
})
export class DateTimePipe implements PipeTransform {
  transform(time: number) {
    if (!time)
      return '';

    const d = Date9(time);
    return '' + d.year() + '/' + zero2(d.month() + 1) + '/' + zero2(d.date())
      + '(' + day2string(d) + ') ' + zero2(d.hour()) + ':' + zero2(d.minute());
  }
}

// yyyy/mm/dd(火) 12:00
@Pipe({
  name: 'number2datetime9'
})
export class Number2DateTime9Pipe implements PipeTransform {
  transform(time: number) {
    if (!time)
      return '';

    const d = new Time9(time);
    return d.getYMD() + '(' + WEEK[d.getDay()] + ') ' + d.getHM();
  }
}

// yyyy年mm月dd日(火)
@Pipe({
  name: 'number2date9'
})
export class Number2Date9Pipe implements PipeTransform {
  transform(time: number) {
    if (!time)
      return '';

    const d = new Time9(time);
    return d.getYMD2() + '(' + WEEK[d.getDay()] + ')';
  }
}

// 12:00
@Pipe({
  name: 'number2time9'
})
export class Number2Time9Pipe implements PipeTransform {
  transform(time: number) {
    if (!time)
      return '';

    const d = new Time9(time);
    return d.getHM();
  }
}

// 12時00分
@Pipe({
  name: 'number2time9J'
})
export class Number2Time9JPipe implements PipeTransform {
  transform(time: number) {
    if (!time)
      return '';

    const d = new Time9(time);
    return zero2(d.getHours()) + '時' + zero2(d.getMinutes()) + '分';
  }
}

// mm月dd日(火)
@Pipe({
  name: 'number2mmddd'
})
export class Number2MMDDDPipe implements PipeTransform {
  transform(time: number) {
    if (!time)
      return '';

    const d = new Time9(time);
    return d.getMD() + '(' + WEEK[d.getDay()] + ')';
  }
}

@Pipe({
  name: 'minute2time'
})
export class Minute2TimePipe implements PipeTransform {
  transform(minute: number) {
    return minute2string(minute);
  }
}

// yyyy/mm/dd(火) 12:00
@Pipe({
  name: 'datetime2'
})

//
@Pipe({
  name: 'disability'
})
export class DisabilityPipe implements PipeTransform {
  transform(b: boolean) {
    return b ? '身体障がい者等の割引の適用を希望します' : '身体障がい者等の割引の適用を希望しません'
  }
}

//
export const pipes = [
  Number3Pipe,
  NumberPlatePipe,
  RegistIfPipe,
  Number2DatePipe,
  Number2DateTime9Pipe,
  Number2Date9Pipe,
  Number2Time9Pipe,
  Number2Time9JPipe,
  Number2MMDDDPipe,
  DateTimePipe,
  Minute2TimePipe,
  DisabilityPipe,
];
