<ng-container *ngIf="reservation">
  <div class="confirm-info cancel-constract-tip">
    <div><i class="confirm-img circle-warning-img"></i></div>
    <div>
      <p>以下の申請内容を取り消します。</p>
      <p>内容を確認し、「取消」を押してください。</p>
    </div>
  </div>
  <h2>申請取消</h2>
  <apply-info [viewOnly]="true" [reservation]="reservation"></apply-info>
  <ng-container *ngIf="!IsComplete">
    <section class="checkWrapper">
      <label [ngClass]="{'checkBoxArea':isConfirmed,'checkBoxAreaNot':!isConfirmed}">
        <input [(ngModel)]="isConfirmed" type="checkbox" class="unsubscribeConfirm_check">
        <div class="checkboxMeg">取消内容について確認しました</div>
      </label>
    </section>
    <br>
    <div *ngIf="!IsComplete" class="buttonBoxVertical">
      <button type="button" (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
      <loading-button
        (apply)="onCancel()"
        [disabled]="!isConfirmed ||api.Disabled "
        [loading]="api.Disabled"
        [label]="'取消'"
        [class]="'cmn-btn cncl-btn'"
        [lClass]="'button-box-loading'">
      </loading-button>
    </div>
    <br>
  </ng-container>
</ng-container>