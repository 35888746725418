import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';
import { NameBirthdateModel } from '../name-birthdate.model';
import { MypageModel } from '../../mypage.model';

import { IFullnameComponent } from '../../../shared/i-fullname/i-fullname.component';
import { IKanaComponent } from '../../../shared/i-kana/i-kana.component';


//
interface Req {
  fullNameList: string[];
  furiganaList: string[];
}

interface Res {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
  nameValidator: {
    validatorMessageFullNameList: string[];
    validatorMessageNameFuriganaList: string[];  
  }
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(IFullnameComponent, {static: false}) fullname: IFullnameComponent;
  @ViewChild(IKanaComponent, { static: false }) kana: IKanaComponent;

  // for Server
  error: string;
  errors: string[];
  messages: { fullname: string[];kana:string[]};


  //
  disabled: boolean;

  //
  constructor(
    public model: NameBirthdateModel,
    public mypage: MypageModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
  ) { }

  ngOnInit() {
    this.messages = {
      fullname:null,
      kana:null
    };
  }

  ngDoCheck() {
    const b1 = this.fullname ? this.fullname.validation() : false;
    const b2 = this.kana ? this.kana.validation() : false;
    var b3 = true;
    if (this.model.byUserName === '0' && this.model.byUserFurigana === '0') {
      b3 = false;
    }
    this.disabled = !(b1 && b2 && b3);
  }

  onKeyup = () => {
    this.error = null;
    this.errors = null;
  }

  //
  private afterPost = (body: Res) => {
    if (body.resultCode === 0) {
      this.model.saveStorage();
      this.navi.navigateByUrl('/mypage/name-birthdate/confirm');
    } else {
      this.api.enabled();

      this.error = body.mainMessage;
      this.messages.fullname = body.nameValidator.validatorMessageFullNameList;
      this.messages.kana = body.nameValidator.validatorMessageNameFuriganaList;
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;
    

    const req: Req = {
      fullNameList:this.model.fullname,
      furiganaList:this.model.kana
    };

    this.api.post<Res>('/name/check', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
      }
}
