import { zero2 } from './utils';
import * as moment from 'moment-timezone';

//
export function Date9(t: number = 0): moment.Moment {
  return moment.tz(t ? t : new Date().getTime(), "Asia/Tokyo");
}

//
export class Time9 {
  static today0 = () => {
    const today = Time9.date();
    today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    return today;
  }

  static time0 = (time?: number) => {
    const dt = Time9.date(time);
    dt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return dt.valueOf();
  }

  static date = (time?: number) => Date9(time);

  static toString = (t: number, sepa: string) => {
    if (!t)
      return '';

    const t9 = new Time9(t);
    return t9.getYMD() + (sepa ? sepa + t9.getHMS() : '');
  }

  //
  dt: moment.Moment;

  //
  constructor(public time: number) {
    this.dt = Time9.date(time);
  }

  //
  getTime = () => this.dt.valueOf();
  getYear = () => this.dt.year();
  getMonth = () => this.dt.month() + 1;
  getDate = () => this.dt.date();
  getHours = () => this.dt.hour();
  getMinutes = () => this.dt.minute();
  getSeconds = () => this.dt.second();
  getDay = () => this.dt.day();

  getYMD = () => '' + this.getYear() + '/' + zero2(this.getMonth()) + '/' + zero2(this.getDate());
  getYMD2 = () => '' + this.getYear() + '年' + zero2(this.getMonth()) + '月' + zero2(this.getDate()) + '日';
  getMD = () => zero2(this.getMonth()) + '/' + zero2(this.getDate());
  getHM = () => '' + zero2(this.getHours()) + ':' + zero2(this.getMinutes());
  getHMS = () => '' + zero2(this.getHours()) + ':' + zero2(this.getMinutes()) + ':' + zero2(this.getSeconds());

  getTime0 = () => {
    const dt = Date9(this.dt.valueOf());
    return dt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }
}

//
export function printDate9(t: number = 0) {
  console.log(Date9(t).format(), new Date(t).toLocaleString());
}

// 2018/07/05
export function time2string(t: number) {
  if (!t)
    return '';

  const d = Date9(t);
  return '' + d.year() + '/' + zero2(d.month() + 1) + '/' + zero2(d.date());
}

// time -> 01/20
export function time2expired(time: number) {
  if (time) {
    const dt = Date9(time);
    return zero2(dt.month() + 1) + '/' + zero2(dt.year() - 2000);
  } else
    return null;
}

export function isCreditExpired(month: number, year: number) {
  const dt1 = Date9();
  const dt2 = Date9();
  dt2.set({ year: 2000 + year, month: month, date: 1 });

  // TODO: oneDay?
  return dt2.valueOf() - dt1.valueOf() < 0;
}

//
export function day2string(d: moment.Moment) {
  return ['日', '月', '火', '水', '木', '金', '土'][d.day()];
}
