import { Component, Input } from '@angular/core';
import { Delivery } from '../../passcard.model';

@Component({
  selector: 'delivery',
  templateUrl: './delivery.component.html'
})
export class DeliveryComponent {
  @Input() delivery!: Delivery;
}
