import { Component, Input } from '@angular/core';

@Component({
  selector: 'flow-box-registration',
  templateUrl: './flow-box-registration.component.html'
})
export class FlowBoxRegistrationComponent {
  @Input() processed: number = 0;
  @Input() processing: number = 0;
  @Input() steps: string[] = [];

  getActiveClass(proc: number) {
    return proc == this.processing ? 'active' : (proc < this.processing ? 'done' : null);
  }

}
