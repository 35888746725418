import { Component, Input } from '@angular/core';

@Component({
  selector: 'p-postcode',
  templateUrl: './p-postcode.component.html',
})
export class PPostcodeComponent {
  @Input() label: string;
  @Input() value: string[];

  constructor() { }
}
