import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mailaddr',
  templateUrl: './mail-addr.component.html',
})
export class MailAddrComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
