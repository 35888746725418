import { Component, Input } from '@angular/core';

@Component({
  selector: 'p-single-c',
  templateUrl: './p-single-c.component.html',
})
export class PSingleCComponent {
  @Input() label: string;
  @Input() value: string;

  constructor() { }
}
