import { Component, OnInit } from '@angular/core';
import { AppModel } from '../../app.model';
import { NavigateService, StorageService } from '../../services';
import { RegistrationModel } from '../registration.model';
import { MypageModel } from '../../mypage/mypage.model';


@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
})
export class CompleteComponent implements OnInit {

  parkingCode = '';
  loginURLFlg = false;
  parkingCodeZero = false;
  agreementStatus = "";

  constructor(
    public model: RegistrationModel,
    private _app: AppModel,
    private _mypage: MypageModel,
    private _navi: NavigateService,
    private _storage: StorageService,
  ) { }

  ngOnInit() {
    if (this._storage._load('parkingCode')) {
      this.parkingCode = this._storage._load('parkingCode');
    }

    // 利用規約同意の不具合対応
    if (this._storage._load('agreementStatus')) {
      this.agreementStatus = this._storage._load('agreementStatus');
    }

    // 会員登録完了後、マイページに遷移した時の表示用
    this._mypage.getMypage();
    // セッションストレージからparkingCodeを削除する
    if (this._storage._load('parkingCode')) {
      this._storage.remove('parkingCode');
    }

    // 遷移元によってボタンの文言を切り替える
    if (this.model.transitionSourceURL === '/login'
      || this.model.transitionSourceURL === '/web-passcard/login') {
      this.loginURLFlg = true;
      if (this.parkingCode === '0') {
        this.parkingCodeZero = true;
      }
    }

  }

  //
  onBack = () => {
    // 会員登録完了後の画面遷移先切替対応
    if (this.model.transitionSourceURL) {
      if (this._app.login) {
        if (this.model.transitionSourceURL === '/login'
            || this.model.transitionSourceURL === '/web-passcard/login') {
          if (this.model.parkingCode === 0) {
            this._navi.navigateByUrl('/passcard/list');
          } else {
            //this._navi.navigateByUrl('/passcard/new');
            if (this.agreementStatus === "1") {
              this._navi.navigateByUrl('/passcard/new');
            } else {
              // 利用規約同意の不具合対応
              this._app.login = false;
              this._navi.navigateByUrl('/terms?parking-code=' + this.model.parkingCode);
            }
          }
        } else {
          //this._navi.navigateByUrl(this.model.transitionSourceURL);
          this._app.login = false;
          this._navi.navigateByUrl('/terms?parking-code=' + this.model.parkingCode);
        }
      } else {
        this._app.login = false;
        this._navi.navigateByUrl('/terms?parking-code=' + this.model.parkingCode);
      }
    } else {
      this._app.login = false;
      this._navi.navigateByUrl('/terms?parking-code=' + this.model.parkingCode);
    }
  }

}
