<h2>会員退会</h2>

<h3>定期契約中の退会について</h3>

<div class="notice-sentence-unsubscribe">
  <p class="sendText">お客様は<span class="notice-blue">現在契約・申請中</span>となっております。</p>
  <p class="sendText">恐れ入りますが、<span class="notice-blue">契約中・申請中の期間は退会ができません。</span></p>
  <p class="sendText">退会をご希望の場合は、</p>
  <p class="sendText">契約・申請の期間終了後にお願いいたします。</p>
</div>

<div class="buttonBox">
  <button (click)="onApply()" class="cmn-btn bck-btn">マイページに戻る</button>
</div>