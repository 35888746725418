<h2>住所変更確認</h2>
<t-error [message]="error" [messages]="errors"></t-error>

<t-info [value1]="'以下の内容で住所を更新します。'" [value2]="'内容を確認し、「確定」を押してください。'"></t-info>

<section>
  <div class="tableCtn tableCtn-confirm">
    <div *ngIf="model.byUserZip!=='0'">
      <p-postcode [label]="'郵便番号'" [value]="model.postcode"></p-postcode>
    </div>
    <div *ngIf="model.byUserAddress1!=='0'">
      <p-single [label]="'住所1'" [value]="model.address1"></p-single>
    </div>
    <div *ngIf="model.byUserAddress2!=='0'">
      <p-single [label]="'住所2'" [value]="model.address2"></p-single>
    </div>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>