import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';
import { CarInfoModel } from '../car-info.model';
import { MypageModel } from '../../mypage.model';

import { ICarInfoComponent } from '../../../shared/i-carinfo/i-carinfo.component';


//
interface Req {
  numberPlateList: string[];
}

interface Res {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
  carInfoInfoValidator: {
    numberPlateList: string[];
    validatorMessageNumberPlateList: string[];
  }
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(ICarInfoComponent, { static: false }) carInfo: ICarInfoComponent;

  // for Server
  error: string;
  errors: string[];
  messages: { np: string[]; okNg: string[][];};

  //
  disabled: boolean;

  //
  constructor(
    public model: CarInfoModel,
    public mypage: MypageModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
  ) { }

  ngOnInit() {
    this.disabled = true;
    this.model.dispNumberPlate = true;
  
    this.messages = {
      np: [null, null, null, null],
      okNg: [null, null, null, null]
    };
  }

  ngDoCheck() {
    const b1 = this.carInfo ? this.carInfo.validation() : false;
    var b2 = true;
    if (this.model.byCarInfo === '0' ) 
    {
      b2 = false;
    }
    this.disabled = !(b1 && b2) ;
  }

  onKeyup = () => {
    this.error = null;
    this.errors = null;
  }

  //
  private afterPost = (body: Res) => {
    if (body.resultCode === 0) {
      this.model.saveStorage();
      this.navi.navigateByUrl('/mypage/car-info/confirm');
    } else {
      this.api.enabled();
      
      const valid = body.carInfoInfoValidator;
      this.messages.okNg = valid.numberPlateList
        ? valid.numberPlateList.map(s => s === 'OK' ? null : ['NG'])
        : [null, null, null, null];

      this.messages.np = valid.validatorMessageNumberPlateList;

      this.error = body.mainMessage;
      /*this.errors = body.validatorMessageList;*/
    }
  }

  onChange = () => {
    this.error = null;
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const req: Req = {
      numberPlateList: this.model.numberPlate
    };

    this.api.post<Res>('/carInfo/check', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
