import { Component, OnInit } from '@angular/core';
import { NavigateService, StorageService } from '../../services';
import { NoticeInfoService, NoticeInfo } from '../../services/notice-info.service';

@Component({
  selector: 'app-info-detail',
  templateUrl: './detail.component.html',
})
export class DetailComponent implements OnInit {
  info: NoticeInfo;
  loading: boolean;


  constructor(
    public navi: NavigateService,
    private storageSetvice: StorageService
  ) { }

  ngOnInit() {
    // お知らせ情報をストレージから取得
    this.info = <NoticeInfo>this.storageSetvice.load(NoticeInfoService.LOCAL_STORAGE_KEY_NOTICEINFO , this.info);
  }

  /**
   * 閉じるボタン押下時イベント
   */
  onClose() {
    window.close();
  }
}
