import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NOSHOWORDER, PasscardApply, ReservationStatus, mapStatus, weekDays } from '../../passcard.model';
import { PasscardContract } from '../../models/passcard-contract';

const status = [
  { status: ReservationStatus.Applying, text: '申請中', statusClass: 'status-busy', comment: '申請中' },
  { status: ReservationStatus.Waiting, text: 'お手続き待ち', statusClass: 'status-busy', comment: 'お手続き待ち' },
  { status: ReservationStatus.Available, text: '利用可', statusClass: 'status-idle' },
  { status: ReservationStatus.Recepting, text: '申請受付中', statusClass: 'status-idle', comment: '順番となりました' },
  { status: ReservationStatus.Queuing, text: '順番待ち', statusClass: 'status-queuing' },
  { status: ReservationStatus.Reject, text: '申請中', statusClass: 'status-busy', comment: '申請の修正が必要です' },
]

@Component({
  selector: 'card',
  templateUrl: './card.component.html'
})
export class CardComponent {
  private _apply!: PasscardApply;
  RS = ReservationStatus;
  waitingForRefresh = false;

  @Input()
  set reservation(val: PasscardApply) {
    this._apply = val;
    if (val?.systemDate) {
      this.waitingForRefresh = val.systemDate >= val.passcardUpdateFrom && val.systemDate <= val.passcardUpdateTo;
    }
  }

  get reservation() {
    return this._apply;
  }

  @Input() clickable = false;

  @Output() cardClick = new EventEmitter();

  get order() {
    return this.reservation?.currentNum ?? NOSHOWORDER;
  }

  get contract(): PasscardContract {
    return this.reservation.passcardAvailable == 1 ? this.reservation : null;
  }

  get status(): number {
    if (this.reservation.passcardAvailable == 1) return ReservationStatus.Available;
    
    return mapStatus(this.reservation.status);
  }

  get StatusText(): string {
    return (<any>ReservationStatus)[this.status];
  }

  get Tips() {
    return status[this.status].comment;
  }

  get StatusClass() {
    return status[this.status].statusClass;
  }

  get TermYear() {
    if (!this.contract?.passcardValidTo) return '';

    const term = new Date(this.contract.passcardValidTo);
    return term.getFullYear();
  }

  get TermMMDD() {
    if (!this.contract?.passcardValidTo) return '';

    const term = new Date(this.contract.passcardValidTo);
    return `${term.getMonth() + 1}月${term.getDate()}日`
  }

  get TermWeekDay() {
    if (!this.contract?.passcardValidTo) return '';

    const term = new Date(this.contract.passcardValidTo);
    return `${weekDays[term.getDay()]}`
  }

  isShowOrder() {
    return this.order != NOSHOWORDER;
  }

  onCardClick() {
    this.cardClick.emit();
  }
}
