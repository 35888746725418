import { Component, Input } from '@angular/core';

@Component({
  selector: 'p-single',
  templateUrl: './p-single.component.html',
})
export class PSingleComponent {
  @Input() label: string;
  @Input() value: string;

  constructor() { }
}
