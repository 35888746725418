import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';
import { AddressModel } from '../address.model';
import { MypageModel } from '../../mypage.model';

import { IAddressComponent } from '../../../shared/i-address/i-address.component';


//
interface Req {
  zipCodeList: string[];
  address1: string;
  address2: string;  
}

interface Res {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
  addressValidator: {
    validatorMessageZipCodeList: string[];
    validatorMessageAddress1: string[];
    validatorMessageAddress2: string[];
  }
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(IAddressComponent, { static: false }) address: IAddressComponent;

  // for Server
  error: string;
  errors: string[];
  messages: { zipCode: string[]; address1: string[]; address2:string[] ;};

  //
  disabled: boolean;

  //
  constructor(
    public model: AddressModel,
    public mypage: MypageModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
  ) { }

  ngOnInit() {
    this.disabled = true;
    this.messages = {
      zipCode:null,
      address1:null,
      address2:null,
    };

  }

  ngDoCheck() {
    //if (this.address)
    //this.disabled = !this.address.validation();

    const b1 = this.address ? this.address.validation() : false;

    this.disabled = !(b1);
  }

  onKeyup = () => {
    this.error = null;
    this.errors = null;
  }

  //
  private afterPost = (body: Res) => {
    if (body.resultCode === 0) {
      this.model.saveStorage();
      this.navi.navigateByUrl('/mypage/address/confirm');
    } else {
      this.api.enabled();

      this.error = body.mainMessage;
      this.errors = body.validatorMessageList;
      // TODO 入力チェックのエラーの表示
      this.messages.zipCode = body.addressValidator.validatorMessageZipCodeList;
      this.messages.address1 = body.addressValidator.validatorMessageAddress1;
      this.messages.address2 = body.addressValidator.validatorMessageAddress2;
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const req: Req = {
      zipCodeList: this.model.postcode,
      address1: this.model.address1,
      address2: this.model.address2,    
    };

    this.api.post<Res>('/address/check', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
