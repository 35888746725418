import { Component, OnInit } from '@angular/core';

import { ApiService, ConfigService, NavigateService, DeviceService } from '../../services';

import { AppModel } from 'src/app/app.model';
import { MypageModel } from '../mypage.model';

import { CarInfoModel } from '../car-info/car-info.model';
import { MotorcycleInfoModel } from '../motorcycle-info/motorcycle-info.model';
import { CreditModel } from '../credit/credit.model';
import { MailAddrModel } from '../mail-addr/mail-addr.model';
import { MailNotifyModel } from '../mail-notify/mail-notify.model';
import { PasswdModel } from '../passwd/passwd.model';
import { PhoneModel } from '../phone/phone.model';
import { NameBirthdateModel } from '../name-birthdate/name-birthdate.model';
import { AddressModel } from '../address/address.model';
import { SecurityNumberModel } from '../security-number/security-number.model';
import { WsInfoModel } from '../ws-info/ws-info.model';



import { hasNP } from '../../shared/utils';

//
@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
})
export class TopComponent implements OnInit {
  disPC: boolean;

  constructor(
    private app: AppModel,
    public mypage: MypageModel,
    private carInfo: CarInfoModel,
    private motorcycleInfo: MotorcycleInfoModel,
    private credit: CreditModel,
    private mailAddr: MailAddrModel,
    private mailNotify: MailNotifyModel,
    private passwd: PasswdModel,
    private phone: PhoneModel,
    private nameBirthdate: NameBirthdateModel,
    private address: AddressModel,
    private securityNumber: SecurityNumberModel,
    private wsInfo: WsInfoModel,
    public api: ApiService,
    public conf: ConfigService,
    private navi: NavigateService,
    private device: DeviceService,
  ) { }

  ngOnInit() {
    this.api.disabled = true;
    this.mypage.getMypage().subscribe(() => this.api.enabled(0));


    // TODO 表示、非表示の定義取得

    // 表示、非表示を切り替える
    // --サポート
    this.settingDisp(this.mypage.dispNoticeInfo,'dispNoticeInfo');
    // --登録情報
    this.settingDisp(this.mypage.dispNameBirthdate,'dispNameBirthdate');
    this.settingDisp(this.mypage.dispAddress,'dispAddress');
    this.settingDisp(this.mypage.dispPhone,'dispPhone');
    this.settingDisp(this.mypage.dispSecurityNumber,'dispSecurityNumber');
    this.settingDisp(this.mypage.dispCarInfo,'dispCarInfo');
    this.settingDisp(this.mypage.dispMotorcycleInfo,'dispMotorcycleInfo');
    this.settingDisp(this.mypage.dispWsInfo,'dispWsInfo');
    // --アカウント情報
    this.settingDisp(this.mypage.dispMailNotify,'dispMailNotify');


    // 表示する項目を取得
    // --サポート
    var lsitDispSup = [];
    if (this.mypage.dispNoticeInfo) {
      lsitDispSup.push('dispNoticeInfo');
    }
    // 初回リリースではヘルプは非表示にする
    lsitDispSup.push('dispHelp');
    // --登録情報
    var lsitDispReg = [];
    if (this.mypage.dispNameBirthdate) {
      lsitDispReg.push('dispNameBirthdate');
    }
    if (this.mypage.dispAddress) {
      lsitDispReg.push('dispAddress');
    }
    if (this.mypage.dispPhone) {
      lsitDispReg.push('dispPhone');
    }
    if (this.mypage.dispSecurityNumber) {
      lsitDispReg.push('dispSecurityNumber');
    }
    if (this.mypage.dispCarInfo) {
      lsitDispReg.push('dispCarInfo');
    }
    if (this.mypage.dispMotorcycleInfo) {
      lsitDispReg.push('dispMotorcycleInfo');
    }
    if (this.mypage.dispWsInfo) {
      lsitDispReg.push('dispWsInfo');
    }
    // --アカウント情報
    var lsitDispAcc = [];
    lsitDispAcc.push('dispMailAddr');
    lsitDispAcc.push('dispPasswd');
    if (this.mypage.dispMailNotify) {
      lsitDispAcc.push('dispMailNotify');
    }
    lsitDispAcc.push('dispLogout');
    // --会員退会
    var lsitDispWithd = [];
    lsitDispWithd.push('dispWithd');


    // PCかモバイル端末か判断
    if (this.device.getDevice() == 'other'){
      this.disPC = true;
    }


    // 表示項目数ごとにCSSを制御
    // --サポート
    if (lsitDispSup.length === 2) {
      if (this.disPC)
      {
        document.getElementById(lsitDispSup[0]).classList.add("blkCell_l","pc","one_l");
        document.getElementById(lsitDispSup[1]).classList.add("blkCell_r","pc","one_r");
      } else {
        document.getElementById(lsitDispSup[0]).classList.add("blkCell_l","one_l");
        document.getElementById(lsitDispSup[1]).classList.add("blkCell_r","one_r");
      }
    } else if (lsitDispSup.length === 1) {
      if (this.disPC)
      {
        document.getElementById(lsitDispSup[0]).classList.add("blkCell_l","pc","blank_l");
      } else {
        document.getElementById(lsitDispSup[0]).classList.add("blkCell_l","blank_l");
      }
    } else if (lsitDispSup.length === 0) {
      document.getElementById("dispSupArea").remove();

    }

    // --登録情報
    if (lsitDispReg.length === 7) {
      // cssを追加する
      if (this.disPC)
      {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","pc","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","pc","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","pc","middle");
        document.getElementById(lsitDispReg[3]).classList.add("blkCell_r","pc","middle");
        document.getElementById(lsitDispReg[4]).classList.add("blkCell_l","pc","middle");
        document.getElementById(lsitDispReg[5]).classList.add("blkCell_r","pc","middle");
        document.getElementById(lsitDispReg[6]).classList.add("blkCell_l","pc","odd_last");  
      } else {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","middle");
        document.getElementById(lsitDispReg[3]).classList.add("blkCell_r","middle");
        document.getElementById(lsitDispReg[4]).classList.add("blkCell_l","middle");
        document.getElementById(lsitDispReg[5]).classList.add("blkCell_r","middle");
        document.getElementById(lsitDispReg[6]).classList.add("blkCell_l","odd_last");  
      }
    } else if (lsitDispReg.length === 6) {
      if (this.disPC)
      {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","pc","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","pc","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","pc","middle");
        document.getElementById(lsitDispReg[3]).classList.add("blkCell_r","pc","middle");
        document.getElementById(lsitDispReg[4]).classList.add("blkCell_l","pc","last_l","pc_last");
        document.getElementById(lsitDispReg[5]).classList.add("blkCell_r","pc","last_r","pc_last");  
      } else {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","middle");
        document.getElementById(lsitDispReg[3]).classList.add("blkCell_r","middle");
        document.getElementById(lsitDispReg[4]).classList.add("blkCell_l","last_l","pc_last");
        document.getElementById(lsitDispReg[5]).classList.add("blkCell_r","last_r","pc_last");  
      }
    } else if (lsitDispReg.length === 5) {
      if (this.disPC)
      {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","pc","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","pc","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","pc","middle");
        document.getElementById(lsitDispReg[3]).classList.add("blkCell_r","pc","middle");
        document.getElementById(lsitDispReg[4]).classList.add("blkCell_l","pc","odd_last");  
      } else {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","middle");
        document.getElementById(lsitDispReg[3]).classList.add("blkCell_r","middle");
        document.getElementById(lsitDispReg[4]).classList.add("blkCell_l","odd_last");  
      }
    } else if (lsitDispReg.length === 4) {
      if (this.disPC)
      {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","pc","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","pc","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","pc","last_l","pc_last");
        document.getElementById(lsitDispReg[3]).classList.add("blkCell_r","pc","last_r","pc_last");  
      } else {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","last_l","pc_last");
        document.getElementById(lsitDispReg[3]).classList.add("blkCell_r","last_r","pc_last");  
      }
    } else if (lsitDispReg.length === 3) {
      if (this.disPC)
      {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","pc","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","pc","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","pc","odd_last");  
      } else {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","first_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","first_r");
        document.getElementById(lsitDispReg[2]).classList.add("blkCell_l","odd_last");  
      }
    } else if (lsitDispReg.length === 2) {
      if (this.disPC)
      {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","pc","one_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","pc","one_r");
      } else {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","one_l");
        document.getElementById(lsitDispReg[1]).classList.add("blkCell_r","one_r");
      }
    } else if (lsitDispReg.length === 1) {
      if (this.disPC)
      {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","pc","blank_l");
      } else {
        document.getElementById(lsitDispReg[0]).classList.add("blkCell_l","blank_l");
      }
    } else if (lsitDispReg.length === 0) {
      document.getElementById("dispRegistInfoArea").remove();
    }
    // --アカウント情報
    if (lsitDispAcc.length === 4) {
      if (this.disPC)
      {
        document.getElementById(lsitDispAcc[0]).classList.add("blkCell_l","pc","first_l");
        document.getElementById(lsitDispAcc[1]).classList.add("blkCell_r","pc","first_r");
        document.getElementById(lsitDispAcc[2]).classList.add("blkCell_l","pc","last_l","pc_last");
        document.getElementById(lsitDispAcc[3]).classList.add("blkCell_r","pc","last_r","pc_last");  
      } else {
        document.getElementById(lsitDispAcc[0]).classList.add("blkCell_l","first_l");
        document.getElementById(lsitDispAcc[1]).classList.add("blkCell_r","first_r");
        document.getElementById(lsitDispAcc[2]).classList.add("blkCell_l","last_l","pc_last");
        document.getElementById(lsitDispAcc[3]).classList.add("blkCell_r","last_r","pc_last");  
      }
    } else if (lsitDispAcc.length === 3) {
      if (this.disPC)
      {
        document.getElementById(lsitDispAcc[0]).classList.add("blkCell_l","pc","first_l");
        document.getElementById(lsitDispAcc[1]).classList.add("blkCell_r","pc","first_r");
        document.getElementById(lsitDispAcc[2]).classList.add("blkCell_l","pc","odd_last");  
      }else {
        document.getElementById(lsitDispAcc[0]).classList.add("blkCell_l","first_l");
        document.getElementById(lsitDispAcc[1]).classList.add("blkCell_r","first_r");
        document.getElementById(lsitDispAcc[2]).classList.add("blkCell_l","odd_last");  
      }
    }
    // --会員退会
    if (lsitDispWithd.length === 1) {
      if (this.disPC)
      {
        document.getElementById(lsitDispWithd[0]).classList.add("blkCell_l","pc","blank_l");
      } else {
        document.getElementById(lsitDispWithd[0]).classList.add("blkCell_l","blank_l");
      }

    } 


  }
  
  // 表示、非表示切り替え
  settingDisp = (e:boolean,elements:string) => {
    if (e) {
      document.getElementById(elements).classList.add("disp");
    } else {
      document.getElementById(elements).style.display ="none";
      document.getElementById(elements).remove();
    }
  }

  //
  hasNP = () => hasNP(this.mypage.numberPlate);

  hasNotify = () => {
    const n = this.mypage.notify;
    return n.updateNotice || n.updateBeforeNotice;
  }

  //
  onCarInfo = () => {
    this.carInfo.reset(this.mypage.numberPlate,this.mypage.byCarInfo);
    this.navi.navigateByUrl('/mypage/car-info/config');
  }

  onMotorcycleInfo = () => {
    this.motorcycleInfo.reset(this.mypage.motorcycleNumberPlate,this.mypage.byMotorcycleInfo);
    this.navi.navigateByUrl('/mypage/motorcycle-info/config');
  }

  onCredit = () => {
    this.credit.reset();
    this.navi.navigateByUrl('/mypage/credit/config');
  }

  onMailAddr = () => {
    this.mailAddr.reset(this.mypage.mailAddr);
    this.navi.navigateByUrl('/mypage/mail/config');
  }

  onMailNotify = () => {
    this.mailNotify.setModel(this.mypage.notify);
    this.navi.navigateByUrl('/mypage/mail-notify/config');
  }

  onPasswd = () => {
    this.passwd.reset();
    this.navi.navigateByUrl('/mypage/passwd/config');
  }

  onPhone = () => {
    this.phone.reset(this.mypage.phone,this.mypage.byUserTel);
    this.navi.navigateByUrl('/mypage/phone/config');
  }

  onUnsubscribe = () => {
    this.navi.navigateByUrl('/unsubscribe/confirm');
  }

  //
  onLogout = () => {
    this.api.disabled = true;

    const req: {
    } = {
    };

    this.api.post<{ resultCode: 0 }>('/logout', req, 1).subscribe(
      _res => {
        this.api.enabled(0);
        this.app.login = false;
        this.api.clear();
        this.navi.navigateByUrl('/logout');
      }
    );
  }

  onNameBirthdate = () => {
    this.nameBirthdate.reset(this.mypage.name,this.mypage.kana,
      this.mypage.byUserName,this.mypage.byUserFurigana,this.mypage.byUserBirth);
    this.navi.navigateByUrl('/mypage/name-birthdate/config');
  }

  onAddress = () => {
    this.address.reset(this.mypage.postcode, this.mypage.addr1, this.mypage.addr2,this.mypage.kana,
      this.mypage.byUserZip,this.mypage.byUserAddress1,this.mypage.byUserAddress2);
    this.navi.navigateByUrl('/mypage/address/config');
  }

  onSecurityNumber = () => {
    this.securityNumber.reset(this.mypage.securityNumber,this.mypage.bySecurityNumber);
    this.navi.navigateByUrl('/mypage/security-number/config');
  }

  onWsInfo = () => {
    this.wsInfo.reset(this.mypage.wsName, this.mypage.wsKana, this.mypage.wsTelephoneNumber, 
      this.mypage.wsPostcodeList, this.mypage.wsAddress1, this.mypage.wsAddress2, 
      this.mypage.byCompanyName, this.mypage.byCompanyFurigana, this.mypage.byCompanyTel,
      this.mypage.byCompanyZip, this.mypage.byCompanyAddress1, this.mypage.byCompanyAddress2);
    this.navi.navigateByUrl('/mypage/ws-info/config');
  }

  onInfo = () => {
    this.navi.navigateByUrl('/notice-info/list');
  }

  onHelp = () => {
    // TODOの遷移先が決まり次第
    const LINK = this.mypage.helpURL;
		if(!window.open(LINK)) {
			location.href = LINK;
		}
  }




}
