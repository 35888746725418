<h3>配送先情報</h3>
<div class="delivery-tip"><span>※定期券の配送先となるため、</span><span>お間違えの無いようにご入力ください</span></div>
<br>
<div class="passcard-list">
  <div class="passcard passcard-normal" [ngClass]="{ 'passcard-selected': model.kind == 0 }"
    (click)="onDestKbSelected(0)">
    <div class="input">
      <div class="radio-wrapper"><input type="radio" name="ticketinfo" [checked]="model.kind == 0" value="0"
          id="ticket1" /></div>
      <div><label class="radio-caption" for="ticket1">マイページと同じ内容にする</label></div>
    </div>
  </div>
  <div class="passcard passcard-normal" [ngClass]="{ 'passcard-selected': model.kind == 1 }"
    (click)="onDestKbSelected(1)">
    <div class="input">
      <div class="radio-wrapper"><input type="radio" name="ticketinfo" [checked]="model.kind == 1" value="1"
          id="ticket2" /></div>
      <div><label class="radio-caption" for="ticket2">お届け先情報を入力する</label></div>
    </div>
  </div>
</div>
<br>
<delivery *ngIf="Readonly; else edit" [delivery]="model.myPage"></delivery>
<ng-template #edit>
  <div class="tableCtn">
    <i-address [model]="model.deliveryInput" [label]="'郵便番号'" [label1]="'郵便番号'" [label2]="'住所1'" [label3]="'住所2'"
      [spanRequire]="true" (keyup)="onChange()"> </i-address>
    <i-username #fullname label="宛名" placeHolder="天野太郎" field="userName" [model]="model.deliveryInput" (keyup)="onChange()" />
    <i-phone [model]="model.deliveryInput" [label]="'電話番号'" [spanRequire]="true" (keyup)="onChange()"></i-phone>
  </div>
</ng-template>