import { Component, DoCheck, Input, Output, EventEmitter } from '@angular/core';
import { ValidateService } from '../../services';
import { option_numbers } from '../../shared/utils';
import { Date9 } from '../time9';

//
@Component({
  selector: 'i-birthdate',
  templateUrl: './i-birthdate.component.html',
})
export class IBirthdateComponent  implements DoCheck {
  @Input() model: { byUserBirth: string, birthdate: string[]; };
  @Input() label: string;
  @Input() messages: string[];

  //
  option_y = [];
  option_m = [];
  option_d = [];
  msgYear: string[];
  msgMonth: string[];
  msgDay: string[];
  message: string[];

  //
  constructor(
    public valid: ValidateService
  ) {
    const date = Date9();
    const year = date.year();
    this.option_y = option_numbers(1900, year);
    this.option_m = option_numbers(1, 12);
    this.option_d = option_numbers(1, 31);
  }

  ngDoCheck() {
  }

  //
  onChangeYear = () => { };
  onChangeMonth = () => { };
  onChangeDay = () => { };

  //
  validation = () => {
    const msg = this.valid;
    this.message = [];
    if (this.model.byUserBirth === '2') {
      const fn = msg.validRequired;
      this.msgYear = fn(this.model.birthdate[0], this.label);
      this.msgMonth = fn(this.model.birthdate[1], this.label);
      this.msgDay = fn(this.model.birthdate[2], this.label);
      this.message = this.valid.merge(this.msgYear, this.msgMonth, this.msgDay);
    }
    msg.pushBirthDate(this.message, 
                      this.model.birthdate[0], 
                      this.model.birthdate[1], 
                      this.model.birthdate[2], 
                      this.label);
    return !this.message.length;
  }

}
