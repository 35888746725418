<h2>メールアドレス</h2>
<t-error [message]="error"></t-error>

<div class="infoText">
  <div class="infoLine">
    <div>
      <img class="info-icon" src="./assets/img/info.svg" alt="">
    </div>
    <div class="meg">
      メールアドレスの変更を行います。<br>
      メールアドレスを入力し、<br class="br-sp">「確認」を押すと<br class="br-sp">確認メールが送信されます。<br>
    </div>
  </div>
</div>

<section>
  <div class="tableCtn">
    <i-email
      [model]="model"
      [messages]="errors"
      (keyup)="onKeyup()"
      [label]="'メールアドレス'">
    </i-email>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="disabled"
    [loading]="api.disabled"
    [label]="'確認'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>