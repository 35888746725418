<h2>会員退会</h2>

<h3>退会における注意事項</h3>

<div class="unsubscribe_txt">
  <p class="warning">退会すると、下記のサービスが全てご利用できなくなります。</p>
  <ul class="list_circle">
    <li>定期申請手続き</li>
    <!--最初のリリースでは利用明細は表示されない-->
    <!--<li>利用明細の発行</li>-->
    <!--最初のリリースでは過去の定期利用情報は表示されない-->
    <!--<li>過去の定期利用情報の確認</li>-->
  </ul>
</div>

<div class="checkWrapper wrapper-p">
  <label [ngClass]="{'checkBoxArea':consent,'checkBoxAreaNot':!consent}">
    <input [(ngModel)]="consent" type="checkbox" class="unsubscribeConfirm_check">
    <div class="checkboxMeg checkboxMeg-unsubscribe">上記の注意事項を確認しました</div>
  </label>
</div>

<div class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="!consent"
    [loading]="api.disabled"
    [label]="'退会する'"
    [class]="'cmn-btn cncl-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>