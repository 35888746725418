import { Component } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { LostService } from '../../lost.service';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'lost01',
  templateUrl: './lost01.component.html'
})
export class Lost01Component {

  constructor(public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _lostService: LostService
  ) {
  }

  get Steps() {
    return this._lostService.applyCardDelivery == 0 ? ['定期確認', '確認', '完了']
      : ['定期確認', '配送情報', '確認', '完了'];
  }

  get IsComplete() {
    return this._lostService.complete;
  }

  get contract() {
    return this._lostService.passcardApply;
  }

  onNext() {
    if (this._lostService.applyCardDelivery == 0) {
      this._navi.navigateByUrl('/passcard/lost-handler/step3');
      return;
    }

    this.api.deliveryInfo()
      .then(x => {
        this._lostService.myPage = x;
        this._navi.navigateByUrl('/passcard/lost-handler/step2');
      });
  }
}
