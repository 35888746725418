import { Component } from '@angular/core';
import { PasscardApiProxy } from './passcard.api.proxy';

@Component({
  selector: 'app-passcard',
  templateUrl: './passcard.component.html',
})
export class PassCardComponent {
  constructor(
    private _api: PasscardApiProxy,
  ) {
    this._api.error = '';
  }
  
  get error() {
    return this._api.error;
  }
}
