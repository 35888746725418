import { Routes } from '@angular/router';

import { CompleteComponent } from './complete/complete.component';
import { ConfigComponent } from './config/config.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { MailComponent } from './mail/mail.component';
import { PaymentComponent } from './payment/payment.component';
import { SendComponent } from './send/send.component';
import { FlowBoxRegistrationComponent } from './flow-box/flow-box-registration.component';

//
export const routes: Routes = [{
  path: 'complete',
  component: CompleteComponent
}, {
  path: 'confirm/:p1/:p2',
  component: ConfigComponent
}, {
  path: 'confirm2',
  component: ConfirmComponent
}, {
  path: 'mail',
  component: MailComponent
}, {
  path: 'payment',
  component: PaymentComponent
}, {
  path: 'send',
  component: SendComponent
}, {
  // confirm
  path: 'confirm/:p1/:p2/:p3',
  component: ConfigComponent
}];

//
import { StepbarComponent } from './stepbar/stepbar.component';

export const components = [
  CompleteComponent,
  ConfigComponent,
  ConfirmComponent,
  MailComponent,
  PaymentComponent,
  SendComponent,
  StepbarComponent,
  FlowBoxRegistrationComponent,
];
