import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppModel } from '../../../app.model';
import { ApiService, ConfigService, NavigateService } from '../../../services';
import { MypageModel } from '../../../mypage/mypage.model';
import { Time9 } from '../../../shared/time9';

//
interface Req {
  dcReserveCompanyNo: number;
  dcMemberCompanyNo: number;
  mailSendToken: string;
  mailSendDate: string;
}

interface Res {
  resultCode: number;
  linkStatusCode: number;
}

//
@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
})
export class CompleteComponent implements OnInit {
  errors: string[];

  constructor(
    public app: AppModel,
    public api: ApiService,
    private conf: ConfigService,
    private _mypage: MypageModel,
    private navi: NavigateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    const map = this.route.snapshot.paramMap;
    this.putParams(map.get('p1'), map.get('p2'));
  }

  // http://localhost:4200/mypage/mail/complete/3QRz77EIhhCSTyDW/1536307335486
  private putParams = (p1: string, p2: string) => {
    this.api.disabled = true;    

    const req: Req = {
      dcMemberCompanyNo: this.conf.memberCompany,
      dcReserveCompanyNo: this.conf.reserveCompany,
      mailSendToken: p1,
      mailSendDate: p2
    }

    this.api.put<Res>('/mailAddress/update', req, 2).subscribe(
      res => this.afterPut(res.body)
    );
  }

  private afterPut = (body: Res) => {
    this.api.enabled();

    
    // delete JWT
    // this.api.clear();
    this.app.login = true;
    console.log(this.app.login);

    // 会員登録完了後、マイページに遷移した時の表示用
    this._mypage.getMypage();
    
    

    //
    const status = body.linkStatusCode;
    if (body.resultCode !== 0)
      this.errors = {
        '1': ['メールアドレス変更の有効期限が切れました。', 'もう一度、マイページ画面からメールアドレス変更してください。'],
        '2': ['メールアドレス変更の有効期限が切れました。', 'もう一度、マイページ画面からメールアドレス変更してください。']
      }[status];
  }

  //  
  onLogin = () => {
    this.navi.navigateByUrl('/login');
  }

  onMyPage = () => {
    this.navi.navigateByUrl('/mypage/top');
  }

}
