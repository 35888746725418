import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ws-info',
  templateUrl: './ws-info.component.html',
})
export class WsInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
