import { Injectable } from '@angular/core';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PasswdReissueModel {
  email: string;

  p1: string;
  p2: string;

  passwd: string;

  completed: boolean;

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  reset = () => {
    this.completed = false;
    this.email = null;
    this.p1 = null;
    this.p2 = null;
    this.saveStorage();
  }

  apply = () => {
    this.completed = true;
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('passwd-reissue', this);
  }

  loadStorage = () => {
    this._storage.load('passwd-reissue', this);
  }
}
