<!DOCTYPE html>
<div *ngIf="api.disabled" class="error-textbox">
  <p class="err-txt-box">問合せ中...</p>
</div>

<ng-container *ngIf="!api.disabled">
  <h2>メールアドレス変更完了</h2>
  <t-error [messages]="errors"></t-error>

  <p class="complete-text">メールアドレス変更が<br class="br-sp">完了しました</p>

  <div class="buttonBox">
    <button *ngIf="app.login" [disabled]="api.disabled" (click)="onMyPage()" class="cmn-btn bck-btn">マイページに戻る</button>
    <button *ngIf="!app.login" [disabled]="api.disabled" (click)="onLogin()" class="cmn-btn bck-btn">ログイン画面に戻る</button>
  </div>
</ng-container>