import { Component } from '@angular/core';
import { PeriodKind, PasscardStatus, formatPeriod, Passcard, Delivery, ApplyStatus, ApplyKind, PasscardStatusText } from '../../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { PasscardBookingService } from '../../../passcard-booking.service';
import { NavigateService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'apply03',
  templateUrl: './apply03.component.html'
})
export class Apply03Component {
  PasscardStatus = PasscardStatus;
  showPasscardStatus = true;
  passcard: Passcard;
  usedStart!: string;
  fixedPeriod!: string;
  deliveryInfo!: Delivery;
  infoClass = '';
  infoImg = 'info-img';
  infoText = ['', ''];

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _bookingService: PasscardBookingService) {
    this.passcard = _bookingService.PasscardInfo;

    this.showPasscardStatus = _bookingService.applyKind != ApplyKind.ReApply;

    if (this.passcard.status == PasscardStatus.Available) {
      const selectTargetMonth = _bookingService.ValidPeriodKind;
      this.usedStart = PeriodKind[selectTargetMonth];
      this.fixedPeriod = formatPeriod([_bookingService.periods[selectTargetMonth - 1], null]);
      this.deliveryInfo = _bookingService.Delivery;
      this.infoClass = 'confirm-info-border';
      this.infoImg = 'info-img';
      this.infoText[0] = '以下の内容で定期申請を行います。';
      this.infoText[1] = '内容を確認し、「申請」を押してください。';
    }
    else {
      this.infoClass = 'confirm-info-background';
      this.infoImg = 'triangle-warning-img';
      this.infoText[0] = '空き待ち登録となります。順番となるまで定期券はご利用いただけません。';
      this.infoText[1] = '内容を確認し、「申請」を押してください。';
    }
  }

  get showFlowboxes() {
    return this._bookingService.PasscardInfo.status == PasscardStatus.Available;
  }

  get IsComplete() {
    return this._bookingService.complete;
  }

  onReInput(kb: number) {
    this.api.error = '';
    this._navi.navigateByUrl(`/passcard/new/step${kb}`);
  }

  get StatusText() {
   return PasscardStatusText[this.passcard.status];
  }

  get StausTextExtra() {
    if (this.passcard.status == PasscardStatus.WaitingVacancies) {
      return `現在${this.passcard.waitNum}人待ちです`;
    }

    return '';
  }

  get Steps() {
    return this._bookingService.applyCardDelivery == 0 ? ['定期選択', '確認', '完了']
      : ['定期選択', '配送情報', '確認', '完了'];
  }

  get CurrentProc() {
    return this._bookingService.applyCardDelivery == 0 ? 1 : 2;
  }

  get ShowDelivery() {
    return this._bookingService.applyCardDelivery != 0 && this.passcard.status == PasscardStatus.Available;
  }

  /**
   * 申請を行う
   */
  async onApply() {
    this._bookingService.maxProcessed = 2;
    const { no, passcardType, extended } = this._bookingService.PasscardInfo;
    const { PasscardInfo: passcardInfo, ValidPeriodKind }= this._bookingService;
    // status:
    //  1：申込可の定期券を申請する場合
    //  2：空き待ちの定期券を申請する場合
    //  3：申請受付中の定期券を申請する場合（空き待ちだった順番が来た定期券を申請する場合）
    const status = this._bookingService.applyKind == ApplyKind.ReApply ? 3 : (passcardInfo.status == PasscardStatus.Available ? 1 : 2);
    const dcParkingNo = status == 3 ? passcardInfo['dcParkingNo'] : null;
    const bookingInfoRequired = {
      passcardApplyNo: status == 3 ? this._bookingService.passcardApplyNo : null,
      dcParkingNo,
      status,
      no,
      passcardType,
      extended,
      applyCardDelivery: this._bookingService.applyCardDelivery
    }

    const deliveryInfo = this.ShowDelivery ? {
      deliveryType: this._bookingService.DeliveryInputKind,
      ...this._bookingService.Delivery,
    } : {};

    const reservation = passcardInfo.status == PasscardStatus.Available ? {
      ...deliveryInfo,
      ...bookingInfoRequired,
      passcardMonth: 0,
      selectTargetMonth: ValidPeriodKind,
      passcardValidFrom: this._bookingService.periods[ValidPeriodKind - 1],
    } : bookingInfoRequired;

    this._bookingService.receiptNo = await this.api.passcardApply(reservation);
    this._bookingService.maxProcessed = Math.max(this._bookingService.maxProcessed, 2);
    this._navi.navigateByUrl('/passcard/new/complete');
  }
}
